import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import SingleSlide from "./Slide";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { fetchTrendingAnime } from "../../../utils/AnimeApi";
const SliderComp = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const trending = await fetchTrendingAnime();
        // console.log(trending)
        setData(trending);
      } catch (error) {
        // Handle errors, e.g., set an error state or log the error
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Call the async function immediately inside useEffect
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    arrows:false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "w-full",
    appendDots: dots => (
      <div
        style={{
          bottom: '-30px'
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: () => (
      <div
        style={{
          width: "30px",
          height: "5px",
          background: "#bbb",
          borderRadius: "5px",
          margin: "0 3px",
        }}
      ></div>
    ),
  };

  return (
    <div className="mx-auto w-[100vw] md:w-[97vw] bg-[#1c1c1c] rounded-2xl md:p-2">
      
      {data?(
        <Slider {...settings}>
        {data.map(element=>(
          <SingleSlide
          key={element.anime.id}
          id={element.anime.id}
          heading={element.anime.title.english}
          text={element.anime.description}
          buttonText="Click Me"
          imageUrl={element.anime.cover?element.anime.cover: null}
          kitsu={element.kitsu}
        />
        ))}
        </Slider>
      ):(<Slider {...settings}><div>
            <SingleSlide
              text="Please Wait..."
              heading="Please Wait..."
              buttonText="Click Me"
              imageUrl="image1.jpg"
            />
          </div>
          </Slider>)}
        
      
    </div>
  );
};

export default SliderComp;
