import React, { useContext, useState } from "react";
import Modal from "./Modal";
import { AuthContext } from "../../../authContext/AuthContext";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../authContext/AuthActions";

const ProfilePage = () => {
  const { user, dispatch } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageurl, setimageurl] = useState("https://s4.anilist.co/file/anilistcdn/character/large/b127691-6m4hNwL2vLML.png");
  const Level = ["User", "Admin", "Anime Moderator", "Comment Moderator"]
  const navigate=useNavigate();


  const logoutuser =()=>{
    dispatch(logout())
    navigate("/home")
  }
    const openModal = () => {
    setIsModalOpen(true);
  };
  const imageurlset = (image) => {
    setimageurl(image);
    setIsModalOpen(false);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className=" flex mt-5 flex-col md:flex-row">

      <div className="bg-[#1C1C1C] relative py-4 rounded-lg  md:w-[30%]">
      <div className="w-full flex justify-center">
      <div
          className='absolute cursor-default w-[150px] h-[150px] flex items-center  rounded-full'
          onMouseEnter={(e) => { e.currentTarget.querySelector('.hidden').style.display = 'block'; }}
          onMouseLeave={(e) => { e.currentTarget.querySelector('.hidden').style.display = 'none'; }}
        >
          <div className="hidden bg-opacity-60 bg-black w-[150px] h-[150px] rounded-full" style={{ display: 'none' }}>
              <div className='mx-auto flex  justify-center my-auto h-[250px] items-center'>
                <button onClick={openModal} className='h-full p-1'> <img src="pencil.svg" className="h-6" alt="" /></button>
              </div>
          </div>
        </div>
      </div>
        <img
          src={imageurl}
          alt=""
          className="w-[150px] border-[4px] border-purple-800  h-[150px] mx-auto  object-cover rounded-full"
          
        />
        <Modal isOpen={isModalOpen} imageurlset={imageurlset} onClose={closeModal} />
        <div className="text-center text-2xl my-4">{user.user.username}</div>
        <div>
          <div className=" text-ml flex flex-row justify-between mx-4 my-10">
            <div>
              <p>Join Date</p>
              <p>Watch List</p>
            </div>
            <div>
              <p>{user.user.joinDate.split('T')[0]}</p>
              <p className=" text-end">{user.user.watchList.length}</p>
            </div>
          </div>
          <div className="text-center">
            <button onClick={logoutuser} className="text-gray-400 hover:text-purple-600">
              Logout
            </button>
          </div>
        </div>
      </div>
      <div className="w-full mt-2 md:mt-0 md:ml-5">

        <div className="bg-[#1C1C1C] rounded-lg ">
          <div className="md:w-[70%] p-2 flex flex-row justify-between items-center">
            <p className="ml-2 text-gray-300">Access Level</p>
            <input
              type="text"
              disabled
              value={Level[user.user.accessLevel+1]}
              className="bg-[#323232] p-2 rounded-md md:w-[300px]"
            />
          </div>
          <div className="md:w-[70%] p-2 flex flex-row justify-between items-center">
            <p className="ml-2 text-gray-300">Email address</p>
            <input
              type="text"
              value={user.user.email}
              className="bg-[#323232] p-2 rounded-md md:w-[300px]"
            />
          </div>
          <div className="md:w-[70%] p-2 flex flex-row justify-between items-center">
            <p className="ml-2 text-gray-300">Username</p>
            <input
              type="text"
              value={user.user.username}
              className="bg-[#323232] p-2 rounded-md md:w-[300px]"
            />
          </div>
          <div className="md:w-[70%] p-2 flex flex-row justify-between items-center">
            <p className="ml-2 text-gray-300">Display Name</p>
            <input
              type="text"
              value={user.user.displayName}
              className="bg-[#323232] p-2 rounded-md md:w-[300px]"
            />
          </div>
          <div className="md:w-[70%] p-2 flex flex-row justify-between items-center">
            <p className="ml-2 text-gray-300">Password</p>
            <input
              type="text"
              className="bg-[#323232] p-2 rounded-md md:w-[300px]"
            />
          </div>
          {/* <div className="md:w-[70%] p-2 flex flex-row justify-between items-center">
            <p className="ml-2 text-gray-300">Password Confirmation</p>
            <input
              type="text"
              className="bg-[#323232] p-2 rounded-md md:w-[300px]"
            />
          </div> */}
          <div className="md:w-[70%] p-2 flex flex-row justify-end items-center">
            <button className="p-2 bg-purple-800 rounded px-6 text-white hover:scale-110 transition-all ">Update</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfilePage;