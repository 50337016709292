// userAPI.js

import axios from 'axios';

const BASE_URL = 'https://api.anime4us.me/api/admin'; // Replace with your actual API base URL

// Function to get users with pagination and limit
const getUsers = async (page = 1, limit = 10,accessLevel=-2) => {
  try {
    const response = await axios.get(`${BASE_URL}/users?page=${page}&limit=${limit}&accessLevel=${accessLevel}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Function to get users with specific access level, pagination, and limit
const getUsersByAccessLevel = async (accessLevel, page = 1, limit = 10) => {
  try {
    const response = await axios.get(`${BASE_URL}/users/access/${accessLevel}?page=${page}&limit=${limit}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Function to search users by email, username, or display name with pagination and limit
const searchUsers = async (query, page = 1, limit = 10) => {
  try {
    const response = await axios.get(`${BASE_URL}/users/search?query=${query}&page=${page}&limit=${limit}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Function to update user details
const updateUser = async (userId, userData) => {
  try {
    const response = await axios.patch(`${BASE_URL}/users/${userId}`, userData);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Function to delete a user
const deleteUser = async (userId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/users/${userId}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export  {
  getUsers,
  getUsersByAccessLevel,
  searchUsers,
  updateUser,
  deleteUser,
};
