import React from "react";
import { Link } from "react-router-dom";
import { addToWatchlist, removeFromWatchlist } from "../../../utils/Watchlist";
import { AuthContext } from "../../../authContext/AuthContext";
import { useContext } from "react";
import { loginSuccess } from "../../../authContext/AuthActions";

const DetailsBox = ({ cardData }) => {

  const { user, dispatch } = useContext(AuthContext);
  const deleteEntry = async (animeId) => {
    try {
      if(user){
        const newUserData = await removeFromWatchlist(user.user._id, animeId);
      
      const updatedUser = { ...user, user: newUserData };
      dispatch(loginSuccess(updatedUser));
      }
    } catch (error) {
      console.error('Error deleting entry:', error);
    }
  };

  const addEntry = async (animeId) => {
    try {
      if(user){
        const newUserData = await addToWatchlist(user.user._id, animeId);
      
      const updatedUser = { ...user, user: newUserData };
      dispatch(loginSuccess(updatedUser));
      }
    } catch (error) {
      console.error('Error deleting entry:', error);
    }
  };

  const shortenDescription = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substr(0, maxLength) + "...";
  };

  return (
    <div className=" text-white p-2 rounded-lg font-jt">
      <h2 className="text-xl text-center font-comic tracking-widest  mb-2">
        {cardData?.title.english?cardData?.title.english:cardData?.title.romaji}
      </h2>

      <p className="text-white tracking-wider font-jt">
        <span className=" text-[15px] text-gray-300">Released: </span>
        <span className="text-purple-400 tracking-wider text-base  py-2 rounded-full  font-jt ">
          {cardData?.releaseDate}
        </span>
        , <span className=" text-[15px] text-gray-300">Status: </span>
        <span className="text-purple-400 tracking-wider text-base  py-2 rounded-full  font-jt ">
          {cardData?.status}
        </span>
      </p>
      <p className="text-white my-1 tracking-wider font-jt">
        <span className=" text-[15px] text-gray-300">Studios: </span>
        <span className="text-purple-400   tracking-wider text-base  py-2 rounded-full  font-jt ">
          {cardData?.studios[0]}
        </span>
        , <span className=" text-[15px] text-gray-300">Rating: </span>
        <span className="text-orange-700  tracking-wider text-lg  py-2 rounded-full  font-jt ">
          {cardData?.averageRating}
        </span>
      </p>
      <p className="text-white my-1 tracking-wider font-jt">
        <span className=" text-[15px] text-gray-300">Episodes: </span>
        <span className="text-purple-400    tracking-wider text-base  py-2 rounded-full  font-jt ">
          {cardData?.currentEpisode} / {cardData?.totalEpisodes}
        </span>{" "}
        , <span className=" text-[15px] text-gray-300">Type: </span>
        <span className="text-purple-400  tracking-widest text-base  py-2 rounded-full  font-jt ">
          {cardData?.type}
        </span>
      </p>
      <p className="text-sm text-gray-300 my-2">
        <span className=" text-[15px] text-gray-300 font-jt tracking-widest">
          Description:{" "}
        </span>
        <div
      dangerouslySetInnerHTML={{ __html: cardData.description }} className="max-h-40 overflow-y-auto"
    />
      </p>

      <div className="flex flex-wrap  ">
            {cardData?.genres?.map((genre, index) => (
              <span key={index} className="bg-[#353535] text-sm rounded-full py-1 px-3  mr-2 mb-2">
                {genre}
              </span>
            ))}
          </div>

     

      <div className="flex flex-row justify-around">
        <Link to={`/watch?anime=${cardData.id}`} className="border-2 p-1 outline-none  bg-purple-900 text-white border-purple-900 rounded-full text-center w-[80%] text-3xl ">
        <button className=" ">
          Watch
        </button>
        </Link>
        {user.user.watchList.includes(cardData.id)? (<img onClick={()=>deleteEntry(cardData.id)} src="tick.svg" className="w-12 cursor-pointer" />):(<img onClick={()=>addEntry(cardData.id)} src="plus.svg" className="w-10 cursor-pointer" />)}
        
        
      </div>
    </div>
  );
};

export default DetailsBox;
