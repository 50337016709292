import React, { useEffect, useState } from "react";
import RichInput from "./RichInput";
import {
  addReactionToComment,
  deleteComment,
  deleteReactionFromComment,
  getSingleLevelComments,
  updateComment,
} from "../../../utils/Comment Api";

const Comment = ({
  ReFetchParentComment,
  comment,
  addComment,
  addReply,
  parentId,
  userId,
  animeId,
  episodeNumber,
}) => {
  const { text } = comment;
  const [reactions, setReactions] = useState(comment.reactions);
  const [textData, settextData] = useState(text);
  const [Childrendata, setChildrendata] = useState(null);
  const [showChildren, setShowChildren] = useState(false);
  const [reaction, setReaction] = useState(null);
  const [defaultReaction, setdefaultReaction] = useState(null);
  const [newCommentText, setNewCommentText] = useState("");
  const [replyToId, setReplyToId] = useState(null);
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [reply, setreply] = useState("");
  const [showReactions, setShowReactions] = useState(false);
  const [edit, setEdit] = useState(false);
  const [currentReactionID, setCurrentReactionId] = useState(null);
  const [reactiondataArray, setreactiondataArray] = useState([]);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const reactionIcons = [
    "emoji-like.svg",
    "emoji-dislike.svg",
    "emoji-heart.svg",
    "emoji-surprised.svg",
    "emoji-fearful.svg",
    "emoji-angry.svg",
    "emoji-joy.svg",
    "emoji-tired.svg",
  ];

  function formatDate(dateString) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  }
  const toggleReactions = () => {
    setShowReactions(!showReactions);
  };
  const toggleDefaultReactions = () => {
    setdefaultReaction(!defaultReaction);
  };

  const handleReactionAdd = async (reaction) => {
    const reactionType = reaction;
    const data = await addReactionToComment(parentId, { reactionType, userId });
    setReactions(data.reactions);
  };

  const handleReactionSelection = (reaction) => {
    setReaction(reaction);
    handleReactionAdd(reaction);
    console.log("Selected reaction:", reaction);
    setShowReactions(false); // Close reactions after selection
  };
  const getDataChildren = async () => {
    const data = await getSingleLevelComments(animeId, episodeNumber, parentId);
    data.reverse();
    // console.log(data,"data1234")
    setChildrendata(data);
  };
  const handleToggleChildren = () => {
    if (Childrendata == null) {
      getDataChildren();
    } else {
      setShowChildren(!showChildren);
    }
  };

  const handleReaction = async (type) => {
    const reactionType = reaction;
    const data = await deleteReactionFromComment(parentId, currentReactionID);
    setReactions(data.reactions);
    setReaction(null);
  };
  const handleOpenEdit = () => {
    setEdit(true);
    // Handle reaction logic here
  };
  const handleCloseEdit = async () => {
    const data = await updateComment(parentId, textData);
    settextData(data.text);
    setEdit(false);
    // Handle reaction logic here
  };
  const handleAddComment = () => {
    if (newCommentText) {
      addComment(newCommentText);
      setNewCommentText("");
    }
  };

  const handleAddReply = () => {
    if (replyToId !== null && newCommentText) {
      addReply(replyToId, newCommentText);
      setNewCommentText("");
      setReplyToId(null);
      setShowReplyInput(false);
    }
  };

  ///////////////////Reaction//////////////////////////

  useEffect(() => {
    // Count the frequency of each reactionType

    reactions.forEach((data) => {
      if (data.userId == userId) {
        setReaction(data.reactionType);
        setCurrentReactionId(data._id);
      }
    });

    const reactionFrequency = reactions.reduce((acc, reaction) => {
      const { reactionType } = reaction;
      acc[reactionType] = (acc[reactionType] || 0) + 1;
      return acc;
    }, {});

    // Convert the reactionFrequency object into an array of objects and sort it by frequency (descending order)
    const sortedReactions = Object.entries(reactionFrequency)
      .map(([reactionType, frequency]) => ({ reactionType, frequency }))
      .sort((a, b) => b.frequency - a.frequency);

    setreactiondataArray(sortedReactions);
  }, [reactions]);
  /////////////////////////////DELETE COMMENT////////////////////////

  const handleDeleteComment = async () => {
    setdeleteLoading(true);
    const data = await deleteComment(parentId);
    await ReFetchParentComment();
    setdeleteLoading(false);
  };

  return (
    <div className=" pl-4 py-4 font-jt text-white border-l-2   border-gray-300  ">
      <div className=" flex flex-row items-center bg-[#242323] p-2 rounded-t-md">
        <img
          src="https://s4.anilist.co/file/anilistcdn/character/large/b127691-6m4hNwL2vLML.png"
          className="h-10 w-10 rounded-full"
          alt=""
        />
        <div className="ml-4">
          <div className="text-lg">{comment.userId.displayName}</div>
          <div className="text-base text-gray-300">
            {formatDate(comment.createdAt)}
          </div>
        </div>
        <div className="relative">
          {reactions.length > 0 && (
            <div
              onClick={toggleDefaultReactions}
              className="ml-2 cursor-pointer rounded-full flex flex-row py-1  bg-[#323232] px-2"
            >
              {reactiondataArray.map((data) => (
                <img src={data.reactionType} className="h-6" />
              ))}
            </div>
          )}
          {defaultReaction && (
            <div className="absolute  mt-1 -left-[170px] md:-left-0 w-[400px] md:w-[550px] flex flex-row py-2 z-50 bg-[#323232] rounded-full p-1">
              {reactiondataArray.map((data, index) => (
                <div
                  key={index}
                  className="cursor-pointer rounded-full bg-[#232020] py-2  mx-1 w-fit px-3 flex flex-row items-center"
                  onClick={() => handleReactionSelection(data.reactionType)}
                >
                  <img
                    src={data.reactionType}
                    className="h-5 hover:scale-150 transition-all"
                    alt={`Reaction ${index}`}
                  />
                  <div className="text-sm">{data.frequency}</div>
                </div>
              ))}
            </div>
          )}
        </div>
        {edit == false ? (
          <button
            onClick={handleOpenEdit}
            className="text-white flex flex-row mb-1 hover:scale-110 transition-all items-center bg-[#323232] rounded-md px-2 ml-2"
          >
            <img src="edit.svg" className="h-4 mr-1" alt="" />
            Edit
          </button>
        ) : (
          <button
            onClick={handleCloseEdit}
            className="text-white flex flex-row mb-1 hover:scale-110 transition-all items-center bg-[#323232] rounded-md px-2 ml-2"
          >
            <img src="save.svg" className="h-4 mr-1" alt="" />
            Save
          </button>
        )}
      </div>
      {edit == false ? (
        <div
          dangerouslySetInnerHTML={{ __html: textData }}
          className="text-white bg-[#242323] p-2"
        />
      ) : (
        <RichInput
          getDataChildren={getDataChildren}
          flag={true}
          parentId={parentId}
          userId={userId}
          animeId={animeId}
          episodeNumber={episodeNumber}
          comment={textData}
          setComment={settextData}
        />
      )}
      <div className="flex  bg-[#242323] rounded-b-md flex-wrap md:flex-row pt-2">
        {/* <button onClick={() => handleReaction('like')} className="mr-2">
          Like
        </button>
        <button onClick={() => handleReaction('dislike')} className="mr-2">
          Dislike
        </button> */}
        <div className="relative">
          <div className="flex items-center  py-1  rounded-full flex-row">
            <div
              className="flex cursor-pointer rounded-full px-2 py-1 mx-1 flex-row items-center"
              onClick={toggleReactions}
            >
              {reaction ? (
                <img className="h-8" src={reaction} />
              ) : (
                <>
                  <div className="font-bold text-purple-600">React</div>
                </>
              )}
            </div>
            {reaction && (
              <div
                onClick={handleReaction}
                className="mr-2 cursor-pointer bg-[#323232] rounded-md items-center px-2 py-2 flex flex-row"
              >
                {" "}
                <img src="eraser.svg" className="h-4 mr-1" alt="" />
                Clear Reaction
              </div>
            )}
          </div>

          {showReactions && (
            <div className="absolute -left-6 md:-left-0  mt-1  w-[400px] flex flex-row z-50 bg-[#323232] rounded-full p-1">
              {reactionIcons.map((icon, index) => (
                <div
                  key={index}
                  className="cursor-pointer rounded-full px-2 py-1 mx-1 flex-row items-center"
                  onClick={() => handleReactionSelection(icon)}
                >
                  <img
                    src={icon}
                    className="h-8 hover:scale-150 transition-all"
                    alt={`Reaction ${index}`}
                  />
                </div>
              ))}
            </div>
          )}
        </div>

        <button
          onClick={() => setShowReplyInput(!showReplyInput)}
          className="text-white flex flex-row mb-1 hover:scale-110 transition-all items-center bg-[#323232] rounded-md py-y px-2 ml-2"
        >
          <img src="reply.svg" className="h-4 mr-1" alt="" />
          Reply
        </button>
        {deleteLoading ? (
          <button
            className="text-white flex flex-row mb-1 items-center bg-[#323232] rounded-md py-2 px-2 ml-2"
            onClick={() => handleDeleteComment()}
            disabled
          >
            <img src="dustbin.svg" className="h-4 mr-1" alt="" />
            {deleteLoading ? "Deleting" : "Delete Comment"}
          </button>
        ) : (
          <button
            className="text-white flex flex-row mb-1 hover:scale-110 transition-all items-center bg-[#323232] rounded-md py-2 px-2 ml-2"
            onClick={() => handleDeleteComment()}
          >
            <img src="dustbin.svg" className="h-4 mr-1" alt="" />
            {deleteLoading ? "Deleting" : "Delete Comment"}
          </button>
        )}

        {/* <button className="text-white flex flex-row mb-1 items-center hover:scale-110 transition-all bg-[#323232] rounded-md px-2 ml-4">
          <img src="review.svg" className="h-4 mr-1" alt="" />
          Mark for Review
        </button> */}
        <button
          onClick={handleToggleChildren}
          className="text-sm mr-4 text-white ml-auto hover:underline"
        >
          {showChildren ? "Hide replies" : "Show replies"}
        </button>
      </div>
      {showReplyInput && (
        <div className="mt-2">
          <RichInput
            setShowReplyInput={setShowReplyInput}
            showReplyInput={showReplyInput}
            getDataChildren={getDataChildren}
            comment={reply}
            setComment={setreply}
            parentId={parentId}
            userId={userId}
            animeId={animeId}
            episodeNumber={episodeNumber}
          />
        </div>
      )}
      {showChildren && Childrendata && Childrendata.length > 0 && (
        <div className="ml-4 mt-2">
          {Childrendata.map((child) => (
            <Comment
              key={child._id}
              comment={child}
              addComment={addComment}
              addReply={addReply}
              parentId={child._id}
              userId={userId}
              animeId={animeId}
              episodeNumber={episodeNumber}
              ReFetchParentComment={getDataChildren}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Comment;
