import React from "react";
import { Link } from "react-router-dom";

const CardImage = ({ kitsu, cardData }) => {
  return (
    <div className="w-[180px] md:w-[178px] relative rounded-t-xl rounded-b bg-[#272729]">
      <Link to={`/watch?anime=${cardData.id}`}>
        <img
          src={cardData.image}
          alt={cardData.title.english}
          className="w-full h-[250px] md:h-[250px]  p-[1px]  object-cover"
        />
        <div className="text-white flex flex-row  justify-between items-center">
          <div className="flex flex-row items-center m-1">
            
            <p className="relative border-white flex flex-row items-center w-fit tracking-wider  mr-2  font-jt  text-white ">
              
                {cardData.currentEpisode}/{cardData.totalEpisodes}
           
            </p>
            
            <div className="relative">
              <img className="h-6" src="tv.svg" />{" "}
              <p className="text-black top-[2px] text-[13px] mx-auto flex justify-center w-full font-bold font-jt absolute">
                {kitsu?.attributes.ageRating}
              </p>
            </div>
          </div>

          <p className="justify-end flex flex-row items-center text-sm  text-gray-300 mr-2 tracking-widest">
            {" "}
            <img className="h-4 mr-1" src="episode.svg" />
            {cardData.type}
          </p>
        </div>
      </Link>
    </div>
  );
};

export default CardImage;
