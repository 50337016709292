import { useEffect, useState } from "react";
import Card from "../../component/Home/Card/Card";
import NewReleaseCard from "../../component/Home/New Release added completed/NewReleaseCard";
import SliderComp from "../../component/Home/Slider/Slider";
import RectangleCard from "../../component/Home/Top anime/Top Anime Card";
import Schedule from "../../component/Home/schedule/Schedule";
import { fetchPopularAnime, fetchRecentEpisodes, fetchTopAnimeByGenre } from "../../utils/AnimeApi";
import { Skeleton } from "@mui/material";
import GenreList from "../../component/Home/Genre/GenreList";

const HomePage = () => {
  const [recentData, setrecentData] = useState(null);
  const [popularData, setpopularData] = useState(null);
  const [ActionData, setActionData] = useState(null);
  const [RomanceData, setRomanceData] = useState(null);
  const [ThrillerData, setThrillerData] = useState(null);
  const dummyRecently = new Array(40).fill(0)
  const dummyPopular = new Array(20).fill(0)
  const dummyART = new Array(7).fill(0)
  const [mobileART,setmobileArt]=useState(2)

  const handleARTTabs =(num)=>{
    setmobileArt(num)
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const recent = await fetchRecentEpisodes();
        // console.log(recent)
        setrecentData(recent);
      } catch (error) {
        
        console.error('Error fetching data:', error);
      }
    };
    const fetchpopular = async () => {
      try {
        const popular = await fetchPopularAnime();
        // console.log(popular)
        setpopularData(popular);
      } catch (error) {
        
        console.error('Error fetching data:', error);
      }
    };

    const fetchART = async () => {
      try {
        const art = await fetchTopAnimeByGenre();
        console.log(art)
        setActionData(art.topAction);
        setRomanceData(art.topRomance);
        setThrillerData(art.topThriller);
      } catch (error) {
        
        console.error('Error fetching data:', error);
      }
    };
    fetchpopular(); 
    fetchData(); 
    fetchART();
  }, []);

  return (
    <div className="bg-[#020202]">
      <div className="bg-[#1c1c1c]">
        <SliderComp />
      </div>
      <div className="flex flex-col md:flex-row w-full">
        <div className=" md:w-[70%]">
          <div className=" ml-7 mb-2 md:mb-0 font-medium text-3xl mt-2  md:text-start text-white font-jt">
            Recently Updated
          </div>
          <div className="ml-2 flex justify-evenly flex-wrap">
            {recentData?(<>
                {recentData.slice(0,20).map(elem=>(
                  <Card data={elem.anime} kitsu={elem.kitsu}/>
                ))}
            </>):(<>
              {dummyRecently.map(elem=>(<div className="mt-4">
              <Skeleton variant="rectangular" className=""  sx={{ bgcolor: 'grey.900' }} width={180} height={230}/>
              <Skeleton variant="rectangular" className=" mt-2"  sx={{ bgcolor: 'grey.900' }} width={180} height={30}/>
              </div>))}
            </>
            )}
          </div>
        </div>
        <div className="block md:hidden">
        <div className=" font-medium text-3xl mt-6 ml-6  md:text-start text-white font-jt">
            Genres
          </div>
        <GenreList/>
        </div>
        <div className="md:w-[30%]  text-white">
          <div className=" ml-7 md:ml-0 md:text-center text-3xl mt-2 font-jt">Popular Anime</div>
          {popularData?(<>
                {popularData.slice(0,10).map((elem,id)=>(
                  <RectangleCard id={id} data={elem.anime} kitsu={elem.kitsu}/>
                ))}
            </>):(
              <div className="flex flex-col justify-end items-end mr-10">
              {dummyPopular.map(elem=>(<div className="mt-4">
              <Skeleton variant="rectangular" className=""  sx={{ bgcolor: 'grey.900' }} width={390} height={100}/>
              
              </div>))}
              </div>
            )}
          
          
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-around w-[100%] md:w-[80%]  mt-10 ">
        <div className="md:hidden flex flex-row mx-auto w-[95%] justify-center text-white items-center">
              <button onClick={()=> setmobileArt(0)} className={`px-3 ${mobileART==0 ? "bg-purple-800":"bg-[#1c1c1c]"} w-full text-xl rounded-l-xl py-2`}>Action</button>
              <button onClick={()=> setmobileArt(1)} className={`px-3 ${mobileART==1 ? "bg-purple-800":"bg-[#1c1c1c]"} w-full text-xl py-2`}>Thriller</button>
              <button onClick={()=> setmobileArt(2)} className={`px-3 ${mobileART==2 ? "bg-purple-800":"bg-[#1c1c1c]"} w-full text-xl rounded-r-xl py-2`}>Romance</button>
        </div>
      

        {mobileART==0 && <div className="md:ml-4 md:hidden  rounded-md">
          <div className="text-center text-3xl mt-2 font-jt text-white">
            Top Action Anime
          </div>
          {ActionData ? (ActionData.map(elem=>(
            <>
              <NewReleaseCard data={elem.anime} kitsu={elem.kitsu}/>
            </>
          ))):(
            <div className="flex flex-col justify-end items-end ">
              {dummyART.map(elem=>(<div className="mt-4">
              <Skeleton variant="rectangular" className=" rounded-xl"  sx={{ bgcolor: 'grey.900' }} width={350} height={100}/>
              
              </div>))}
              </div>
          )}
          
        </div>}
        {mobileART ==2 && <div className="md:ml-4 md:hidden">
          <div className="text-center text-3xl mt-2 font-jt text-white">
            Top Romance Anime
          </div>
          {RomanceData ? (RomanceData.map(elem=>(
            <>
              <NewReleaseCard data={elem.anime} kitsu={elem.kitsu}/>
            </>
          ))):(
            <div className="flex flex-col justify-end items-end ">
              {dummyART.map(elem=>(<div className="mt-4">
              <Skeleton variant="rectangular" className=" rounded-xl"  sx={{ bgcolor: 'grey.900' }} width={350} height={100}/>
              
              </div>))}
              </div>
          )}
        </div>}
        {mobileART==1 && <div className=" md:ml-4 md:hidden">
          <div className="text-center text-3xl mt-2 font-jt text-white">
            Top Thriller Anime
          </div>
          {ThrillerData  ? (ThrillerData.map(elem=>(
            <>
              <NewReleaseCard data={elem.anime} kitsu={elem.kitsu}/>
            </>
          ))):(
            <div className="flex flex-col justify-end items-end ">
              {dummyART.map(elem=>(<div className="mt-4">
              <Skeleton variant="rectangular" className=" rounded-xl"  sx={{ bgcolor: 'grey.900' }} width={350} height={100}/>
              
              </div>))}
              </div>
          )}
        </div>}



        
        <div className="md:ml-4 hidden md:block   rounded-md">
          <div className="text-center text-3xl mt-2 font-jt text-white">
            Top Action Anime
          </div>
          {ActionData ? (ActionData.map(elem=>(
            <>
              <NewReleaseCard data={elem.anime} kitsu={elem.kitsu}/>
            </>
          ))):(
            <div className="flex flex-col justify-end items-end ">
              {dummyART.map(elem=>(<div className="mt-4">
              <Skeleton variant="rectangular" className=" rounded-xl"  sx={{ bgcolor: 'grey.900' }} width={350} height={100}/>
              
              </div>))}
              </div>
          )}
          
        </div>
        <div className="md:ml-4 hidden md:block ">
          <div className="text-center text-3xl mt-2 font-jt text-white">
            Top Romance Anime
          </div>
          {RomanceData ? (RomanceData.map(elem=>(
            <>
              <NewReleaseCard data={elem.anime} kitsu={elem.kitsu}/>
            </>
          ))):(
            <div className="flex flex-col justify-end items-end ">
              {dummyART.map(elem=>(<div className="mt-4">
              <Skeleton variant="rectangular" className=" rounded-xl"  sx={{ bgcolor: 'grey.900' }} width={350} height={100}/>
              
              </div>))}
              </div>
          )}
        </div>
        <div className=" md:ml-4 hidden md:block ">
          <div className="text-center text-3xl mt-2 font-jt text-white">
            Top Thriller Anime
          </div>
          {ThrillerData  ? (ThrillerData.map(elem=>(
            <>
              <NewReleaseCard data={elem.anime} kitsu={elem.kitsu}/>
            </>
          ))):(
            <div className="flex flex-col justify-end items-end ">
              {dummyART.map(elem=>(<div className="mt-4">
              <Skeleton variant="rectangular" className=" rounded-xl"  sx={{ bgcolor: 'grey.900' }} width={350} height={100}/>
              
              </div>))}
              </div>
          )}
        </div>
      </div>
      <Schedule />
    </div>
  );
};

export default HomePage;
