import { useState } from "react";


const CustomCheckBox = ({width,item, index, data, included, setIncluded, notIncluded, setnotIncluded }) => {
    const [level, setLevel] = useState(0);

    function toggle() {
        const curr = (level + 1) % 3;
        const str = data[index];

        if (curr === 0) {
            // Remove str from both included and notIncluded if present
            setIncluded(prevIncluded => prevIncluded.filter(item => item !== str));
            setnotIncluded(prevNotIncluded => prevNotIncluded.filter(item => item !== str));
        } else if (curr === 1) {
            // Add str to included and remove from notIncluded if present
            if (!included.includes(str)) {
                setIncluded(prevIncluded => [...prevIncluded, str]);
            }
            setnotIncluded(prevNotIncluded => prevNotIncluded.filter(item => item !== str));
        } else {
            // Remove str from included if present and add it to notIncluded
            setIncluded(prevIncluded => prevIncluded.filter(item => item !== str));
            if (!notIncluded.includes(str)) {
                setnotIncluded(prevNotIncluded => [...prevNotIncluded, str]);
            }
        }

        setLevel(curr);
    }

    return (
        <div>
            
            {(!included.includes(data[index]) && !notIncluded.includes(data[index])) && <div onClick={toggle} className="flex flex-row items-center"><img  className="h-4 mx-auto my-auto" src="unchecked.svg" /><li key={item.id} className={`w-${width} ml-2 text-gray-300 hover:text-purple-700 cursor-pointer`}>{item}</li></div>}
            {(included.includes(data[index]) && !notIncluded.includes(data[index])) && <div onClick={toggle} className="flex flex-row items-center"><img  className="h-4 mx-auto my-auto" src="select.svg" /><li key={item.id} className={`w-${width} ml-2 text-gray-300 hover:text-purple-700 cursor-pointer`}>{item}</li></div>}
            {(!included.includes(data[index]) && notIncluded.includes(data[index])) && <div onClick={toggle} className="flex flex-row items-center"><img  className="h-4 mx-auto my-auto" src="cross red.svg" /><li key={item.id} className={`w-${width} ml-2 text-gray-300 hover:text-purple-700 cursor-pointer`}>{item}</li></div>}
        </div>
    );
};


export default CustomCheckBox;