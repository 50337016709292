import React, { useState, useEffect } from 'react';

const RealTimeClock = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formattedDate = `${time.toLocaleDateString(undefined, {
    
    
    month: 'short',
    day: 'numeric',
  })}`;

  return (
    <div className='text-base items-center bg-purple-900 p-1 px-3 rounded-full font-bold  ml-2 mb-4 text-white flex flex-row '>
      <p> {formattedDate} -- {time.toLocaleTimeString()}</p>
    
      <p></p>
    </div>
  );
};

export default RealTimeClock;
