import React from 'react';
import Popup from 'reactjs-popup';
import DetailsBox from './CardDetails';
import CardImage from './CardImage';

const Related = ({data}) => {
    

  return (
    <div className="flex w-full items-center justify-center ">
     <CardImage cardData={data}/>
    </div>
  );
};

export default Related;
