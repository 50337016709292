import React from 'react';
import { Link } from 'react-router-dom';

const CardImage = ({ kitsu,cardData}) => {
  const cleanedColor = cardData.color? cardData.color.replace(/"/g, ''):"#BFDBFE";
  const textStyle = {
    color: cleanedColor,
    // You can add other inline styles if needed
  };
  return (
    <Link to={`/watch?anime=${cardData.id}`} className="w-[370px] md:w-[350px] items-center m-2 md:mx-4 rounded-xl  flex flex-row h-36 md:h-24 text-white bg-[#272729]">
          
          <div className='h-full min-w-fit'>
            <img
            src={cardData.image}
            alt={cardData.title.english?cardData.title.english:cardData.title.romaji}
            className='h-full rounded-l-xl  w-[100px] object-cover p-[1px]'
            /> 
          </div>
          <div className='flex flex-col w-full font-jt'>
            <p className='text-[20px] text-center text-white' >{cardData.title.english?cardData.title.english.slice(0,20)+"...":cardData.title.romaji.slice(0,20)+"..."}</p>
            
              <div className='flex flex-row justify-center items-center'>

              <p className=' border-gray-300 text-[12px] rounded-md mr-2 px-1 font-bold font-jt tracking-widest text-gray-300 border-2'>{cardData.currentEpisode}/{cardData.totalEpisodes}</p> 
              <div className="relative">
              <img className="h-6" src="tv.svg" />{" "}
              <p className="text-black top-[2px] text-[13px] mx-auto flex justify-center w-full font-bold font-jt absolute">
                {kitsu?.attributes.ageRating}
              </p>
            </div>
              <p className='justify-end font-bold mx-2 tracking-widest text-[12px] text-gray-300  p-[2px] '> {cardData.type}</p>
              
              </div>
              <p className='justify-end text-[12px] font-bold mr-2 mt-1 tracking-widest text-center text-gray-300'>{cardData.startDate.day}.{cardData.startDate.month}.{cardData.startDate.year}</p>
          </div>

    </Link>
  );
};

export default CardImage;
