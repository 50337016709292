import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const CardImage = ({ cardData}) => {

  const navigate = useNavigate();
  const handleNavigateAndReload = () => {
    navigate(`/watch?anime=${cardData.id}`);
    window.location.reload(); // Reloads the page after navigation
  };
  
  function capitalizeFirstLetter(str) {
    str=str.toLowerCase().replace(/_/g, ' ');
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  

  const cleanedColor = cardData.color? cardData.color.replace(/"/g, ''):"#000000";
  const textStyle = {
    color: cleanedColor,
    // You can add other inline styles if needed
  };
  const borderStyle = {
    borderRight: `1px solid ${cleanedColor}`,
    borderBottom: `1px solid ${cleanedColor}`, // Applying border with cleanedColor
    // You can add other inline styles if needed
  }
  return (
    <Link onClick={handleNavigateAndReload} to={`/watch?anime=${cardData.id}`} className=" cursor-pointer w-[100%] mx-auto md:w-[350px] items-center m-2 justify-center md:mx-4 rounded-xl  flex flex-row h-40 md:h-24 text-white bg-[#272729]" style={borderStyle}>
          
          <div className='h-full min-w-fit'>
            <img
            src={cardData.image}
            alt={cardData.title.english?cardData.title.english:cardData.title.romaji}
            className='h-full rounded-l-xl  w-[130px] md:w-[100px] object-cover p-[1px]'
            /> 
          </div>
          <div className='flex flex-col w-full font-jt'>
            <p className='text-center text-lg text-white' >{cardData?.title.english? cardData?.title.english.slice(0,25)+"..":cardData?.title.romaji.slice(0,25)+".."}</p>
            
              <div className='flex flex-row justify-center items-center'>

              <p className='  text-sm rounded-md  font-jt tracking-widest text-gray-300 '>{cardData.status}</p> 
              <p className='justify-end  mr-1 tracking-widest text-sm text-gray-300 p-[2px] '>{cardData.episodes?`, Episode:${cardData.episodes}`:""}</p>            

              <p className='justify-end  mr-1 tracking-widest text-sm text-gray-300 p-[2px] '> </p>
              
              </div>
              {/* <p className='justify-end text-sm mr-2 mt-1 tracking-widest text-center '>{capitalizeFirstLetter(cardData.type)} {capitalizeFirstLetter(cardData.relationType)}</p> */}
          </div>

    </Link>
  );
};

export default CardImage;
