import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {

    const footerStyle = {
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("bleach.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    };
    return (
        <footer className="container mx-auto  bottom-0  text-white flex justify-between font-jt flex-col md:min-h-[300px]" style={footerStyle}>
            <div className='bg-black bg-opacity-75 p-3 md:p-8'>
                <div className=' items-baseline  flex '>

                    <img src="logo-main.png" className='h-[200px] md:text-right ' alt="" />
                </div>

                <div className="container mx-auto pt-8 text-gray-200 p-2 md:px-4 ">
                    <p className="text-lg md:text-xl leading-relaxed mb-2">
                    Your gateway to a vast world of anime! Dive into a realm of English subbed and dubbed anime, all available for effortless streaming. Embrace a hassle-free experience with simple registration and seamless access. Our daily updates, lightning-fast servers, and user-friendly features make tracking and enjoying your beloved anime a breeze. We're new and constantly evolving, so if you encounter bugs or have feature requests, reach out via the social links below. Join the anime adventure today!
                    </p>
                    <hr/>
                    <p className="text-base md:text-lg mt-2 mb-4">
                        Disclaimer: This site does not store any files on its server. All contents are provided by non-affiliated third parties.This is just a personal Project for Fun
                    </p>
                   <div className='flex flex-col md:flex-row justify-between'>
                   <p className="text-lg">
                        Copyright © 2023 All Rights Reserved by Anime 4us
                        <br />
                        Created by Rishabh Sagar
                    </p>

                    <div className='flex mt-4 md:mt-0 flex-row'>
                        <Link to="https://www.instagram.com/sagar.rish/">
                        <img src="instagram.svg" className='h-10 mx-5  hover:scale-110 cursor-pointer transition-all ' alt="" />
                        </Link>
                        <Link to="https://twitter.com/rishabh_sagar_">
                        <img src="twitter.svg" className='h-10 mx-5 hover:scale-110 cursor-pointer transition-all ' alt="" />
                        </Link>
                        <Link to="https://github.com/rishabh-sagar">
                        <img src="github.svg" className='h-10 mx-5 hover:scale-110 cursor-pointer transition-all ' alt="" />
                        </Link>
                        <Link to="https://www.linkedin.com/in/rishabh-sagar-b89122157/">
                        <img src="linkedin.svg" className='h-10 mx-5 hover:scale-110 cursor-pointer transition-all ' alt="" />
                        </Link>
                    </div>
                   </div>
                </div>
            </div>

        </footer>
    );
};

export default Footer;
