import axios from 'axios';

const BASE_URL = 'https://api.anime4us.me/api/history'; // Replace this with your API base URL

const getHistory = async (userId) => {
  try {
    const response = await axios.get(`${BASE_URL}/${userId}/history`);
    
    return response.data;

  } catch (error) {
    throw error.response.data;
  }
};

const addOrUpdateHistory = async (userId, animeId, episodeNumber, type) => {
  try {
    // Ensure animeId and episodeNumber are numbers
    const parsedAnimeId = parseInt(animeId);
    const parsedEpisodeNumber = parseInt(episodeNumber);

    if (isNaN(parsedAnimeId) || isNaN(parsedEpisodeNumber)) {
      throw new Error('Invalid animeId or episodeNumber');
    }

    const response = await axios.post(`${BASE_URL}/${userId}/history`, {
      animeId: parsedAnimeId,
      episodeNumber: parsedEpisodeNumber,
      type,
    });

    // console.log('History Updated');
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};


const deleteHistoryEntry = async (userId, animeId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/${userId}/history/${animeId}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const deleteAllHistory = async (userId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/${userId}/history`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export {
  getHistory,
  addOrUpdateHistory,
  deleteHistoryEntry,
  deleteAllHistory,
};
