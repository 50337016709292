import React from 'react';
import Popup from 'reactjs-popup';
import DetailsBox from './CardDetails';
import CardImage from './CardImage';
import { deleteHistoryEntry } from '../../../utils/History Api';
import { loginSuccess } from '../../../authContext/AuthActions';
import { Link } from 'react-router-dom';

const Card = ({user,dispatch,history,data}) => {

  
  
  const deleteEntry = async (animeId) => {
    try {
      if(user){
        const newUserData = await deleteHistoryEntry(user.user._id, animeId);
      
      const updatedUser = { ...user, user: newUserData };
      dispatch(loginSuccess(updatedUser));
      }
    } catch (error) {
      console.error('Error deleting entry:', error);
    }
  };
  

  return (
    <>
    <div className='md:hidden block cursor-pointer'>
            <CardImage deleteEntry={deleteEntry} history={history} cardData={data}/>
            <div className='text-white font-jt text-center mt-1 text-xl'>
            <Link to={`/watch?anime=${data.id}`}>{data?.title.english? data?.title.english.slice(0,10):data?.title.romaji.slice(0,10)}</Link>
              </div>
        </div>
    <div className="hidden md:flex items-center h-fit justify-center m-2 ">
      <Popup
        trigger={<div className=' cursor-pointer'>
            <CardImage deleteEntry={deleteEntry} history={history} cardData={data}/>
            <div className='text-white font-jt text-center mt-1 text-xl'>
            {data?.title.english? data?.title.english.slice(0,10):data?.title.romaji.slice(0,10)}
              </div>
        </div>
        }
        position="right center"

        contentStyle={{
          padding: '2px',
          borderRadius: '6px',
          backgroundColor: '#272729',
          color: 'white',
          width:'300px',
          
        }}
        arrow={false}
        on="hover"
        mouseEnterDelay={800}
        mouseLeaveDelay={0}
      >
       <DetailsBox  history={history} cardData={data}/>
      </Popup>
    </div>
    </>
    
  );
};

export default Card;
