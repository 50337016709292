import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const GenreList = () => {
  const initialVisibleGenres = 12; // Initial number of visible genres
  const [visibleGenres, setVisibleGenres] = useState(initialVisibleGenres);
  const genres = [
  "Action",
  "Action & Adventure",
  "Adventure",
  "Animation",
  "Anime",
  "Anime Influenced",
  "Avant Garde",
  "Award Winning",
  "Boys Love",
  "Cars",
  "Children",
  "Comedy",
  "Cooking",
  "Crime",
  "Dementia",
  "Demons",
  "Documentary",
  "Doujinshi",
  "Drama",
  "Ecchi",
  "Erotica",
  "Family",
  "Fantasy",
  "Food",
  "Friendship",
  "Game",
  "Game Show",
  "Gender Bender",
  "Girls Love",
  "Gore",
  "Gourmet",
  "Harem",
  "Hentai",
  "Historical",
  "History",
  "Home and Garden",
  "Horror",
  "Indie",
  "Isekai",
  "Kids",
  "Law",
  "Magic",
  "Mahou Shoujo",
  "Mahou Shounen",
  "Martial Arts",
  "Mature",
  "Mecha",
  "Medical",
  "Military",
  "Mini-Series",
  "Music",
  "Musical",
  "Mystery",
  "News",
  "Parody",
  "Police",
  "Political",
  "Psychological",
  "Racing",
  "Reality",
  "Romance",
  "Samurai",
  "School",
  "Sci-Fi",
  "Sci-Fi & Fantasy",
  "Science Fiction",
  "Shoujo Ai",
  "Shounen Ai",
  "Slice of Life",
  "Soap",
  "Space",
  "Sport",
  "Sports",
  "Super Power",
  "Supernatural",
  "Suspense",
  "Talk Show",
  "Thriller",
  "Tragedy",
  "Travel",
  "Vampire",
  "War",
  "War & Politics",
  "Western",
  "Workplace",
  "Yaoi",
  "Youth",
  "Yuri",
  "Zombies"
];

const randomColor = () => {
  const colors = [
      'text-[#F6D6D6]', 'text-[#F6F7C4]', 'text-[#A1EEBD]', 'text-[#7BD3EA]', 'text-[#C3E2C2]',
      'text-[#EAECCC]', 'text-[#AFC8AD]', 'text-[#EEE7DA]', 'text-[#F2F1EB]', 'text-[#FDF7E4]',
      'text-[#FAEED1]', 'text-[#FFC5C5]', 'text-[#E5D4FF]', 'text-[#F1EAFF]', 'text-[#C3ACD0]',
      'text-[#FFFBF5]', 'text-[#D2E3C8]', 'text-[#A0E9FF]', 'text-[#A0E9FF]', 'text-[#FFDFDF]',
      'text-[#F9B572]', 'text-[#FFF8C9]', 'text-[#D2E0FB]', 'text-[#FFBFBF]', 'text-[#FFDBAA]',
      'text-[#FAF0D7]', 'text-[#F6FFA6]', 'text-[#9376E0]', 'text-[#FFF78A]', 'text-[#FFC47E]'
    ];
  return colors[Math.floor(Math.random() * colors.length)];
};

  const handleShowMore = () => {
    // Function to show more genres
    setVisibleGenres(visibleGenres + 12); // Increase by 12 when "Show More" is clicked
  };

  return (
    <div className="bg-[#202020] font-jt rounded-md w-[95vw] mx-auto mt-4 p-4">
      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4">
        {genres.slice(0, visibleGenres).map((genre, index) => (
          <Link to={`/filter?genre=${genre}`} key={index} className={`text-sm ${randomColor()} text-xl capitalize`}>
            {genre}
          </Link >
        ))}
      </div>
      {visibleGenres < genres.length && ( // Show "Show More" button if there are more genres
        <button
          className=" text-white text-3xl py-2 px-4 rounded mt-4"
          onClick={handleShowMore}
        >
          Show More
        </button>
      )}
    </div>
  );
};

export default GenreList;
