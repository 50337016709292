import React, { useContext, useEffect, useState } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../authContext/AuthContext";
import ProfilePage from "./Profile/Profile";
import UserList from "../../component/Admin and Moderators/Admin/Admin";
import ContineWatching from "./Continue Watching/Continue Watching";
import WatchList from "./Watch List/Watch List";
import { Skeleton } from "@mui/material";

const Miscellaneous = ({defaulttab}) => {

  const navigate = useNavigate();
  const location = useLocation();
  const [Tabs,setTabs]=useState(defaulttab)

  const urls = ["profile","continue-watching","watch-list","user-list","anime-updates","comment-flag"]
    const setnavigate =(tabid)=>{
      navigate(`/${urls[tabid]}`)
      setTabs(tabid)
    }

  console.log(location.pathname);

  const { user, dispatch } = useContext(AuthContext);
  return (
    <div className="container font-jt text-white min-h-screen mx-auto py-8">
      {user?(<div className="w-[90vw]  flex flex-col mx-auto">
      <div className="bg-[#1C1C1C]  w-full  overflow-x-scroll scrollbar-hide md:overflow-x-hidden py-4 scroll-hidden  md:justify-around  p-2 flex flex-row rounded-lg">
            <p onClick={()=> setnavigate(0)} className={`mx-2 text-base ${Tabs==0?'text-purple-800':'text-gray-300'}  hover:text-purple-800 cursor-pointer flex flex-row items-center`}>
              <img src="profile.svg" className=" mx-2 h-4" alt="" /> <div className="md:w-auto w-16">Profile</div>
            </p>{" "}
            <p onClick={()=> setnavigate(1)} className={` mx-2 text-base ${Tabs==1?'text-purple-800':'text-gray-300'}  hover:text-purple-800 cursor-pointer flex flex-row items-center`}>
              <img src="continue-watching.svg" className="mx-2 h-[18px]" alt="" />{" "}
              <div className=" w-40">Continue Watching</div>
            </p>{" "}
            <p onClick={()=> setnavigate(2)} className={`mx-2 text-base ${Tabs==2?'text-purple-800':'text-gray-300'}  hover:text-purple-800 cursor-pointer flex flex-row items-center`}>
              <img src="watchlist.svg" className="mx-2 h-6" alt="" /> <div className="md:w-auto w-24">Watch List</div>
            </p>
            {user.user.accessLevel==0 && <>
            <p onClick={()=> setnavigate(3)} className={`mx-2 text-base ${Tabs==3?'text-purple-800':'text-gray-300'}  hover:text-purple-800 cursor-pointer flex flex-row items-center`}>
              <img src="user.svg" className="mx-1 h-6" alt="" /> <div className="md:w-auto w-24">User List</div>
            </p>
            <p onClick={()=> setnavigate(4)} className={`mx-2 text-base ${Tabs==4?'text-purple-800':'text-gray-300'}  hover:text-purple-800 cursor-pointer flex flex-row items-center`}>
              <img src="popular.svg" className="mx-2 h-6" alt="" /><div className="md:w-auto w-32"> Anime Updates</div>
            </p>
            <p onClick={()=> setnavigate(5)} className={`mx-2 text-base ${Tabs==5?'text-purple-800':'text-gray-300'}  hover:text-purple-800 cursor-pointer flex flex-row items-center`}>
              <img src="comment.svg" className="mx-2 h-6" alt="" /> <div className="md:w-auto w-32">Comment Flag</div>
            </p>
            </>}
          </div>
          {Tabs==0 && <ProfilePage/>}
          {Tabs==1 && <ContineWatching/>}
          {Tabs==2 && <WatchList/>}
          {Tabs==3 && user.user.accessLevel==0 && <UserList/>}
        <div></div>
      </div>):(<div className="text-white"><Skeleton variant="rectangular" className=" mx-auto my-auto rounded-lg w-[90vw]"  sx={{ bgcolor: 'grey.900' }} height={700} /></div>)}
    </div>
  );
};

export default Miscellaneous;
