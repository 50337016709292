import axios from 'axios';

// Set the base URL for your API
axios.defaults.baseURL = 'https://api.anime4us.me/api/watchlist'; // Replace with your actual base URL


  const getWatchlist= async userId => {
    try {
      const response = await axios.get(`/${userId}/watchlist`);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  }

  const addToWatchlist= async (userId, animeId) => {
    try {
      const response = await axios.post(`/${userId}/watchlist`, { animeId });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  }

  const removeFromWatchlist= async (userId, animeId) => {
    try {
      const response = await axios.delete(`/${userId}/watchlist/${animeId}`);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  }

 export {
    getWatchlist,
    addToWatchlist,
    removeFromWatchlist
  };
  