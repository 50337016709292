import React from "react";
import "./button.scss";
// import "./style.css";
import { Link } from "react-router-dom";
import { Skeleton } from "@mui/material";
const SingleSlide = ({ heading, text, buttonText, id, imageUrl, kitsu }) => {
  const mobileText = text.slice(0, 40) + "..."
  return (
    <div className="flex flex-col items-center max-w-full h-[250px] md:h-[75vh] ">
      <div className="flex flex-col md:flex-row w-full">
        <div className=" block md:hidden   w-[100%] md:w-[55%] ">
          {imageUrl !== "image1.jpg" ? (
            <div className="relative">
            <img
              src={imageUrl}
              alt="Slide"
              className="absolute h-[250px] rounded-md object-cover shadow-md shadow-slate-900"
            />
            <div className="absolute h-[250px] w-full">
              {/* Gradient background */}
              <div className="bg-gradient-to-t from-black via-transparent to-transparent absolute bottom-0 h-[400px] w-full" />
          
              <h2 className="text-white text-[17px] mt-[150px] ml-4 h-full tracking-wider mb-4 font-jt relative z-10">
                {heading}
                <div dangerouslySetInnerHTML={{ __html: mobileText }} className="text-white text-[12px]" />
                <div className="mt-2 flex flex-row items-center">
                  <Link to={`/watch?anime=${id}`}>
                    <button className="text-[14] bg-purple-900 px-4 py-1 flex flex-row items-center tracking-wider font-jt rounded-md hover:scale-110 transition-all shadow-inner shadow-purple-800">
                      <img src="watch.svg" className="h-6" alt="" /> Watch
                    </button>
                  </Link>
                </div>
              </h2>
            </div>
          </div>
          
          ) : (
            <Skeleton
              variant="rectangular"
              className="rounded-2xl"
              sx={{ bgcolor: "grey.900" }}
              width={800}
              height={250}
            />
          )}
        </div>

        <div className="text-white w-[100%] md:w-[45%] hidden md:flex flex-col justify-center p-8">
          <h2 className="text-[40px] tracking-wider font-bold mb-4 font-jt">
            {heading}
          </h2>
          <p className="text-lg hidden md:block mb-2 font-jt">
            <div
              dangerouslySetInnerHTML={{ __html: text }}
              className="max-h-40 overflow-y-hidden"
            />
          </p>
          <span className="flex items-center   w-fit">

            <div className="relative">
              <img className="h-10" src="tv.svg" />{" "}
              <p className="text-black top-[6px] mx-auto flex justify-center w-full font-bold font-jt absolute">
                {kitsu?.attributes.ageRating}
              </p>
            </div>
            <p className="ml-2 text-sm">{kitsu?.attributes.ageRatingGuide}</p>
          </span>
          <div className="mt-2 flex flex-row items-center">
            <Link to={`/watch?anime=${id}`}>
              <button className=" text-[30px] bg-purple-900 px-4 py-1 flex flex-row items-center tracking-wider font-jt rounded-2xl hover:scale-110 transition-all shadow-inner shadow-purple-950"><img src="watch.svg" className="h-6" alt="" /> Watch</button>
            </Link>

          </div>
        </div>

        <div className="p-4 hidden md:block w-[100%] md:w-[55%] h-fit">
          {imageUrl !== "image1.jpg" ? (
            <img
              src={imageUrl}
              alt="Slide"
              className="w-fit min-h-[450px] object-cover rounded-xl shadow-md  shadow-slate-900"
            />
          ) : (
            <Skeleton
              variant="rectangular"
              className="rounded-2xl"
              sx={{ bgcolor: "grey.900" }}
              width={800}
              height={450}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleSlide;
