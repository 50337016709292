import React, { useState } from 'react';
import CharacterCarousel from './CharacterCarousel';




const AnimeCard = ({ data }) => {
  const {
    title,
    image,
    description,
    genres,
    startDate,
    endDate,
    totalEpisodes,
    status,
    studios,
    malId,
    synonyms,
    isLicensed,
    isAdult,
    countryOfOrigin,
    popularityAnilist,
    color,
    cover,
    averageRating,
    duration,
    season,
    subOrDub,
    type,
    tags,
    rating,
  } = data.anime;
  const { characters } = data.animeData
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [showCharacters, setShowCharacters] = useState(false);
  const maxDescriptionLength = 500; // Set the maximum characters for the truncated description

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const toggleTags = () => {
    setShowTags(!showTags);
  };

  const toggleCharacters = () => {
    setShowCharacters(!showCharacters);
  };

  const renderDescription = () => {
    if (description.length <= maxDescriptionLength || showFullDescription) {
      return description;
    }
    return description.slice(0, maxDescriptionLength) + '...';
  };

  return (

    <div className=' md:ml-6'>
      <div className="flex flex-col md:flex-row  mt-4  h-fit bg-[#171717] font-jt text-white shadow-lg rounded-lg rounded-b-none p-4">
        <img src={image} alt={title.romaji} className=" h-[50vh] md:max-w-[255px]  mx-auto md:mx-0 object-cover rounded-md" />


        <div className="md:ml-4 mt-4 md:mt-0 flex-1">
          <h2 className="text-3xl text-center font-semibold mb-2 ">{title.romaji}</h2>

          <div className='mb-4'>
            <div dangerouslySetInnerHTML={{ __html: renderDescription() }} className="text-gray-300" />

            {description.length > maxDescriptionLength && (
              <button
                className="text-purple-600 cursor-pointer"
                onClick={toggleDescription}
              >
                {showFullDescription ? 'Show Less' : 'Show More'}
              </button>
            )}
          </div>



          <div className="flex flex-wrap mb-4 ">
            <span className="mr-2 text-purple-600 mt-1">Genres:</span>
            {genres.map((genre, index) => (
              <span key={index} className="bg-[#353535] rounded-full py-1 px-3 text-base mr-2 mb-2">
                {genre}
              </span>
            ))}
          </div>

          {/* Show Tags Button */}
          <div className="flex justify-start mt-2">
            <button
              className="text-purpwhitesor-pointer mr-4 bg-purple-900  p-2 m-2 rounded  hover:scale-110 transition-all"
              onClick={toggleTags}
            >
              {showTags ? 'Hide Tags' : 'Show Tags'}
            </button>

            {/* Show Characters Button */}
            <button
              className="text-white cursor-pointer  bg-purple-900  p-2 m-2 rounded  hover:scale-110 transition-all"
              onClick={toggleCharacters}
            >
              {showCharacters ? 'Hide Characters' : 'Show Characters'}
            </button>
          </div>

          <div className="mb-4">
            <div className="flex flex-wrap mb-4">
              <div className="w-full md:w-1/2">
                <p className="text-gray-400">

                  <span className=" text-purple-600 ">Status:</span> {status}
                  <br />
                  <span className=" text-purple-600 ">Date:</span> {startDate.year}.{startDate.month}.{startDate.day} - {endDate.year}.{endDate.month}.{endDate.day}

                  <br />
                  <span className=" text-purple-600 ">Total Episodes:</span> {totalEpisodes}
                  <br />
                  <span className=" text-purple-600 ">Type:</span> {type}
                </p>
              </div>
              <div className="w-full md:w-1/2 ">
                <p className="text-gray-400 flex flex-row items-center">
                  <span className=" text-purple-600 ">Studios:</span>
                  <ul>
                    {studios.map((studio, index) => (
                      <li key={index}>{studio}</li>
                    ))}
                  </ul>
                </p>
                <p className="text-gray-400">
                  <span className=" text-purple-600 ">Synonyms:</span> {synonyms.join(', ')}
                  <br />
                  <span className=" text-purple-600 ">Is Adult:</span> {isAdult ? 'Yes' : 'No'}
                </p>
              </div>
            </div>

            <div className="flex flex-wrap mb-4">
              <div className="w-full md:w-1/2 text-gray-400">
                <span className=" text-purple-600 ">Popularity:</span> {popularityAnilist}
                <br />
                <span className=" text-purple-600 ">Rating:</span> {averageRating}
                <br />
                <span className=" text-purple-600 ">Anilist Rating:</span> {rating?.anilist}

              </div>
              <div className="w-full md:w-1/2">
                <p className="text-gray-400">
                  <span className=" text-purple-600 ">Country of Origin:</span> {countryOfOrigin}
                  <br />

                  <span className=" text-purple-600 ">Duration:</span> {duration} Mins
                  <br />
                  <span className=" text-purple-600 ">Season:</span> {season}
                  <br />

                </p>
              </div>
            </div>
          </div>
        </div>

      </div>


      {showTags && (
        <div className="flex flex-wrap mb-4 bg-[#171717] rounded-b-md px-2">
          <span className="mr-2 text-purple-600 mt-1">Tags:</span>
          {tags.map((tag, index) => (
            <span key={index} className="bg-[#353535] text-gray-300 rounded-full py-1 px-3 text-base mr-2 mb-2">
              {tag}
            </span>
          ))}
        </div>
      )}

      {/* Show Characters */}
      {showCharacters && (
        <CharacterCarousel characters={characters} />
      )}
    </div>
  );
};

export default AnimeCard;
