import React, { useContext, useState } from "react";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { login } from "../../authContext/apiCalls";
import { AuthContext } from "../../authContext/AuthContext";
import { BeatLoader 
} from "react-spinners";
import { useEffect } from "react";
const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Required"),
});

const LoginPopup = ({Loginpop,SetLoginPop,Registerpop,SetRegisterpop}) => {
  const [showPassword, setShowPassword] = useState(false);
  const { dispatch, user } = useContext(AuthContext);
  const navigate = useNavigate();

  
  useEffect(() => {
    if(user){
      SetLoginPop(!Loginpop)
    }
  }, [user])
  
  const footerStyle = {
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("bleach.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
};

  return (
    <div className="fixed top-0 left-0 text-gray-300 z-[3000] w-screen h-screen backdrop-blur-sm  bg-black bg-opacity-50  font-jt">
      <div className="flex relative flex-col lg:flex-row bg-[#1C1C1C] bg-opacity-80 h-full lg:h-fit lg:my-10 w-[100%] lg:w-[84%] lg:rounded-2xl mx-auto py-8 shadow-lg" style={footerStyle}>
        <button
        onClick={()=>{SetLoginPop(!Loginpop)}}
        className="absolute top-4 right-4 transition-all hover:scale-[1.05] text-gray-600 hover:text-gray-800">
          <img src="close.svg" className="h-10" alt="" />
        </button>
        <div
          
          alt=""
          className="lg:block hidden mx-auto  h-[600px] w-[600px]"
        />
        <div className="font-jt mr-14 rounded-xl bg-[#1C1C1C] bg-opacity-80 p-4 lg:p-8  w-full lg:w-1/2">
          <h2 className="text-2xl font-extrabold mb-3 text-[45px]">Login</h2>
          <p className="text-gray-300 font-thin mt-6 mb-16 text-[25px]">
            Use your email address to sign in
          </p>
          <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting, setFieldError }) => {
            try {
              setSubmitting(true);
              await login(values, dispatch);
              if (user) {
                SetLoginPop(!Loginpop)
                
              }
            } catch (error) {
              console.error(error,"234aa"); // Log or handle your error here
              setFieldError("password", "Wrong Email/Password"); // display server error message
            } finally {
              setSubmitting(false);
            }
          }}
        >
            {({ isSubmitting }) => (
              <Form className="space-y-4" >
                <div className="relative">
                  <label
                    htmlFor="email"
                    className="font-semibold text-[16px] absolute z-10 left-[35px] -top-[10px] bg-[#1C1C1C] bg-opacity-80"
                  >
                    Email
                  </label>
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    className="relative mb-4 border-[3px] block text-[18px] py-3 px-5 text-gray-300 bg-[#1C1C1C] border-gray-300 rounded-full w-full"
                  />
                  <ErrorMessage
                    name="email"
                    className="text-red-600 text-[16px] ml-5 "
                    component="div"
                  />
                </div>
                <div className="relative">
                  <label
                    htmlFor="password"
                    className="font-semibold text-[16px] absolute z-10 left-[35px] -top-[10px] bg-[#1C1C1C] bg-opacity-80"
                  >
                    Password
                  </label>
                  <Field
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    className="relative mb-4 border-[3px] block text-[18px] py-3 px-5 text-gray-300 bg-[#1C1C1C] border-gray-300 rounded-full w-full"
                  />
                  <button
                  type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-4 top-7 transform -translate-y-1/2"
                  >
                    {!showPassword ? (
                      <i class="fa fa-eye-slash" aria-hidden="true"></i>
                    ) : (
                      <i class="fa fa-eye" aria-hidden="true"></i>
                    )}
                  </button>
                  <ErrorMessage
                    name="password"
                    className="text-red-600 text-[16px] ml-5 "
                    component="div"
                  />
                  
                </div>

                <div className="flex flex-row justify-between mb-12">
                  <div className="flex items-center mt-4 ">
                    <input
                      type="checkbox"
                      id="rememberMe"
                      className="mr-2 w-4 h-4 rounded-md focus:ring-black"
                    />
                    <label htmlFor="rememberMe" className="text-gray-600">
                      Remember me
                    </label>
                  </div>
                  <a className="mt-4 underline font-medium " href="#">
                    Forgot Password?
                  </a>
                </div>

                <button
                  type="submit"
                  className="bg-white text-black w-full py-2 rounded-full mb-6 shadow-md"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (<BeatLoader  color={"white"} size={14} />) : "Login"}
                </button>
              </Form>
            )}
          </Formik>
          
          <div className="text-center mt-4">
            <span className="text-gray-300">Not a member yet?</span>{" "}
            <button onClick={()=>{SetRegisterpop(!Registerpop);SetLoginPop(!Loginpop)}} className="text-purple-800 underline">
              Register
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPopup;
