import { useState } from "react";

const CustomCheckBoxSort = ({ index, sortData, setSortData }) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(prevMode => !prevMode);
        
        const updatedSortData = [...sortData];
        updatedSortData[index].sortby = !isChecked;
        setSortData(updatedSortData);
    };

    const toggle = () => {
        const updatedSortData = [...sortData];
        updatedSortData[index].taken = !updatedSortData[index].taken;
        setSortData(updatedSortData);
    };

    return (
        <div>
            <div className='flex flex-row bg-[#1C1C1C] text-lg justify-between w-[320px] p-2 mx-4 my-2 rounded-lg items-center'>
                {sortData[index].taken ? (
                    <img onClick={toggle} className="h-6 mx-auto cursor-pointer my-auto" src="select.svg" alt="Selected" />
                ) : (
                    <img onClick={toggle} className="h-6 mx-auto cursor-pointer my-auto" src="unchecked.svg" alt="Not selected" />
                )}
                {/* Assuming 'item' is available */}
                {/* Replace 'item.id' with your unique identifier */}
                <li  className={`w-48 ml-2 text-gray-300 hover:text-purple-700 cursor-pointer`}>
                    {sortData[index].name}
                </li>
                <div className='text-white mr-2'>{isChecked ? "Asc" : "Desc"}</div>
                <div>
                    <input
                        type="checkbox"
                        className="checkbox"
                        id={`checkbox-${index}`}
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor={`checkbox-${index}`} className="checkbox-label">
                        <img src='asc.svg' className="w-6 fas fa-moon" alt="Ascending" />
                        <img src='desc.svg' className="w-6 fas fa-sun" alt="Descending" />
                        <span className="ball"></span>
                    </label>
                </div>
            </div>
        </div>
    );
};

export default CustomCheckBoxSort;

// Usage in the parent component:
{/* <CustomCheckBoxSort index={index} sortData={sortData} setSortData={setSortData} /> */}
