import React from 'react';
import Popup from 'reactjs-popup';
import DetailsBox from './CardDetails';
import CardImage from './CardImage';
import { removeFromWatchlist } from '../../../utils/Watchlist';
import { loginSuccess } from '../../../authContext/AuthActions';

const Card = ({user,dispatch,data,kitsu}) => {

  const deleteEntry = async (animeId) => {
    try {
      const newUserData = await removeFromWatchlist(user.user._id, animeId);
      
      const updatedUser = { ...user, user: newUserData };
      dispatch(loginSuccess(updatedUser));
    } catch (error) {
      console.error('Error deleting entry:', error);
    }
  };

  return (
    <>
    <div className='md:hidden block cursor-pointer'>
            <CardImage deleteEntry={deleteEntry} kitsu={kitsu} cardData={data}/>
            <div className='text-white font-jt text-center mt-1 text-xl'>
            {data?.title.english? data?.title.english.slice(0,10):data?.title.romaji.slice(0,10)}
              </div>
        </div>
    <div className="hidden md:flex  items-center h-fit justify-center m-2 ">
      <Popup
        trigger={<div className=' cursor-pointer'>
            <CardImage deleteEntry={deleteEntry} kitsu={kitsu} cardData={data}/>
            <div className='text-white font-jt text-center mt-1 text-xl'>
            {data?.title.english? data?.title.english.slice(0,10):data?.title.romaji.slice(0,10)}
              </div>
        </div>
        }
        position="right center"

        contentStyle={{
          padding: '2px',
          borderRadius: '6px',
          backgroundColor: '#272729',
          color: 'white',
          width:'300px',
          
        }}
        arrow={false}
        on="hover"
        mouseEnterDelay={800}
        mouseLeaveDelay={0}
      >
       <DetailsBox cardData={data}/>
      </Popup>
    </div>
    </>
  );
};

export default Card;
