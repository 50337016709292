import React from 'react';
import Popup from 'reactjs-popup';
import DetailsBox from './CardDetails';
import CardImage from './CardImage';

const NewReleaseCard = ({data,kitsu}) => {
    const cardData = {
        imageUrl: 'https://s4.anilist.co/file/anilistcdn/media/anime/cover/large/bx20-YJvLbgJQPCoI.jpg',
        title: 'Naruto',
        description:
          "Naruto Uzumaki, a hyperactive and knuckle-headed ninja, lives in Konohagakure, the Hidden Leaf village. Moments prior to his birth, a huge demon known as the Kyuubi, the Nine-tailed Fox, attacked Konohagakure and wreaked havoc. In order to put an end to the Kyuubi's rampage, the leader of the village, the 4th Hokage, sacrificed his life and sealed the monstrous beast inside the newborn Naruto.\n\nShunned because of the presence of the Kyuubi inside him, Naruto struggles to find his place in the village. He strives to become the Hokage of Konohagakure, and he meets many friends and foes along the way.\n\n(Source: MAL Rewrite)",
        status: 'Completed',
        releaseDate: '2002',
        currentEpisode: 220,
        totalEpisodes: 220,
        rating: 8.1,
        isAdult: false,
        type: 'TV',
        "startDate": {
          "year": 2002,
          "month": 10,
          "day": 3
        },
        "studios": [
            "Studio Pierrot"
          ],
        genre: ['Action', 'Adventure', 'Comedy', 'Drama', 'Fantasy', 'Supernatural'],
      };
      

  return (
    <>
    <div className='md:hidden flex items-center justify-center "'>
    <CardImage kitsu={kitsu} cardData={data}/>
    </div>
    <div className=" hidden md:flex items-center justify-center ">
      <Popup
        trigger={<div className=' cursor-pointer'>
            <CardImage kitsu={kitsu} cardData={data}/>
           
        </div>
        }
        position="right center"
        mouseEnterDelay={800}
        mouseLeaveDelay={0}
        contentStyle={{
          padding: '2px',
          borderRadius: '6px',
          backgroundColor: '#272729',
          color: 'white',
          width:'300px',
          
        }}
        arrow={false}
        on="hover"
      >
       <DetailsBox cardData={data}/>
      </Popup>
    </div>
    </>
  );
};

export default NewReleaseCard;
