import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../authContext/AuthContext';
import {addOrUpdateHistory} from '../../../utils/History Api'
const episodesData = [
    {
        "id": "naruto-episode-1",
        "title": "Enter: Naruto Uzumaki!",
        "image": "https://media.kitsu.io/episodes/thumbnails/451/original.jpg",
        "number": 1,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-1"
    },
    {
        "id": "naruto-episode-2",
        "title": "My Name is Konohamaru!",
        "image": "https://media.kitsu.io/episodes/thumbnails/452/original.jpg",
        "number": 2,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-2"
    },
    {
        "id": "naruto-episode-3",
        "title": "Sasuke and Sakura: Friends or Foes?",
        "image": "https://media.kitsu.io/episodes/thumbnails/453/original.jpg",
        "number": 3,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-3"
    },
    {
        "id": "naruto-episode-4",
        "title": "Pass or Fail: Survival Test",
        "image": "https://media.kitsu.io/episodes/thumbnails/454/original.jpg",
        "number": 4,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-4"
    },
    {
        "id": "naruto-episode-5",
        "title": "You Failed! Kakashi's Final Decision",
        "image": "https://media.kitsu.io/episodes/thumbnails/455/original.jpg",
        "number": 5,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-5"
    },
    {
        "id": "naruto-episode-6",
        "title": "A Dangerous Mission! Journey to the Land of Waves!",
        "image": "https://media.kitsu.io/episodes/thumbnails/456/original.jpg",
        "number": 6,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-6"
    },
    {
        "id": "naruto-episode-7",
        "title": "The Assassin of the Mist!",
        "image": "https://media.kitsu.io/episodes/thumbnails/457/original.jpg",
        "number": 7,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-7"
    },
    {
        "id": "naruto-episode-8",
        "title": "The Oath of Pain",
        "image": "https://media.kitsu.io/episodes/thumbnails/458/original.jpg",
        "number": 8,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-8"
    },
    {
        "id": "naruto-episode-9",
        "title": "Kakashi: Sharingan Warrior",
        "image": "https://media.kitsu.io/episodes/thumbnails/459/original.jpg",
        "number": 9,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-9"
    },
    {
        "id": "naruto-episode-10",
        "title": "The Forest of Chakra",
        "image": "https://media.kitsu.io/episodes/thumbnails/460/original.jpg",
        "number": 10,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-10"
    },
    {
        "id": "naruto-episode-11",
        "title": "The Land Where a Hero Once Lived",
        "image": "https://media.kitsu.io/episodes/thumbnails/461/original.jpg",
        "number": 11,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-11"
    },
    {
        "id": "naruto-episode-12",
        "title": "Battle on the Bridge! Zabuza Returns!!",
        "image": "https://media.kitsu.io/episodes/thumbnails/462/original.jpg",
        "number": 12,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-12"
    },
    {
        "id": "naruto-episode-13",
        "title": "Haku's Secret Jutsu: Crystal Ice Mirrors",
        "image": "https://media.kitsu.io/episodes/thumbnails/463/original.jpg",
        "number": 13,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-13"
    },
    {
        "id": "naruto-episode-14",
        "title": "The Number One Hyperactive, Knucklehead Ninja Joins the Fight!!",
        "image": "https://media.kitsu.io/episodes/thumbnails/464/original.jpg",
        "number": 14,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-14"
    },
    {
        "id": "naruto-episode-15",
        "title": "Zero Visibility: The Sharingan Shatters",
        "image": "https://media.kitsu.io/episodes/thumbnails/465/original.jpg",
        "number": 15,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-15"
    },
    {
        "id": "naruto-episode-16",
        "title": "The Broken Seal",
        "image": "https://media.kitsu.io/episodes/thumbnails/466/original.jpg",
        "number": 16,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-16"
    },
    {
        "id": "naruto-episode-17",
        "title": "White Past: Hidden Ambition",
        "image": "https://media.kitsu.io/episodes/thumbnails/467/original.jpg",
        "number": 17,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-17"
    },
    {
        "id": "naruto-episode-18",
        "title": "The Weapons Known as Shinobi",
        "image": "https://media.kitsu.io/episodes/thumbnails/468/original.jpg",
        "number": 18,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-18"
    },
    {
        "id": "naruto-episode-19",
        "title": "The Demon in the Snow",
        "image": "https://media.kitsu.io/episodes/thumbnails/469/original.jpg",
        "number": 19,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-19"
    },
    {
        "id": "naruto-episode-20",
        "title": "A New Chapter Begins: The Chunin Exam!",
        "image": "https://media.kitsu.io/episodes/thumbnails/470/original.jpg",
        "number": 20,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-20"
    },
    {
        "id": "naruto-episode-21",
        "title": "Identify Yourself: Powerful New Rivals",
        "image": "https://media.kitsu.io/episodes/thumbnails/471/original.jpg",
        "number": 21,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-21"
    },
    {
        "id": "naruto-episode-22",
        "title": "Chunin Challenge: Rock Lee vs. Sasuke!",
        "image": "https://media.kitsu.io/episodes/thumbnails/472/original.jpg",
        "number": 22,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-22"
    },
    {
        "id": "naruto-episode-23",
        "title": "Genin Takedown! All Nine Rookies Face Off!",
        "image": "https://media.kitsu.io/episodes/thumbnails/473/original.jpg",
        "number": 23,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-23"
    },
    {
        "id": "naruto-episode-24",
        "title": "Start Your Engines: The Chunin Exam Begins!",
        "image": "https://media.kitsu.io/episodes/thumbnails/474/original.jpg",
        "number": 24,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-24"
    },
    {
        "id": "naruto-episode-25",
        "title": "The Tenth Question: All or Nothing!",
        "image": "https://media.kitsu.io/episodes/thumbnails/475/original.jpg",
        "number": 25,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-25"
    },
    {
        "id": "naruto-episode-26",
        "title": "Special Report: Live from the Forest of Death!",
        "image": "https://media.kitsu.io/episodes/thumbnails/476/original.jpg",
        "number": 26,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-26"
    },
    {
        "id": "naruto-episode-27",
        "title": "The Chunin Exam Stage 2: The Forest of Death",
        "image": "https://media.kitsu.io/episodes/thumbnails/477/original.jpg",
        "number": 27,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-27"
    },
    {
        "id": "naruto-episode-28",
        "title": "Eat or be Eaten: Panic in the Forest",
        "image": "https://media.kitsu.io/episodes/thumbnails/478/original.jpg",
        "number": 28,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-28"
    },
    {
        "id": "naruto-episode-29",
        "title": "Naruto's Counterattack: Never Give In!",
        "image": "https://media.kitsu.io/episodes/thumbnails/479/original.jpg",
        "number": 29,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-29"
    },
    {
        "id": "naruto-episode-30",
        "title": "The Sharingan Revived: Dragon-Flame Jutsu!",
        "image": "https://media.kitsu.io/episodes/thumbnails/480/original.jpg",
        "number": 30,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-30"
    },
    {
        "id": "naruto-episode-31",
        "title": "Bushy Brow's Pledge: Undying Love and Protection!",
        "image": "https://media.kitsu.io/episodes/thumbnails/481/original.jpg",
        "number": 31,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-31"
    },
    {
        "id": "naruto-episode-32",
        "title": "Sakura Blossoms!",
        "image": "https://media.kitsu.io/episodes/thumbnails/482/original.jpg",
        "number": 32,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-32"
    },
    {
        "id": "naruto-episode-33",
        "title": "Battle Formation: Ino-Shika-Cho!",
        "image": "https://media.kitsu.io/episodes/thumbnails/483/original.jpg",
        "number": 33,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-33"
    },
    {
        "id": "naruto-episode-34",
        "title": "Akamaru Trembles: Gaara's Cruel Strength!",
        "image": "https://media.kitsu.io/episodes/thumbnails/484/original.jpg",
        "number": 34,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-34"
    },
    {
        "id": "naruto-episode-35",
        "title": "The Scroll's Secret: No Peeking Allowed",
        "image": "https://media.kitsu.io/episodes/thumbnails/485/original.jpg",
        "number": 35,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-35"
    },
    {
        "id": "naruto-episode-36",
        "title": "Clone vs. Clone: Mine are Better than Yours!",
        "image": "https://media.kitsu.io/episodes/thumbnails/172591/original.jpeg",
        "number": 36,
        "createdAt": "2014-11-21T15:36:24Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-36"
    },
    {
        "id": "naruto-episode-37",
        "title": "Surviving the Cut! The Rookie Nine Together Again!",
        "image": "https://media.kitsu.io/episodes/thumbnails/172592/original.jpeg",
        "number": 37,
        "createdAt": "2014-11-21T15:36:25Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-37"
    },
    {
        "id": "naruto-episode-38",
        "title": "Narrowing the Field: Sudden Death Elimination!",
        "image": "https://media.kitsu.io/episodes/thumbnails/172593/original.jpeg",
        "number": 38,
        "createdAt": "2014-11-21T15:36:26Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-38"
    },
    {
        "id": "naruto-episode-39",
        "title": "Bushy Brow's Jealousy: Lions Barrage Unleashed!",
        "image": "https://media.kitsu.io/episodes/thumbnails/172594/original.jpeg",
        "number": 39,
        "createdAt": "2014-11-21T15:36:27Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-39"
    },
    {
        "id": "naruto-episode-40",
        "title": "Kakashi and Orochimaru: Face-to-Face!",
        "image": "https://media.kitsu.io/episodes/thumbnails/172595/original.jpeg",
        "number": 40,
        "createdAt": "2014-11-21T15:36:28Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-40"
    },
    {
        "id": "naruto-episode-41",
        "title": "Kunoichi Rumble: The Rivals Get Serious!",
        "image": "https://media.kitsu.io/episodes/thumbnails/172596/original.jpeg",
        "number": 41,
        "createdAt": "2014-11-21T15:36:29Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-41"
    },
    {
        "id": "naruto-episode-42",
        "title": "The Ultimate Battle: Cha!",
        "image": "https://media.kitsu.io/episodes/thumbnails/172597/original.jpeg",
        "number": 42,
        "createdAt": "2014-11-21T15:36:30Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-42"
    },
    {
        "id": "naruto-episode-43",
        "title": "Killer Kunoichi and a Shaky Shikamaru",
        "image": "https://media.kitsu.io/episodes/thumbnails/172598/original.jpeg",
        "number": 43,
        "createdAt": "2014-11-21T15:36:31Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-43"
    },
    {
        "id": "naruto-episode-44",
        "title": "Akamaru Unleashed! Who's Top Dog Now?",
        "image": "https://media.kitsu.io/episodes/thumbnails/172599/original.jpeg",
        "number": 44,
        "createdAt": "2014-11-21T15:36:32Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-44"
    },
    {
        "id": "naruto-episode-45",
        "title": "Surprise Attack! Naruto's Secret Weapon!",
        "image": "https://media.kitsu.io/episodes/thumbnails/172600/original.jpeg",
        "number": 45,
        "createdAt": "2014-11-21T15:36:33Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-45"
    },
    {
        "id": "naruto-episode-46",
        "title": "Byakugan Battle: Hinata Grows Bold!",
        "image": "https://media.kitsu.io/episodes/thumbnails/172601/original.jpeg",
        "number": 46,
        "createdAt": "2014-11-21T15:36:35Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-46"
    },
    {
        "id": "naruto-episode-47",
        "title": "A Failure Stands Tall!",
        "image": "https://media.kitsu.io/episodes/thumbnails/172602/original.jpeg",
        "number": 47,
        "createdAt": "2014-11-21T15:36:36Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-47"
    },
    {
        "id": "naruto-episode-48",
        "title": "Gaara vs. Rock Lee: The Power of Youth Explodes!",
        "image": "https://media.kitsu.io/episodes/thumbnails/172603/original.jpeg",
        "number": 48,
        "createdAt": "2014-11-21T15:36:37Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-48"
    },
    {
        "id": "naruto-episode-49",
        "title": "Lee's Hidden Strength: Forbidden Secret Jutsu!",
        "image": "https://media.kitsu.io/episodes/thumbnails/499/original.jpeg",
        "number": 49,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-49"
    },
    {
        "id": "naruto-episode-50",
        "title": "The Fifth Gate: A Splendid Ninja is Born",
        "image": "https://media.kitsu.io/episodes/thumbnails/500/original.jpeg",
        "number": 50,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-50"
    },
    {
        "id": "naruto-episode-51",
        "title": "A Shadow in Darkness: Danger Approaches Sasuke",
        "image": "https://media.kitsu.io/episodes/thumbnails/501/original.jpeg",
        "number": 51,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-51"
    },
    {
        "id": "naruto-episode-52",
        "title": "Ebisu Returns: Naruto's Toughest Training Yet!",
        "image": "https://media.kitsu.io/episodes/thumbnails/502/original.jpeg",
        "number": 52,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-52"
    },
    {
        "id": "naruto-episode-53",
        "title": "Long Time No See: Jiraiya Returns!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167771/original.jpg",
        "number": 53,
        "createdAt": "2014-11-18T14:21:30Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-53"
    },
    {
        "id": "naruto-episode-54",
        "title": "The Summoning Jutsu: Wisdom of the Toad Sage!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167772/original.jpg",
        "number": 54,
        "createdAt": "2014-11-18T14:21:33Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-54"
    },
    {
        "id": "naruto-episode-55",
        "title": "A Feeling of Yearning, A Flower Full of Hope",
        "image": "https://media.kitsu.io/episodes/thumbnails/167773/original.jpg",
        "number": 55,
        "createdAt": "2014-11-18T14:21:34Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-55"
    },
    {
        "id": "naruto-episode-56",
        "title": "Live or Die: Risk it All to Win it All!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167774/original.jpg",
        "number": 56,
        "createdAt": "2014-11-18T14:21:37Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-56"
    },
    {
        "id": "naruto-episode-57",
        "title": "He Flies! He Jumps! He Lurks! Chief Toad Appears!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167775/original.jpg",
        "number": 57,
        "createdAt": "2014-11-18T14:21:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-57"
    },
    {
        "id": "naruto-episode-58",
        "title": "Hospital Besieged: The Evil Hand Revealed!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167776/original.jpg",
        "number": 58,
        "createdAt": "2014-11-18T14:21:46Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-58"
    },
    {
        "id": "naruto-episode-59",
        "title": "The Final Rounds: Rush to the Battle Arena!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167777/original.jpg",
        "number": 59,
        "createdAt": "2014-11-18T14:21:49Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-59"
    },
    {
        "id": "naruto-episode-60",
        "title": "Byakugan vs. Shadow Clone",
        "image": "https://media.kitsu.io/episodes/thumbnails/167778/original.jpg",
        "number": 60,
        "createdAt": "2014-11-18T14:21:51Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-60"
    },
    {
        "id": "naruto-episode-61",
        "title": "Ultimate Defense: Zero Blind Spot!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167779/original.jpg",
        "number": 61,
        "createdAt": "2014-11-18T14:21:54Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-61"
    },
    {
        "id": "naruto-episode-62",
        "title": "A Failure's True Power",
        "image": "https://media.kitsu.io/episodes/thumbnails/167780/original.jpg",
        "number": 62,
        "createdAt": "2014-11-18T14:21:56Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-62"
    },
    {
        "id": "naruto-episode-63",
        "title": "Hit it or Quit it: The Final Rounds Get Complicated!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167781/original.jpg",
        "number": 63,
        "createdAt": "2014-11-18T14:21:57Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-63"
    },
    {
        "id": "naruto-episode-64",
        "title": "Zero Motivation: The Guy with Cloud Envy!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167782/original.jpg",
        "number": 64,
        "createdAt": "2014-11-18T14:21:59Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-64"
    },
    {
        "id": "naruto-episode-65",
        "title": "Dancing Leaf, Squirming Sand",
        "image": "https://media.kitsu.io/episodes/thumbnails/167783/original.jpg",
        "number": 65,
        "createdAt": "2014-11-18T14:22:02Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-65"
    },
    {
        "id": "naruto-episode-66",
        "title": "Bushy Brow's Jutsu: Sasuke Style!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167784/original.jpg",
        "number": 66,
        "createdAt": "2014-11-18T14:22:05Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-66"
    },
    {
        "id": "naruto-episode-67",
        "title": "Late for the Show, But Ready to Go! The Ultimate Secret Technique is Born!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167785/original.jpg",
        "number": 67,
        "createdAt": "2014-11-18T14:22:07Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-67"
    },
    {
        "id": "naruto-episode-68",
        "title": "Zero Hour! The Destruction of the Hidden Leaf Village Begins!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167786/original.jpg",
        "number": 68,
        "createdAt": "2014-11-18T14:22:09Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-68"
    },
    {
        "id": "naruto-episode-69",
        "title": "Village in Distress: A New A-Ranked Mission!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167787/original.jpg",
        "number": 69,
        "createdAt": "2014-11-18T14:22:10Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-69"
    },
    {
        "id": "naruto-episode-70",
        "title": "A Shirker's Call to Action: A Layabout No More!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167788/original.jpg",
        "number": 70,
        "createdAt": "2014-11-18T14:22:13Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-70"
    },
    {
        "id": "naruto-episode-71",
        "title": "An Unrivaled Match: Hokage Battle Royale!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167789/original.jpg",
        "number": 71,
        "createdAt": "2014-11-18T14:22:16Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-71"
    },
    {
        "id": "naruto-episode-72",
        "title": "A Mistake from the Past: A Face Revealed!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167790/original.jpg",
        "number": 72,
        "createdAt": "2014-11-18T14:22:19Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-72"
    },
    {
        "id": "naruto-episode-73",
        "title": "Forbidden Secret Technique: Reaper Death Seal!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167791/original.jpg",
        "number": 73,
        "createdAt": "2014-11-18T14:22:21Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-73"
    },
    {
        "id": "naruto-episode-74",
        "title": "Astonishing Truth! Gaara's Identity Emerges!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167792/original.jpg",
        "number": 74,
        "createdAt": "2014-11-18T14:22:24Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-74"
    },
    {
        "id": "naruto-episode-75",
        "title": "Sasuke's Decision: Pushed to the Edge!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167793/original.jpg",
        "number": 75,
        "createdAt": "2014-11-18T14:22:28Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-75"
    },
    {
        "id": "naruto-episode-76",
        "title": "Assassin of the Moonlit Night",
        "image": "https://media.kitsu.io/episodes/thumbnails/167794/original.jpg",
        "number": 76,
        "createdAt": "2014-11-18T14:22:32Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-76"
    },
    {
        "id": "naruto-episode-77",
        "title": "Light vs. Dark: The Two Faces of Gaara",
        "image": "https://media.kitsu.io/episodes/thumbnails/167795/original.jpg",
        "number": 77,
        "createdAt": "2014-11-18T14:22:34Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-77"
    },
    {
        "id": "naruto-episode-78",
        "title": "Naruto's Ninja Handbook",
        "image": "https://media.kitsu.io/episodes/thumbnails/167796/original.jpg",
        "number": 78,
        "createdAt": "2014-11-18T14:22:36Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-78"
    },
    {
        "id": "naruto-episode-79",
        "title": "Beyond the Limit of Darkness and Light",
        "image": "https://media.kitsu.io/episodes/thumbnails/167797/original.jpg",
        "number": 79,
        "createdAt": "2014-11-18T14:22:38Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-79"
    },
    {
        "id": "naruto-episode-80",
        "title": "The Third Hokage, Forever...",
        "image": "https://media.kitsu.io/episodes/thumbnails/167798/original.jpg",
        "number": 80,
        "createdAt": "2014-11-18T14:22:39Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-80"
    },
    {
        "id": "naruto-episode-81",
        "title": "Return of the Morning Mist",
        "image": "https://media.kitsu.io/episodes/thumbnails/167799/original.jpg",
        "number": 81,
        "createdAt": "2014-11-18T14:22:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-81"
    },
    {
        "id": "naruto-episode-82",
        "title": "Eye to Eye: Sharingan vs. Sharingan!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167800/original.jpg",
        "number": 82,
        "createdAt": "2014-11-18T14:22:44Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-82"
    },
    {
        "id": "naruto-episode-83",
        "title": "Jiraiya: Naruto's Potential Disaster!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167801/original.jpg",
        "number": 83,
        "createdAt": "2014-11-18T14:22:46Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-83"
    },
    {
        "id": "naruto-episode-84",
        "title": "Roar, Chidori! Brother vs. Brother!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167802/original.jpg",
        "number": 84,
        "createdAt": "2014-11-18T14:22:49Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-84"
    },
    {
        "id": "naruto-episode-85",
        "title": "Hate Among the Uchihas: The Last of the Clan!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167803/original.jpg",
        "number": 85,
        "createdAt": "2014-11-18T14:22:50Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-85"
    },
    {
        "id": "naruto-episode-86",
        "title": "A New Training Begins: I Will Be Strong!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167804/original.jpg",
        "number": 86,
        "createdAt": "2014-11-18T14:22:52Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-86"
    },
    {
        "id": "naruto-episode-87",
        "title": "Keep on Training: Pop Goes the Water Balloon!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167805/original.jpg",
        "number": 87,
        "createdAt": "2014-11-18T14:22:55Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-87"
    },
    {
        "id": "naruto-episode-88",
        "title": "Focal Point: The Mark of the Leaf",
        "image": "https://media.kitsu.io/episodes/thumbnails/486/original.jpg",
        "number": 88,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-88"
    },
    {
        "id": "naruto-episode-89",
        "title": "An Impossible Choice: The Pain Within Tsunade's Heart",
        "image": "https://media.kitsu.io/episodes/thumbnails/487/original.jpg",
        "number": 89,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-89"
    },
    {
        "id": "naruto-episode-90",
        "title": "Unforgivable! A Total Lack of Respect!",
        "image": "https://media.kitsu.io/episodes/thumbnails/488/original.jpg",
        "number": 90,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-90"
    },
    {
        "id": "naruto-episode-91",
        "title": "Inheritence! The Necklace of Death!",
        "image": "https://media.kitsu.io/episodes/thumbnails/489/original.jpg",
        "number": 91,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-91"
    },
    {
        "id": "naruto-episode-92",
        "title": "A Dubious Offer! Tsunade's Choice!",
        "image": "https://media.kitsu.io/episodes/thumbnails/490/original.jpg",
        "number": 92,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-92"
    },
    {
        "id": "naruto-episode-93",
        "title": "Breakdown! The Deal is Off!",
        "image": "https://media.kitsu.io/episodes/thumbnails/491/original.jpg",
        "number": 93,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-93"
    },
    {
        "id": "naruto-episode-94",
        "title": "Attack! Fury of the Rasengan!",
        "image": "https://media.kitsu.io/episodes/thumbnails/492/original.jpg",
        "number": 94,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-94"
    },
    {
        "id": "naruto-episode-95",
        "title": "The Fifth Hokage! A Life on the Line!",
        "image": "https://media.kitsu.io/episodes/thumbnails/493/original.jpg",
        "number": 95,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-95"
    },
    {
        "id": "naruto-episode-96",
        "title": "Deadlock! Sannin Showdown!",
        "image": "https://media.kitsu.io/episodes/thumbnails/494/original.jpg",
        "number": 96,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-96"
    },
    {
        "id": "naruto-episode-97",
        "title": "Kidnapped! Naruto's Hot Spring Adventure!",
        "image": "https://media.kitsu.io/episodes/thumbnails/495/original.jpg",
        "number": 97,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-97"
    },
    {
        "id": "naruto-episode-98",
        "title": "Tsunade's Warning: Ninja No More!",
        "image": "https://media.kitsu.io/episodes/thumbnails/496/original.jpg",
        "number": 98,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-98"
    },
    {
        "id": "naruto-episode-99",
        "title": "The Will of Fire Still Burns!",
        "image": "https://media.kitsu.io/episodes/thumbnails/497/original.jpg",
        "number": 99,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-99"
    },
    {
        "id": "naruto-episode-100",
        "title": "Sensei and Student: The Bond of the Shinobi",
        "image": "https://media.kitsu.io/episodes/thumbnails/498/original.jpg",
        "number": 100,
        "createdAt": "2013-02-20T18:20:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-100"
    },
    {
        "id": "naruto-episode-101",
        "title": "Gotta See! Gotta Know! Kakashi-Sensei's True Face!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167807/original.jpg",
        "number": 101,
        "createdAt": "2014-11-18T14:23:34Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-101"
    },
    {
        "id": "naruto-episode-102",
        "title": "Mission: Help an Old Friend in the Land of Tea",
        "image": "https://media.kitsu.io/episodes/thumbnails/167808/original.jpg",
        "number": 102,
        "createdAt": "2014-11-18T14:23:37Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-102"
    },
    {
        "id": "naruto-episode-103",
        "title": "The Race is on! Trouble on the High Seas!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167810/original.jpg",
        "number": 103,
        "createdAt": "2014-11-18T14:23:39Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-103"
    },
    {
        "id": "naruto-episode-104",
        "title": "Run Idate Run! Nagi Island Awaits!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167811/original.jpg",
        "number": 104,
        "createdAt": "2014-11-18T14:23:40Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-104"
    },
    {
        "id": "naruto-episode-105",
        "title": "A Fierce Battle of Rolling Thunder!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167813/original.jpg",
        "number": 105,
        "createdAt": "2014-11-18T14:23:43Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-105"
    },
    {
        "id": "naruto-episode-106",
        "title": "The Last Leg: A Final Act of Desperation",
        "image": "https://media.kitsu.io/episodes/thumbnails/167816/original.jpg",
        "number": 106,
        "createdAt": "2014-11-18T14:23:45Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-106"
    },
    {
        "id": "naruto-episode-107",
        "title": "The Battle Begins: Naruto vs. Sasuke",
        "image": "https://media.kitsu.io/episodes/thumbnails/167818/original.jpg",
        "number": 107,
        "createdAt": "2014-11-18T14:23:46Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-107"
    },
    {
        "id": "naruto-episode-108",
        "title": "Bitter Rivals and Broken Bonds",
        "image": "https://media.kitsu.io/episodes/thumbnails/167820/original.jpg",
        "number": 108,
        "createdAt": "2014-11-18T14:23:48Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-108"
    },
    {
        "id": "naruto-episode-109",
        "title": "An Invitation from the Sound",
        "image": "https://media.kitsu.io/episodes/thumbnails/167822/original.jpg",
        "number": 109,
        "createdAt": "2014-11-18T14:23:49Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-109"
    },
    {
        "id": "naruto-episode-110",
        "title": "Formation! The Sasuke Retrieval Squad",
        "image": "https://media.kitsu.io/episodes/thumbnails/167824/original.jpg",
        "number": 110,
        "createdAt": "2014-11-18T14:23:52Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-110"
    },
    {
        "id": "naruto-episode-111",
        "title": "Sound vs. Leaf",
        "image": "https://media.kitsu.io/episodes/thumbnails/167828/original.jpg",
        "number": 111,
        "createdAt": "2014-11-18T14:23:54Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-111"
    },
    {
        "id": "naruto-episode-112",
        "title": "Squad Mutiny: Everything Falls Apart!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167830/original.jpg",
        "number": 112,
        "createdAt": "2014-11-18T14:23:56Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-112"
    },
    {
        "id": "naruto-episode-113",
        "title": "Full Throttle Power! Choji, Ablaze!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167831/original.jpg",
        "number": 113,
        "createdAt": "2014-11-18T14:23:58Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-113"
    },
    {
        "id": "naruto-episode-114",
        "title": "Good-bye Old Friend...! I'll Always Believe in You!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167835/original.jpg",
        "number": 114,
        "createdAt": "2014-11-18T14:24:00Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-114"
    },
    {
        "id": "naruto-episode-115",
        "title": "Your Opponent Is Me!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167837/original.jpg",
        "number": 115,
        "createdAt": "2014-11-18T14:24:02Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-115"
    },
    {
        "id": "naruto-episode-116",
        "title": "360 Degrees of Vision: The Byakugan's Blind Spot",
        "image": "https://media.kitsu.io/episodes/thumbnails/167840/original.jpg",
        "number": 116,
        "createdAt": "2014-11-18T14:24:03Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-116"
    },
    {
        "id": "naruto-episode-117",
        "title": "Losing is Not an Option!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167843/original.jpg",
        "number": 117,
        "createdAt": "2014-11-18T14:24:06Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-117"
    },
    {
        "id": "naruto-episode-118",
        "title": "The Vessel Arrives Too Late",
        "image": "https://media.kitsu.io/episodes/thumbnails/167848/original.jpg",
        "number": 118,
        "createdAt": "2014-11-18T14:24:10Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-118"
    },
    {
        "id": "naruto-episode-119",
        "title": "Miscalculation: A New Enemy Appears!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167851/original.jpg",
        "number": 119,
        "createdAt": "2014-11-18T14:24:13Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-119"
    },
    {
        "id": "naruto-episode-120",
        "title": "Roar and Howl! The Ultimate Tag Team",
        "image": "https://media.kitsu.io/episodes/thumbnails/167854/original.jpg",
        "number": 120,
        "createdAt": "2014-11-18T14:24:14Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-120"
    },
    {
        "id": "naruto-episode-121",
        "title": "To Each His Own Battle",
        "image": "https://media.kitsu.io/episodes/thumbnails/167857/original.jpg",
        "number": 121,
        "createdAt": "2014-11-18T14:24:17Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-121"
    },
    {
        "id": "naruto-episode-122",
        "title": "Fakeout: Shikamaru's Comeback!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167860/original.jpg",
        "number": 122,
        "createdAt": "2014-11-18T14:24:19Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-122"
    },
    {
        "id": "naruto-episode-123",
        "title": "The Leaf's Handsome Devil!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167863/original.jpg",
        "number": 123,
        "createdAt": "2014-11-18T14:24:21Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-123"
    },
    {
        "id": "naruto-episode-124",
        "title": "The Beast Within",
        "image": "https://media.kitsu.io/episodes/thumbnails/167864/original.jpg",
        "number": 124,
        "createdAt": "2014-11-18T14:24:22Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-124"
    },
    {
        "id": "naruto-episode-125",
        "title": "The Sand Shinobi: Allies of the Leaf",
        "image": "https://media.kitsu.io/episodes/thumbnails/167866/original.jpg",
        "number": 125,
        "createdAt": "2014-11-18T14:24:24Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-125"
    },
    {
        "id": "naruto-episode-126",
        "title": "Showdown: Gaara vs. Kimimaro!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167869/original.jpg",
        "number": 126,
        "createdAt": "2014-11-18T14:24:27Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-126"
    },
    {
        "id": "naruto-episode-127",
        "title": "Vengeful Strike! The Bracken Dance",
        "image": "https://media.kitsu.io/episodes/thumbnails/167872/original.jpg",
        "number": 127,
        "createdAt": "2014-11-18T14:24:29Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-127"
    },
    {
        "id": "naruto-episode-128",
        "title": "A Cry on Deaf Ears",
        "image": "https://media.kitsu.io/episodes/thumbnails/167875/original.jpg",
        "number": 128,
        "createdAt": "2014-11-18T14:24:32Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-128"
    },
    {
        "id": "naruto-episode-129",
        "title": "Brothers: Distance Among the Uchiha",
        "image": "https://media.kitsu.io/episodes/thumbnails/167878/original.jpg",
        "number": 129,
        "createdAt": "2014-11-18T14:24:34Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-129"
    },
    {
        "id": "naruto-episode-130",
        "title": "Father and Son, the Broken Crest",
        "image": "https://media.kitsu.io/episodes/thumbnails/167881/original.jpg",
        "number": 130,
        "createdAt": "2014-11-18T14:24:39Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-130"
    },
    {
        "id": "naruto-episode-131",
        "title": "The Secrets of the Mangekyo Sharingan!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167885/original.jpg",
        "number": 131,
        "createdAt": "2014-11-18T14:24:45Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-131"
    },
    {
        "id": "naruto-episode-132",
        "title": "For a Friend",
        "image": "https://media.kitsu.io/episodes/thumbnails/167886/original.jpg",
        "number": 132,
        "createdAt": "2014-11-18T14:24:49Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-132"
    },
    {
        "id": "naruto-episode-133",
        "title": "A Plea From a Friend",
        "image": "https://media.kitsu.io/episodes/thumbnails/167891/original.jpg",
        "number": 133,
        "createdAt": "2014-11-18T14:24:53Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-133"
    },
    {
        "id": "naruto-episode-134",
        "title": "The End of Tears",
        "image": "https://media.kitsu.io/episodes/thumbnails/167894/original.jpg",
        "number": 134,
        "createdAt": "2014-11-18T14:25:00Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-134"
    },
    {
        "id": "naruto-episode-135",
        "title": "The Promise That Could Not Be Kept",
        "image": "https://media.kitsu.io/episodes/thumbnails/167896/original.jpg",
        "number": 135,
        "createdAt": "2014-11-18T14:25:04Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-135"
    },
    {
        "id": "naruto-episode-136",
        "title": "Deep Cover!? A Super S-Ranked Mission!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167898/original.jpg",
        "number": 136,
        "createdAt": "2014-11-18T14:25:06Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-136"
    },
    {
        "id": "naruto-episode-137",
        "title": "A Town of Outlaws, the Shadow of the Fuma Clan",
        "image": "https://media.kitsu.io/episodes/thumbnails/167900/original.jpg",
        "number": 137,
        "createdAt": "2014-11-18T14:25:09Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-137"
    },
    {
        "id": "naruto-episode-138",
        "title": "Pure Betrayal, and a Fleeting Plea!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167901/original.jpg",
        "number": 138,
        "createdAt": "2014-11-18T14:25:13Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-138"
    },
    {
        "id": "naruto-episode-139",
        "title": "Pure Terror! The House of Orochimaru",
        "image": "https://media.kitsu.io/episodes/thumbnails/167903/original.jpg",
        "number": 139,
        "createdAt": "2014-11-18T14:25:15Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-139"
    },
    {
        "id": "naruto-episode-140",
        "title": "Two Heartbeats: Kabuto's Trap",
        "image": "https://media.kitsu.io/episodes/thumbnails/167905/original.jpg",
        "number": 140,
        "createdAt": "2014-11-18T14:25:17Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-140"
    },
    {
        "id": "naruto-episode-141",
        "title": "Sakura's Determination",
        "image": "https://media.kitsu.io/episodes/thumbnails/167906/original.jpg",
        "number": 141,
        "createdAt": "2014-11-18T14:25:21Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-141"
    },
    {
        "id": "naruto-episode-142",
        "title": "The Three Villains from the Maximum Security Prison",
        "image": "https://media.kitsu.io/episodes/thumbnails/167908/original.jpg",
        "number": 142,
        "createdAt": "2014-11-18T14:25:23Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-142"
    },
    {
        "id": "naruto-episode-143",
        "title": "Tonton! I'm Counting on You!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167910/original.jpg",
        "number": 143,
        "createdAt": "2014-11-18T14:25:25Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-143"
    },
    {
        "id": "naruto-episode-144",
        "title": "A New Squad! Two People and a Dog?!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167912/original.jpg",
        "number": 144,
        "createdAt": "2014-11-18T14:25:29Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-144"
    },
    {
        "id": "naruto-episode-145",
        "title": "A New Formation: Ino-Shika-Cho!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167914/original.jpg",
        "number": 145,
        "createdAt": "2014-11-18T14:25:32Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-145"
    },
    {
        "id": "naruto-episode-146",
        "title": "Orochimaru's Shadow",
        "image": "https://media.kitsu.io/episodes/thumbnails/167915/original.jpg",
        "number": 146,
        "createdAt": "2014-11-18T14:25:35Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-146"
    },
    {
        "id": "naruto-episode-147",
        "title": "A Clash of Fate: You Can't Bring Me Down",
        "image": "https://media.kitsu.io/episodes/thumbnails/167918/original.jpg",
        "number": 147,
        "createdAt": "2014-11-18T14:25:38Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-147"
    },
    {
        "id": "naruto-episode-148",
        "title": "Search for the Rare Bikochu Beetle",
        "image": "https://media.kitsu.io/episodes/thumbnails/167920/original.jpg",
        "number": 148,
        "createdAt": "2014-11-18T14:25:40Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-148"
    },
    {
        "id": "naruto-episode-149",
        "title": "What's the Difference? Don't All Insects Look Alike?",
        "image": "https://media.kitsu.io/episodes/thumbnails/167921/original.jpg",
        "number": 149,
        "createdAt": "2014-11-18T14:25:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-149"
    },
    {
        "id": "naruto-episode-150",
        "title": "A Battle of Bugs: The Deceivers and the Deceived",
        "image": "https://media.kitsu.io/episodes/thumbnails/167923/original.jpg",
        "number": 150,
        "createdAt": "2014-11-18T14:25:42Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-150"
    },
    {
        "id": "naruto-episode-151",
        "title": "Blaze Away Byakugan: This Is My Ninja Way",
        "image": "https://media.kitsu.io/episodes/thumbnails/167924/original.jpg",
        "number": 151,
        "createdAt": "2014-11-18T14:25:44Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-151"
    },
    {
        "id": "naruto-episode-152",
        "title": "Funeral March for the Living",
        "image": "https://media.kitsu.io/episodes/thumbnails/167926/original.jpg",
        "number": 152,
        "createdAt": "2014-11-18T14:25:46Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-152"
    },
    {
        "id": "naruto-episode-153",
        "title": "A Lesson Learned: The Iron Fist of Love",
        "image": "https://media.kitsu.io/episodes/thumbnails/167928/original.jpg",
        "number": 153,
        "createdAt": "2014-11-18T14:25:48Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-153"
    },
    {
        "id": "naruto-episode-154",
        "title": "The Enemy of the Byakugan",
        "image": "https://media.kitsu.io/episodes/thumbnails/167930/original.jpg",
        "number": 154,
        "createdAt": "2014-11-18T14:25:50Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-154"
    },
    {
        "id": "naruto-episode-155",
        "title": "The Dark Creeping Clouds",
        "image": "https://media.kitsu.io/episodes/thumbnails/167931/original.jpg",
        "number": 155,
        "createdAt": "2014-11-18T14:25:52Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-155"
    },
    {
        "id": "naruto-episode-156",
        "title": "Raiga's Counterattack",
        "image": "https://media.kitsu.io/episodes/thumbnails/167933/original.jpg",
        "number": 156,
        "createdAt": "2014-11-18T14:25:55Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-156"
    },
    {
        "id": "naruto-episode-157",
        "title": "Run! The Curry of Life",
        "image": "https://media.kitsu.io/episodes/thumbnails/167934/original.jpg",
        "number": 157,
        "createdAt": "2014-11-18T14:25:57Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-157"
    },
    {
        "id": "naruto-episode-158",
        "title": "Follow My Lead! The Great Survival Challenge",
        "image": "https://media.kitsu.io/episodes/thumbnails/167936/original.jpg",
        "number": 158,
        "createdAt": "2014-11-18T14:26:00Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-158"
    },
    {
        "id": "naruto-episode-159",
        "title": "Bounty Hunter from the Wilderness",
        "image": "https://media.kitsu.io/episodes/thumbnails/167939/original.jpg",
        "number": 159,
        "createdAt": "2014-11-18T14:26:04Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-159"
    },
    {
        "id": "naruto-episode-160",
        "title": "Hunt or Be Hunted?! Showdown at the O.K. Temple!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167942/original.jpg",
        "number": 160,
        "createdAt": "2014-11-18T14:26:07Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-160"
    },
    {
        "id": "naruto-episode-161",
        "title": "The Appearance of Strange Visitors",
        "image": "https://media.kitsu.io/episodes/thumbnails/167943/original.jpg",
        "number": 161,
        "createdAt": "2014-11-18T14:26:09Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-161"
    },
    {
        "id": "naruto-episode-162",
        "title": "The Cursed Warrior",
        "image": "https://media.kitsu.io/episodes/thumbnails/167944/original.jpg",
        "number": 162,
        "createdAt": "2014-11-18T14:26:12Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-162"
    },
    {
        "id": "naruto-episode-163",
        "title": "The Tactician's Intent",
        "image": "https://media.kitsu.io/episodes/thumbnails/167945/original.jpg",
        "number": 163,
        "createdAt": "2014-11-18T14:26:15Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-163"
    },
    {
        "id": "naruto-episode-164",
        "title": "Too Late for Help",
        "image": "https://media.kitsu.io/episodes/thumbnails/167946/original.jpg",
        "number": 164,
        "createdAt": "2014-11-18T14:26:18Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-164"
    },
    {
        "id": "naruto-episode-165",
        "title": "The Death of Naruto",
        "image": "https://media.kitsu.io/episodes/thumbnails/167947/original.jpg",
        "number": 165,
        "createdAt": "2014-11-18T14:26:20Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-165"
    },
    {
        "id": "naruto-episode-166",
        "title": "When Time Stands Still",
        "image": "https://media.kitsu.io/episodes/thumbnails/167948/original.jpg",
        "number": 166,
        "createdAt": "2014-11-18T14:26:25Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-166"
    },
    {
        "id": "naruto-episode-167",
        "title": "When Egrets Flap Their Wings",
        "image": "https://media.kitsu.io/episodes/thumbnails/167949/original.jpg",
        "number": 167,
        "createdAt": "2014-11-18T14:26:26Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-167"
    },
    {
        "id": "naruto-episode-168",
        "title": "Mix It, Stretch It, Boil It Up! Burn, Copper Pot, Burn!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167950/original.jpg",
        "number": 168,
        "createdAt": "2014-11-18T14:26:28Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-168"
    },
    {
        "id": "naruto-episode-169",
        "title": "Remembrance: The Lost Page",
        "image": "https://media.kitsu.io/episodes/thumbnails/167951/original.jpg",
        "number": 169,
        "createdAt": "2014-11-18T14:26:30Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-169"
    },
    {
        "id": "naruto-episode-170",
        "title": "The Closed Door",
        "image": "https://media.kitsu.io/episodes/thumbnails/167952/original.jpg",
        "number": 170,
        "createdAt": "2014-11-18T14:26:31Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-170"
    },
    {
        "id": "naruto-episode-171",
        "title": "Infiltration: The Set-Up!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167953/original.jpg",
        "number": 171,
        "createdAt": "2014-11-18T14:26:32Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-171"
    },
    {
        "id": "naruto-episode-172",
        "title": "Despair: A Fractured Heart",
        "image": "https://media.kitsu.io/episodes/thumbnails/167954/original.jpg",
        "number": 172,
        "createdAt": "2014-11-18T14:26:34Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-172"
    },
    {
        "id": "naruto-episode-173",
        "title": "The Battle at Sea: The Power Unleashed!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167955/original.jpg",
        "number": 173,
        "createdAt": "2014-11-18T14:26:35Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-173"
    },
    {
        "id": "naruto-episode-174",
        "title": "Impossible! Celebrity Ninja Art - Money Style Jutsu!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167956/original.jpg",
        "number": 174,
        "createdAt": "2014-11-18T14:26:36Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-174"
    },
    {
        "id": "naruto-episode-175",
        "title": "The Treasure Hunt is On!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167957/original.jpg",
        "number": 175,
        "createdAt": "2014-11-18T14:26:38Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-175"
    },
    {
        "id": "naruto-episode-176",
        "title": "Run, Dodge, Zigzag! Chase or Be Chased!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167958/original.jpg",
        "number": 176,
        "createdAt": "2014-11-18T14:26:40Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-176"
    },
    {
        "id": "naruto-episode-177",
        "title": "Please, Mr. Postman!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167959/original.jpg",
        "number": 177,
        "createdAt": "2014-11-18T14:26:41Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-177"
    },
    {
        "id": "naruto-episode-178",
        "title": "Encounter! The Boy with a Star's Name",
        "image": "https://media.kitsu.io/episodes/thumbnails/167960/original.jpg",
        "number": 178,
        "createdAt": "2014-11-18T14:26:43Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-178"
    },
    {
        "id": "naruto-episode-179",
        "title": "The Remembered Lullaby",
        "image": "https://media.kitsu.io/episodes/thumbnails/167961/original.jpg",
        "number": 179,
        "createdAt": "2014-11-18T14:26:46Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-179"
    },
    {
        "id": "naruto-episode-180",
        "title": "Hidden Jutsu: The Price of The Ninja Art: Kujaku",
        "image": "https://media.kitsu.io/episodes/thumbnails/167962/original.jpg",
        "number": 180,
        "createdAt": "2014-11-18T14:26:48Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-180"
    },
    {
        "id": "naruto-episode-181",
        "title": "Hoshikage, The Buried Truth",
        "image": "https://media.kitsu.io/episodes/thumbnails/167963/original.jpg",
        "number": 181,
        "createdAt": "2014-11-18T14:26:50Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-181"
    },
    {
        "id": "naruto-episode-182",
        "title": "Reunion, The Remaining time",
        "image": "https://media.kitsu.io/episodes/thumbnails/167964/original.jpg",
        "number": 182,
        "createdAt": "2014-11-18T14:26:52Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-182"
    },
    {
        "id": "naruto-episode-183",
        "title": "The Star's Radiance",
        "image": "https://media.kitsu.io/episodes/thumbnails/167965/original.jpg",
        "number": 183,
        "createdAt": "2014-11-18T14:26:55Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-183"
    },
    {
        "id": "naruto-episode-184",
        "title": "Kiba's Long Day!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167966/original.jpg",
        "number": 184,
        "createdAt": "2014-11-18T14:26:56Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-184"
    },
    {
        "id": "naruto-episode-185",
        "title": "A Legend from the Hidden Leaf: The Onbaa!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167967/original.jpg",
        "number": 185,
        "createdAt": "2014-11-18T14:26:59Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-185"
    },
    {
        "id": "naruto-episode-186",
        "title": "Laughing Shino",
        "image": "https://media.kitsu.io/episodes/thumbnails/167968/original.jpg",
        "number": 186,
        "createdAt": "2014-11-18T14:27:02Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-186"
    },
    {
        "id": "naruto-episode-187",
        "title": "Open for Business! The Leaf Moving Service",
        "image": "https://media.kitsu.io/episodes/thumbnails/167969/original.jpg",
        "number": 187,
        "createdAt": "2014-11-18T14:27:04Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-187"
    },
    {
        "id": "naruto-episode-188",
        "title": "Mystery of the Targeted Merchants",
        "image": "https://media.kitsu.io/episodes/thumbnails/167970/original.jpg",
        "number": 188,
        "createdAt": "2014-11-18T14:27:06Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-188"
    },
    {
        "id": "naruto-episode-189",
        "title": "A Limitless Supply of Ninja Tools",
        "image": "https://media.kitsu.io/episodes/thumbnails/167971/original.jpg",
        "number": 189,
        "createdAt": "2014-11-18T14:27:08Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-189"
    },
    {
        "id": "naruto-episode-190",
        "title": "The Byakugan Sees the Blind Spot",
        "image": "https://media.kitsu.io/episodes/thumbnails/167972/original.jpg",
        "number": 190,
        "createdAt": "2014-11-18T14:27:09Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-190"
    },
    {
        "id": "naruto-episode-191",
        "title": "Forecast: Death! Cloudy with Chance of Sun",
        "image": "https://media.kitsu.io/episodes/thumbnails/167973/original.jpg",
        "number": 191,
        "createdAt": "2014-11-18T14:27:13Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-191"
    },
    {
        "id": "naruto-episode-192",
        "title": "Ino Screams! Chubby Paradise!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167975/original.jpg",
        "number": 192,
        "createdAt": "2014-11-18T14:27:15Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-192"
    },
    {
        "id": "naruto-episode-193",
        "title": "Viva Dojo Challenge! Youth Is All About Passion!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167976/original.jpg",
        "number": 193,
        "createdAt": "2014-11-18T14:27:18Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-193"
    },
    {
        "id": "naruto-episode-194",
        "title": "The Mysterious Curse of the Haunted Castle",
        "image": "https://media.kitsu.io/episodes/thumbnails/167977/original.jpg",
        "number": 194,
        "createdAt": "2014-11-18T14:27:19Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-194"
    },
    {
        "id": "naruto-episode-195",
        "title": "The Third Super-Beast!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167978/original.jpg",
        "number": 195,
        "createdAt": "2014-11-18T14:27:21Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-195"
    },
    {
        "id": "naruto-episode-196",
        "title": "Hot-Blooded Confrontation: Student vs. Sensei",
        "image": "https://media.kitsu.io/episodes/thumbnails/167979/original.jpg",
        "number": 196,
        "createdAt": "2014-11-18T14:27:23Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-196"
    },
    {
        "id": "naruto-episode-197",
        "title": "Crisis! The Hidden Leaf 11 Gather!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167980/original.jpg",
        "number": 197,
        "createdAt": "2014-11-18T14:27:25Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-197"
    },
    {
        "id": "naruto-episode-198",
        "title": "The ANBU Gives Up? Naruto's Recollection",
        "image": "https://media.kitsu.io/episodes/thumbnails/167981/original.jpg",
        "number": 198,
        "createdAt": "2014-11-18T14:27:29Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-198"
    },
    {
        "id": "naruto-episode-199",
        "title": "The Missed Target",
        "image": "https://media.kitsu.io/episodes/thumbnails/167982/original.jpg",
        "number": 199,
        "createdAt": "2014-11-18T14:27:30Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-199"
    },
    {
        "id": "naruto-episode-200",
        "title": "The Powerful Helper",
        "image": "https://media.kitsu.io/episodes/thumbnails/167983/original.jpg",
        "number": 200,
        "createdAt": "2014-11-18T14:27:32Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-200"
    },
    {
        "id": "naruto-episode-201",
        "title": "Multiple Traps! Countdown to Destruction",
        "image": "https://media.kitsu.io/episodes/thumbnails/167984/original.jpg",
        "number": 201,
        "createdAt": "2014-11-18T14:27:35Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-201"
    },
    {
        "id": "naruto-episode-202",
        "title": "The Top 5 Ninja Battles",
        "image": "https://media.kitsu.io/episodes/thumbnails/167985/original.jpg",
        "number": 202,
        "createdAt": "2014-11-18T14:27:37Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-202"
    },
    {
        "id": "naruto-episode-203",
        "title": "Kurenai's Decision, Squad 8 Left Behind",
        "image": "https://media.kitsu.io/episodes/thumbnails/167986/original.jpg",
        "number": 203,
        "createdAt": "2014-11-18T14:27:39Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-203"
    },
    {
        "id": "naruto-episode-204",
        "title": "Yakumo's Sealed Ability",
        "image": "https://media.kitsu.io/episodes/thumbnails/167987/original.jpg",
        "number": 204,
        "createdAt": "2014-11-18T14:27:42Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-204"
    },
    {
        "id": "naruto-episode-205",
        "title": "Kurenai's Top-Secret Mission: The Promise With the Third Hokage",
        "image": "https://media.kitsu.io/episodes/thumbnails/167988/original.jpg",
        "number": 205,
        "createdAt": "2014-11-18T14:27:45Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-205"
    },
    {
        "id": "naruto-episode-206",
        "title": "Genjutsu or Reality?",
        "image": "https://media.kitsu.io/episodes/thumbnails/167989/original.jpg",
        "number": 206,
        "createdAt": "2014-11-18T14:27:46Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-206"
    },
    {
        "id": "naruto-episode-207",
        "title": "The Supposed Sealed Ability",
        "image": "https://media.kitsu.io/episodes/thumbnails/167990/original.jpg",
        "number": 207,
        "createdAt": "2014-11-18T14:27:48Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-207"
    },
    {
        "id": "naruto-episode-208",
        "title": "The Weight of the Prized Artifact!",
        "image": "https://media.kitsu.io/episodes/thumbnails/167991/original.jpg",
        "number": 208,
        "createdAt": "2014-11-18T14:27:50Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-208"
    },
    {
        "id": "naruto-episode-209",
        "title": "The Enemy: Ninja Dropouts",
        "image": "https://media.kitsu.io/episodes/thumbnails/167992/original.jpg",
        "number": 209,
        "createdAt": "2014-11-18T14:27:51Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-209"
    },
    {
        "id": "naruto-episode-210",
        "title": "The Bewildering Forest",
        "image": "https://media.kitsu.io/episodes/thumbnails/167993/original.jpg",
        "number": 210,
        "createdAt": "2014-11-18T14:27:54Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-210"
    },
    {
        "id": "naruto-episode-211",
        "title": "Memory of Flames",
        "image": "https://media.kitsu.io/episodes/thumbnails/167994/original.jpg",
        "number": 211,
        "createdAt": "2014-11-18T14:27:56Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-211"
    },
    {
        "id": "naruto-episode-212",
        "title": "To Each His Own Path",
        "image": "https://media.kitsu.io/episodes/thumbnails/167995/original.jpg",
        "number": 212,
        "createdAt": "2014-11-18T14:27:58Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-212"
    },
    {
        "id": "naruto-episode-213",
        "title": "Vanished Memories",
        "image": "https://media.kitsu.io/episodes/thumbnails/167996/original.jpg",
        "number": 213,
        "createdAt": "2014-11-18T14:28:02Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-213"
    },
    {
        "id": "naruto-episode-214",
        "title": "Bringing Back Reality",
        "image": "https://media.kitsu.io/episodes/thumbnails/167997/original.jpg",
        "number": 214,
        "createdAt": "2014-11-18T14:28:03Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-214"
    },
    {
        "id": "naruto-episode-215",
        "title": "A Past to Be Erased",
        "image": "https://media.kitsu.io/episodes/thumbnails/167998/original.jpg",
        "number": 215,
        "createdAt": "2014-11-18T14:28:06Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-215"
    },
    {
        "id": "naruto-episode-216",
        "title": "The Targeted Shukaku",
        "image": "https://media.kitsu.io/episodes/thumbnails/167999/original.jpg",
        "number": 216,
        "createdAt": "2014-11-18T14:28:09Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-216"
    },
    {
        "id": "naruto-episode-217",
        "title": "Sand Alliance With The Leaf Shinobi",
        "image": "https://media.kitsu.io/episodes/thumbnails/168000/original.jpg",
        "number": 217,
        "createdAt": "2014-11-18T14:28:12Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-217"
    },
    {
        "id": "naruto-episode-218",
        "title": "The Counterattack!",
        "image": "https://media.kitsu.io/episodes/thumbnails/168001/original.jpg",
        "number": 218,
        "createdAt": "2014-11-18T14:28:14Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-218"
    },
    {
        "id": "naruto-episode-219",
        "title": "The Ultimate Weapon Reborn",
        "image": "https://media.kitsu.io/episodes/thumbnails/168002/original.jpg",
        "number": 219,
        "createdAt": "2014-11-18T14:28:14Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-219"
    },
    {
        "id": "naruto-episode-220",
        "title": "Departure",
        "image": "https://media.kitsu.io/episodes/thumbnails/168003/original.jpg",
        "number": 220,
        "createdAt": "2014-11-18T14:28:17Z",
        "description": null,
        "url": "https://www9.gogoanimes.fi///naruto-episode-220"
    }
];

const Sidebar = ({data ,episode,setEpisode}) => {
    const [episodeData,setepisodeData]=useState([]);
    const { user, dispatch } = useContext(AuthContext);
    console.log(user,'user')
    useEffect(()=>{
        const setWatchlist = async ()=>{
            if(user &&  data){
                try{
                    await addOrUpdateHistory(user.user._id,data.anime.id,episode.number,"ok")
                    // alert("hi")
                    }
                    catch(err){
                        console.log(err);
                    }
                }
        }
        setWatchlist();
    },[episode])

    useEffect(()=>{
        const mergeEpisodeData = (episodesAnilist, episodes) => {
    const episodesMap = {};

    episodes.forEach(episode => {
        if (!episodesMap[episode.number]) {
            episodesMap[episode.number] = {
                ...episode,
                idSecond: [],
                hasDub: false,
                hasFiller: false
            };
        }
    });

    episodesAnilist.forEach(anilistItem => {
        const { data } = anilistItem;
        data.forEach(providerItem => {
            const { episodes: providerEpisodes, providerId } = providerItem;

            providerEpisodes.forEach(providerEpisode => {
                const correspondingEpisode = episodesMap[providerEpisode.number];

                if (correspondingEpisode) {
                    correspondingEpisode.idSecond.push({
                        id: providerEpisode.id,
                        providerId,
                        hasDub: providerEpisode.hasDub
                    });

                    if (providerEpisode.hasDub) {
                        correspondingEpisode.hasDub = true;
                    }

                    if (providerEpisode.isFiller) {
                        correspondingEpisode.hasFiller = true;
                    }
                }
            });
        });
    });

    const result = Object.values(episodesMap);

    // Iterate over the array to update idDub based on hasDub
    result.forEach(episode => {
        if (episode.hasDub) {
            const splitId = episode.id.split('-episode');
            episode.idDub = `${splitId[0]}-dub-episode${splitId[1]}`;
        } else {
            episode.idDub = null;
        }
    });

    return result;
};

        console.log(mergeEpisodeData(data.animeData.episodesAnilist,data.animeData.episodes),"filter")
        setepisodeData(mergeEpisodeData(data.animeData.episodesAnilist,data.animeData.episodes))
    },[data])
    const [selectedEpisode, setSelectedEpisode] = useState(null);
    const [episodeRanges, setEpisodeRanges] = useState([]);
    const [selectedRange, setSelectedRange] = useState('');

    useEffect(() => {
        const totalEpisodes = episodeData.length>0? episodeData.length:20;
        const ranges = [];
        const rangeStep = 10;

        for (let i = 0; i < totalEpisodes; i += rangeStep) {
            const start = i + 1;
            const end = Math.min(i + rangeStep, totalEpisodes);
            ranges.push(`${start}-${end}`);
        }

        setEpisodeRanges(ranges);
        setSelectedRange(ranges[0]);
    }, [episodeData]);

    const toggleDetails = (episodeId,episode) => {
        setSelectedEpisode(selectedEpisode === episodeId ? null : episodeId);
        setEpisode(episode)
    };

    //   const handleEpisodesPerPageChange = (e) => {
    //     setEpisodesPerPage(parseInt(e.target.value, 10));
    //   };

    const handleRangeChange = (e) => {
        setSelectedRange(e.target.value);
    };

    return (
        <div className="flex flex-col w-[95vw] mx-auto md:w-[300px] bg-[#171717] m-2 mt-[52px] rounded-lg text-white h-fit font-jt">
            <div className="p-4">
                <h1 className="text-lg font-bold mb-2">Episodes</h1>

                <select
                    className="mb-4 p-2 border rounded text-black"
                    onChange={handleRangeChange}
                    value={selectedRange}
                >
                    {episodeRanges.map((range, index) => (
                        <option key={index} value={range} className=''>
                           
                            Episodes {range}
                            
                        </option>
                    ))}
                </select>
                <ul className=' overflow-y-scroll max-h-[80vh]'>
                    {episodeData
                        .filter((episode) => {
                            const [start, end] = selectedRange.split('-').map(Number);
                            return episode.number >= start && episode.number <= end;
                        }).map((episode) => (
                            <li key={episode.id} className={`cursor-pointer ${episode.number%2==0? "bg-[#5a5959]":"bg-[#2c2c2c]"} ${selectedEpisode === episode.id && "bg-[#401a75]"} rounded-md my-1 p-1`}>
                                <div onClick={() => toggleDetails(episode.id,episode)} className='flex items-center w-full'>
                                    {selectedEpisode === episode.id ? (
                                        <div className="flex flex-col  items-center">
                                            <div className='text-lg text-center font-semibold text-gray-300'>{episode.title}</div>
                                            {episode.image?(<img
                                                src={episode.image}
                                                alt={episode.title}
                                                className="w-[90%] mb-4 border-3 border-purple-950 shadow-lg  rounded-3xl"
                                            />):(<></>)}
                                        </div>
                                    ) : (
                                        
                                            episode.title?(<div className='h-11 items-center flex text-base p-2'>{episode.number} {episode.title.slice(0,25)}...</div>):(<div className='h-11 items-center flex text-base p-2'> Episode {episode.number}</div>)
                                        
                                        
                                    )}
                                </div>
                                {selectedEpisode === episode.id && (
                                    <div className="ml-4">
                                        <p>{episode.description?(<>Description: episode.description</>):(<></>)}</p>
                                    </div>
                                )}
                            </li>
                        ))}
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;
