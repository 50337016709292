import React, { useState, useEffect } from 'react';
import "./style.css"
import "./toggle.css"
import CustomCheckBox from './CustomCheckBox';
import { fetchUniqueValues } from '../../utils/Filter Api';
import CustomCheckBoxSort from './CustomCheckBox-Sort';


const initialSort=[
    {"name":"Release date","taken":false,"sortby":true},
    {"name":"Name A-Z","taken":false,"sortby":true},
    {"name":"Average Rating","taken":false,"sortby":true},
    {"name":"Anilist Rating","taken":false,"sortby":true},
    {"name":"Kitsu Rating","taken":false,"sortby":true},
    {"name":"Average Popularity","taken":false,"sortby":true},
    {"name":"Anilist Popularity","taken":false,"sortby":true},
    {"name":"Number of Episodes","taken":false,"sortby":true}
]
const Filter = ({toggleGenreHome,toggleTagHome,toggleTypeHome,toggleSeasonHome,search,query,setQuery,searchFunc,setSearch}) => {


    
    
    const [sortData, setsortData] = useState(initialSort);
    const [Tags, setTags] = useState(null);
    const [TagsIncluded, setTagsIncluded] = useState([]);
    const [TagsnotIncluded, setTagsnotIncluded] = useState([]);
    const [Genres, setGenres] = useState(null);
    const [GenresIncluded, setGenresIncluded] = useState([]);
    const [GenresnotIncluded, setGenresnotIncluded] = useState([]);
    const [Countries, setCountries] = useState(null);
    const [CountriesIncluded, setCountriesIncluded] = useState([]);
    const [CountriesnotIncluded, setCountriesnotIncluded] = useState([]);
    const [Studios, setStudios] = useState(null);
    const [StudiosIncluded, setStudiosIncluded] = useState([]);
    const [StudiosnotIncluded, setStudiosnotIncluded] = useState([]);
    const [Seasons, setSeasons] = useState(null);
    const [SeasonsIncluded, setSeasonsIncluded] = useState([]);
    const [SeasonsnotIncluded, setSeasonsnotIncluded] = useState([]);
    const [ReleaseDates, setReleaseDates] = useState(null);
    const [ReleaseDateIncluded, setReleaseDateIncluded] = useState([]);
    const [ReleaseDatenotIncluded, setReleaseDatenotIncluded] = useState([]);
    const [status, setstatus] = useState(null);
    const [statusIncluded, setstatusIncluded] = useState([]);
    const [statusnotIncluded, setstatusnotIncluded] = useState([]);
    const [types, settypes] = useState(null);
    const [typesIncluded, settypesIncluded] = useState([]);
    const [typesnotIncluded, settypesnotIncluded] = useState([]);

    
    useEffect(() => {
        const getData = async () => {
            const data = await fetchUniqueValues();

            settypes(data.types.filter((elem) => elem != null))
            setTags(data.tags.filter((elem) => elem != null))
            setGenres(data.genres.filter((elem) => elem != null))
            setCountries(data.countryOfOrigin.filter((elem) => elem != null))
            setStudios(data.studios.filter((elem) => elem != null))
            setSeasons(data.seasons.filter((elem) => elem != null))
            setReleaseDates(data.releaseDates.filter((elem) => elem != null))
            setstatus(data.statusAnilist.filter((elem) => elem != null))

        };

        getData();
    }, []);



    const [showTags, setShowTags] = useState(toggleTagHome ? true : false);
    const [showGenres, setShowGenres] = useState(toggleGenreHome ? true : false);
    const [showCountries, setShowCountries] = useState(false);
    const [showStudios, setShowStudios] = useState(false);
    const [showSeasons, setShowSeasons] = useState(toggleSeasonHome ? true : false);
    const [showReleaseDates, setShowReleaseDates] = useState(false);
    const [showstatus, setShowstatus] = useState(false);
    const [showtypes, setShowtypes] = useState(toggleTypeHome ? true : false);


    const toggleTags = () => {
        setShowTags(!showTags);
        setShowGenres(false);
        setShowCountries(false);
        setShowStudios(false);
        setShowSeasons(false);
        setShowReleaseDates(false);
        setShowstatus(false);
        setShowtypes(false)
    };

    const toggleGenres = () => {
        setShowGenres(!showGenres);
        setShowTags(false);
        setShowCountries(false);
        setShowStudios(false);
        setShowSeasons(false);
        setShowReleaseDates(false);
        setShowstatus(false);
        setShowtypes(false)
    };

    const toggleCountries = () => {
        setShowCountries(!showCountries);
        setShowTags(false);
        setShowGenres(false);
        setShowStudios(false);
        setShowSeasons(false);
        setShowReleaseDates(false);
        setShowstatus(false);
        setShowtypes(false)
    };

    const toggleStudios = () => {
        setShowStudios(!showStudios);
        setShowTags(false);
        setShowGenres(false);
        setShowCountries(false);
        setShowSeasons(false);
        setShowReleaseDates(false);
        setShowstatus(false);
        setShowtypes(false)
    };

    const toggleSeasons = () => {
        setShowSeasons(!showSeasons);
        setShowTags(false);
        setShowGenres(false);
        setShowCountries(false);
        setShowStudios(false);
        setShowReleaseDates(false);
        setShowstatus(false);
        setShowtypes(false)
    };

    const toggleReleaseDates = () => {
        setShowReleaseDates(!showReleaseDates);
        setShowTags(false);
        setShowGenres(false);
        setShowCountries(false);
        setShowStudios(false);
        setShowSeasons(false);
        setShowstatus(false);
        setShowtypes(false)
    };

    const toggleStatus = () => {
        setShowstatus(!showstatus);
        setShowTags(false);
        setShowGenres(false);
        setShowCountries(false);
        setShowStudios(false);
        setShowSeasons(false);
        setShowReleaseDates(false);
        setShowtypes(false)
    };

    const toggleTypes = () => {
        setShowtypes(!showtypes)
        setShowTags(false);
        setShowGenres(false);
        setShowCountries(false);
        setShowStudios(false);
        setShowSeasons(false);
        setShowReleaseDates(false);
        setShowstatus(false);
    };

    useEffect(() => {
        
        // console.log(query)
        setQuery({sortData,typesIncluded,typesnotIncluded,TagsIncluded, TagsnotIncluded, GenresIncluded, GenresnotIncluded, CountriesIncluded, CountriesnotIncluded, StudiosIncluded, StudiosnotIncluded, SeasonsIncluded, SeasonsnotIncluded, ReleaseDateIncluded, ReleaseDatenotIncluded, statusIncluded, statusnotIncluded})

    }, [sortData ,typesIncluded,typesnotIncluded, TagsIncluded, TagsnotIncluded, GenresIncluded, GenresnotIncluded, CountriesIncluded, CountriesnotIncluded, StudiosIncluded, StudiosnotIncluded, SeasonsIncluded, SeasonsnotIncluded, ReleaseDateIncluded, ReleaseDatenotIncluded, statusIncluded, statusnotIncluded])


    // function clearStates(){
    //     setsortData(initialSort)
    //     setTagsIncluded([])
    // }

    return (
        <div className='mb-10 md:mb-32  font-jt '>
            <div className='w-[90vw]'>
                <div className='flex flex-row mt-4 justify-between items-center'>
                <h1 className='text-white px-4  ml-4 text-[30px] '>Filter</h1>
                {/* <h1 onClick={clearStates} className='text-white ml-4 text-base p-2 hover:scale-110 transition-all rounded cursor-pointer border-2 '>Clear Selection</h1> */}
                </div>
                <div className="p-4 flex flex-wrap text-white">
                    {/* ...Existing JSX for tag selection */}
                    <div className="my-2 mx-4 relative h-fit">
                        <h2 className="text-lg font-jt hover:bg-[#2E2E2E] text-gray-300 hover:text-white cursor-pointer flex flex-row w-[330px] md:w-[200px] px-4 mb-2 p-2 bg-[#1C1C1C] rounded-md justify-between items-center" onClick={toggleTags}>Tags <img src="dropdown.svg" className='h-6' alt="" /></h2>
                        {showTags && <ul className='flex flex-col  lg:w-[710px] h-[400px] absolute overflow-y-auto bg-[#1C1C1C] z-20 p-4 rounded-md'>
                            <input type="text" placeholder='Search' className=' rounded-md py-1 my-1 outline-none px-2 bg-[#2E2E2E]' />
                            <div className='flex flex-wrap'>
                                {Tags && Tags.map((item, index) => (
                                    <div className='flex flex-row items-center'>
                                        <CustomCheckBox width={48} item={item} index={index} data={Tags} included={TagsIncluded} setIncluded={setTagsIncluded} notIncluded={TagsnotIncluded} setnotIncluded={setTagsnotIncluded} />

                                    </div>
                                ))}
                            </div>

                        </ul>}
                    </div>

                    <div className="my-2 mx-4 relative ">
                        <h2 className="text-lg font-jt hover:bg-[#2E2E2E] text-gray-300 hover:text-white cursor-pointer flex flex-row w-[330px] md:w-[200px] px-4 mb-2 p-2 bg-[#1C1C1C] rounded-md justify-between items-center" onClick={toggleGenres}>Genres <img src="dropdown.svg" className='h-6' alt="" /></h2>
                        {showGenres && <ul className='flex flex-col  w-fit lg:w-[710px] h-[400px] absolute overflow-y-auto bg-[#1C1C1C] z-20 p-4 rounded-md'>
                            <input type="text" placeholder='Search' className=' rounded-md py-1 my-1 outline-none px-2 bg-[#2E2E2E]' />
                            <div className='flex flex-wrap'>
                                {Genres && Genres.map((item, index) => (
                                    <div className='flex flex-row items-center'>
                                        <CustomCheckBox width={48} item={item} index={index} data={Genres} included={GenresIncluded} setIncluded={setGenresIncluded} notIncluded={GenresnotIncluded} setnotIncluded={setGenresnotIncluded} />

                                    </div>
                                ))}
                            </div>

                        </ul>}
                    </div>
                    <div className="my-2 mx-4 relative ">
                        <h2 className="text-lg font-jt hover:bg-[#2E2E2E] text-gray-300 hover:text-white cursor-pointer flex flex-row w-[330px] md:w-[200px] px-4 mb-2 p-2 bg-[#1C1C1C] rounded-md justify-between items-center " onClick={toggleCountries}>Countries <img src="dropdown.svg" className='h-6' alt="" /></h2>
                        {showCountries && <ul className='flex flex-col w-fit lg:w-[200px] h-fit absolute overflow-y-auto bg-[#1C1C1C] z-20 p-2 px-4 rounded-md'>
                            {Countries && Countries.map((item, index) => (
                                <div className='flex flex-row items-center'>
                                    <CustomCheckBox width={24} item={item} index={index} data={Countries} included={CountriesIncluded} setIncluded={setCountriesIncluded} notIncluded={CountriesnotIncluded} setnotIncluded={setCountriesnotIncluded} />

                                </div>
                            ))}
                        </ul>}
                    </div>
                    <div className="my-2 mx-4 relative ">
                        <h2 className="text-lg font-jt hover:bg-[#2E2E2E] text-gray-300 hover:text-white cursor-pointer flex flex-row w-[330px] md:w-[200px] px-4 mb-2 p-2 bg-[#1C1C1C] rounded-md justify-between items-center" onClick={toggleStudios}>Studios <img src="dropdown.svg" className='h-6' alt="" /></h2>
                        {showStudios && <ul className='flex flex-col  w-fit lg:w-[500px] h-[400px] absolute overflow-y-auto bg-[#1C1C1C] z-20 p-4 rounded-md'>
                            <input type="text" placeholder='Search' className=' rounded-md py-1 my-1 outline-none px-2 bg-[#2E2E2E]' />
                            <div className='flex flex-wrap'>
                                {Studios && Studios.map((item, index) => (
                                    <div className='flex flex-row items-center ml-2'>
                                        <CustomCheckBox width={48} item={item} index={index} data={Studios} included={StudiosIncluded} setIncluded={setStudiosIncluded} notIncluded={StudiosnotIncluded} setnotIncluded={setStudiosnotIncluded} />

                                    </div>
                                ))}
                            </div>

                        </ul>}
                    </div>
                    <div className="my-2 mx-4 relative ">
                        <h2 className="text-lg font-jt hover:bg-[#2E2E2E] text-gray-300 hover:text-white cursor-pointer flex flex-row w-[330px] md:w-[200px] px-4 mb-2 p-2 bg-[#1C1C1C] rounded-md justify-between items-center" onClick={toggleSeasons}>Seasons <img src="dropdown.svg" className='h-6' alt="" /></h2>
                        {showSeasons && <ul className='flex flex-col w-fit lg:w-[200px] h-fit absolute overflow-y-auto bg-[#1C1C1C] z-20 p-2 px-4 rounded-md'>
                            {Seasons && Seasons.map((item, index) => (
                                <div className='flex flex-row items-center'>
                                    <CustomCheckBox width={24} item={item} index={index} data={Seasons} included={SeasonsIncluded} setIncluded={setSeasonsIncluded} notIncluded={SeasonsnotIncluded} setnotIncluded={setSeasonsnotIncluded} />

                                </div>
                            ))}
                        </ul>}
                    </div>
                    <div className="my-2 mx-4 relative ">
                        <h2 className="text-lg font-jt hover:bg-[#2E2E2E] text-gray-300 hover:text-white cursor-pointer flex flex-row w-[330px] md:w-[200px] px-4 mb-2 p-2 bg-[#1C1C1C] rounded-md justify-between items-center" onClick={toggleReleaseDates}>Select Year <img src="dropdown.svg" className='h-6' alt="" /></h2>
                        {showReleaseDates && <ul className='flex flex-col  w-fit lg:w-[410px] h-[400px] absolute overflow-y-auto bg-[#1C1C1C] z-20 p-4 rounded-md'>
                            <div className='flex flex-wrap'>
                                {ReleaseDates && ReleaseDates.map((item, index) => (
                                    <div className='flex flex-row items-center'>
                                        <CustomCheckBox width={40} item={item} index={index} data={ReleaseDates} included={ReleaseDateIncluded} setIncluded={setReleaseDateIncluded} notIncluded={ReleaseDatenotIncluded} setnotIncluded={setReleaseDatenotIncluded} />

                                    </div>
                                ))}
                            </div>

                        </ul>}
                    </div>
                    <div className="my-2 mx-4 relative ">
                        <h2 className="text-lg font-jt hover:bg-[#2E2E2E] text-gray-300 hover:text-white cursor-pointer flex flex-row w-[330px] md:w-[200px] px-4 mb-2 p-2 bg-[#1C1C1C] rounded-md justify-between items-center" onClick={toggleStatus}>Status<img src="dropdown.svg" className='h-6' alt="" /></h2>
                        {showstatus && <ul className='flex flex-col w-fit lg:w-[200px] h-fit absolute overflow-y-auto bg-[#1C1C1C] z-20 p-2 px-4 rounded-md'>
                            {status && status.map((item, index) => (
                                <div className='flex flex-row items-center'>
                                    <CustomCheckBox width={24} item={item} index={index} data={status} included={statusIncluded} setIncluded={setstatusIncluded} notIncluded={statusnotIncluded} setnotIncluded={setstatusnotIncluded} />

                                </div>
                            ))}
                        </ul>}
                    </div>
                    <div className="my-2 mx-4 relative ">
                        <h2 className="text-lg font-jt hover:bg-[#2E2E2E] text-gray-300 hover:text-white cursor-pointer flex flex-row w-[330px] md:w-[200px] px-4 mb-2 p-2 bg-[#1C1C1C] rounded-md justify-between items-center" onClick={toggleTypes}>Types <img src="dropdown.svg" className='h-6' alt="" /></h2>
                        {showtypes && <ul className='flex flex-col w-fit lg:w-[200px] h-fit absolute overflow-y-auto bg-[#1C1C1C] z-20 p-2 px-4 rounded-md'>
                            {types && types.map((item, index) => (
                                <div className='flex flex-row items-center'>
                                    <CustomCheckBox width={24} item={item} index={index} data={types} included={typesIncluded} setIncluded={settypesIncluded} notIncluded={typesnotIncluded} setnotIncluded={settypesnotIncluded} />

                                </div>
                            ))}
                        </ul>}
                    </div>
                </div>
            </div>


            <div className="-mt-4 mb-10  flex flex-wrap  items-center relative ">
                <h1 className='text-gray-300 px-4 pt-4 ml-4 text-[30px] '>Sort</h1>
                <ul className='flex flex-wrap h-fit  overflow-y-auto p-2 px-4 rounded-md'>
                    {sortData.map((item,index) => (
                        <CustomCheckBoxSort index={index} sortData={sortData} setSortData={setsortData}/>
                    ))}
                </ul>
            </div>
            <div className='flex flex-row px-4'>
                <div className="flex flex-row ml-4 bg-[#1C1C1C] px-3 py-2 -my-2 items-center rounded-md">
                    <img src="search.png" className="h-6" alt="" />

                    <input
                        onChange={(e)=>setSearch(e.target.value)}
                        type="text"
                        placeholder="Search by Title"
                        className="ml-4 bg-[#1C1C1C] w-[30vw] text-white    focus:outline-none"
                    />
                </div>
                <button onClick={()=>searchFunc()} className='text-white ml-10  bg-purple-700 hover:bg-purple-900 px-2 py-1 -my-2 tracking-wider rounded-md text-2xl flex flex-row items-center'> <img src="filter.svg" className='mr-2 h-6' alt="" />Filter</button>
            </div>

        </div>

    );
};

export default Filter;
