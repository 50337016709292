import { useState, useEffect, useContext } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { fetchAnimeSearch, fetchRandomAnime } from "../../utils/AnimeApi";
import { logOut } from "../../authContext/apiCalls";
import { AuthContext } from "../../authContext/AuthContext";
import LoginPopup from "../Login/Login";
import SignUpPopUp from "../Signup/Signup";
const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const { user, dispatch } = useContext(AuthContext);
  const [Loginpop,SetLoginPop]=useState(false)
  const [Registerpop,SetRegisterpop]=useState(false)
  const navigate= useNavigate();
  // console.log(user,"user")
  const gotoPage=(page)=>{
      navigate(page)
      setUserMenuOpen(false)
  }

  const RandomRedirect= async()=>{
      const id = await fetchRandomAnime();

      navigate(`/watch?anime=${id}`)
  }
  const handleLogout = () => {
    logOut(dispatch);
    setUserMenuOpen(false);

  };
  const toggleMenu = () => {
    setUserMenuOpen(false)
    setMenuOpen(!menuOpen);
  };
  const toggleClose=()=>{
    setUserMenuOpen(false)
    setMenuOpen(false);
  }

  const toggleUserMenu = () => {
    setMenuOpen(false)
    setUserMenuOpen(!userMenuOpen);
  };
  const handleSearchInputChange = (e) => {
    const input = e.target.value;
    setSearchInput(input);

    // If input is cleared, close the search results popup
    if (input === '') {
      setSearchResults([]);
    }
  };
  useEffect(() => {
    // Function to fetch data from the API based on the searchInput
    const fetchAnimeSearchByCharacter = async () => {
      try {
        
          const data = await fetchAnimeSearch(searchInput);
        console.log(data)
        setSearchResults(data); // Update search results with API response
        
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetch function when searchInput changes
    if (searchInput !== '') {
      fetchAnimeSearchByCharacter();
    }
  }, [searchInput]);
  return (
    <>
    {Loginpop &&(<LoginPopup SetLoginPop={SetLoginPop} Loginpop={Loginpop} Registerpop={Registerpop} SetRegisterpop={SetRegisterpop}/>)}
      {Registerpop &&(<SignUpPopUp Registerpop={Registerpop} SetRegisterpop={SetRegisterpop} SetLoginPop={SetLoginPop} Loginpop={Loginpop}/>)}
    
    <nav className="bg-[#171717]  text-white p-4 top-0 z-[10000] w-full   flex justify-between items-center">
      <div className="flex items-center">
        <div className="relative">
          <img
            src="lines.svg "
            className="w-8 mr-4 md:mr-6"
            alt=""
            onClick={toggleMenu}
          />
          {menuOpen && (
            <div className="absolute  mt-2 py-2 w-[250px] md:w-fit bg-[#171717]  -ml-4 md:-ml-2 rounded-md z-[1000]">
              <ul className="">
                <Link to="/home" onClick={toggleClose}><li className="mx-4 my-4 md:text-base text-2xl">Home</li></Link>
                <Link to="/filter?genres=1" onClick={toggleClose}><li className="mx-4 my-4 md:text-base text-2xl">Genres</li></Link>
                <Link to="/filter?tags=1" onClick={toggleClose}><li className="mx-4 my-4 md:text-base text-2xl">Tags</li></Link>
                <Link to="/filter?types=1" onClick={toggleClose}><li className="mx-4 my-4 md:text-base text-2xl">Types</li></Link>
                <Link to="/filter?seasons=1" onClick={toggleClose}><li className="mx-4 my-4 md:text-base text-2xl">Seasons</li></Link>
                <Link to="/filter?advancefiltering=1" onClick={toggleClose}><li className="mx-4 my-4 md:text-base text-2xl">Advance Filtering</li></Link>
                <Link to="/filter?ongoing=Ongoing" onClick={toggleClose}><li className="mx-4 my-4 md:text-base text-2xl">Ongoing</li></Link>
                <Link to="/home" onClick={toggleClose}><li className="mx-4 my-4 md:text-base text-2xl">Request Anime</li></Link>
              </ul>
            </div>
          )}
        </div>

        <Link to="/home">
          <div className="md:block hidden">
            {/* Logo */}
            <img src="logo-main.png" alt="Logo" className="h-8" />
          </div>
        </Link>
        {/* Search bar */}
        <div className="relative w-full md:w-auto">
          <div className="flex flex-row md:ml-4 w-full md:w-auto bg-[#020202] px-3 py-2 -my-2 rounded-full">
            <img src="search.png" className="h-6" alt="" />
            <input
              type="text"
              placeholder="Search..."
              className="ml-4 bg-[#020202] w-[40vw] md:w-[30vw] text-white focus:outline-none"
              value={searchInput}
              onChange={handleSearchInputChange}
            />
          </div>
          {searchResults.length > 0 && (
            <div className="bg-[#171717] text-white mt-2 font-jt absolute top-8 -ml-12 md:ml-10 z-30 rounded-md">
              <div className="min-w-[400px] md:min-w-[450px]">
                {searchResults.map((result, index) => (
                  <Link to={`watch?anime=${result.id}`}>
                  <div
                    key={result.id}
                    className={`flex flex-row p-2 rounded-lg w-full items-center ${index % 2 === 0 ? 'bg-[#424141]' : 'bg-[#171717]'}`}
                  >
                    <img src={result.image} className="h-24 w-16 rounded" alt="ok" />
                    <div className="ml-2">
                      <div className="text-xl">
                        {result.title.english ? result.title.english : result.title.romaji}
                      </div>
                      <div className="flex flex-row items-center">
                        <div className=" text-sm  px-[4px] text-gray-300">{result.statusAnilist}</div> 
                          <div className="flex flex-row items-center"> <img src="rating.svg" className="h-4 ml-1" alt="" /> {result.averageRating}</div>
                         <div className="mx-4 ">{result.type} </div>
                         <div className="">
                         {result.releaseDate}
                         </div>
                      </div>
                    </div>
                  </div>
                  </Link>
                  
                ))}
                <div className="text-right px-2 py-1 cursor-pointer text-lg">View All</div>
              </div>
            </div>
          )}
        </div>

        <div onClick={()=>gotoPage("/home")} className="mx-4 hidden cursor-pointer md:flex items-center flex-row">
          <img src="trrending.svg" className=" h-6 mr-2" alt="" />
          Trending</div>
        <div onClick={()=>gotoPage("/home")} className="mx-4 hidden cursor-pointer md:flex items-center flex-row">
          <img src="popular.svg" className=" h-[22px] mr-2" alt="" />
          Popular</div>
        <div onClick={RandomRedirect} className="mx-4 hidden cursor-pointer md:flex items-center flex-row">
          <img src="random.svg" className=" h-8 mr-1" alt="" />
          Random</div>
        {(user && user.user )&& <div onClick={()=>gotoPage("/watch-list")} className="mx-4 hidden cursor-pointer md:flex items-center flex-row">
          <img src="watchlist.svg" className=" h-6 mb-1 mr-1" alt="" />
          Watch List</div>}
          {(user && user.user )&& <div onClick={()=>gotoPage("/continue-watching")} className="mx-4 hidden cursor-pointer md:flex items-center flex-row">
          <img src="continue-watching.svg" className=" h-5 mb-1 mr-1" alt="" />
          Watching History</div>}
      </div>
      {user? (
        <div className="flex items-center">
        <div className="ml-4 relative">
          <button
            onClick={toggleUserMenu}
            className="focus:outline-none flex items-center"
          >
            <img
              src="user.jpg"
              alt="User"
              className="h-10 -my-2 w-10 object-cover rounded-full"
            />
          </button>
          {userMenuOpen && (
            <div className="absolute right-0 -mr-4 md:-mr-0 text-2xl md:text-base mt-4 py-2 w-fit bg-[#171717]  rounded-md shadow-xl z-[1000]">
              <ul className="text-white w-[200px]">
                <li  onClick={()=>gotoPage("/profile")} className="m-4 flex flex-row items-center cursor-pointer"> <img src="profile.svg" className="h-4 mr-2 " alt="" />Profile</li>
                <li  onClick={()=>gotoPage("/continue-watching")} className="m-4 flex flex-row items-center cursor-pointer"> <img src="continue-watching.svg" className="h-4 mr-2 " alt="" />Continue Watching</li>
                <li  onClick={()=>gotoPage("/watch-list")} className="m-4 flex flex-row items-center cursor-pointer"> <img src="watchlist.svg" className="h-5 mr-2" alt="" />Watch List</li>
                {user.user.accessLevel==0 && <>
                  <li  onClick={()=>gotoPage("/user-list")} className="m-4 flex flex-row items-center cursor-pointer"> <img src="user.svg" className="h-4 mr-2 " alt="" />User List</li>
                <li  onClick={()=>gotoPage("/anime-updates")} className="m-4 flex flex-row items-center cursor-pointer"> <img src="popular.svg" className="h-4 mr-2 " alt="" />Anime Updates</li>
                <li  onClick={()=>gotoPage("/comment-flag")} className="m-4 flex flex-row items-center cursor-pointer"> <img src="comment.svg" className="h-4 mr-2 " alt="" />Comment Flag</li>
                </>}
                <li onClick={handleLogout} className="m-4 flex flex-row items-center cursor-pointer"> <img src="signout.svg" className="h-4 mr-2 " alt="" />Logout</li>
              </ul>
            </div>
          )}
        </div>
      </div>
      ):(
        <div className="flex items-center">
          <button onClick={()=>{SetRegisterpop(!Registerpop)}} className="hover:scale-110 transition-all font-jt hidden md:flex flex-row justify-center items-center   md:ml-3  text-purple-900 border-purple-900 border-2 w-[130px] h-[40px] rounded-md shadow-md">
          <img src="register.svg" className="h-6 mr-4" alt="" />
            Register
          </button>
          
          <button onClick={()=>{SetLoginPop(!Loginpop)}} className="hover:scale-110 transition-all bg-purple-900 flex flex-row justify-center items-center font-jt  md:ml-3 text-white  w-[100px] h-[40px] rounded-md  shadow-md">
          <img src="login.svg" className="h-6 mr-4" alt="" />
            Sign in
          </button>
        </div>
      )}

    </nav>
    </>
  );
};

export default Navbar;
