import React, { useState, useEffect } from "react";
import { fetchAnimeSearchByCharacter } from "../../../utils/AnimeApi";

const Modal = ({ isOpen, onClose,imageurlset }) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (searchValue.trim() !== "") {
      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await fetchAnimeSearchByCharacter(searchValue);
          setSearchResults(response);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          throw new Error('Error fetching anime search by character');
        }
      };
      fetchData();
    } else {
      setSearchResults([]);
    }
  }, [searchValue, fetchAnimeSearchByCharacter]);

  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-[#1C1C1C]  w-[400px] h-[600px] p-8 rounded-lg">
        <div className="flex justify-end">
          <button onClick={onClose}>
            <img src="close.svg" className="h-10" alt="" />
          </button>
        </div>
        <div className="mt-4 h-fit">
          <input
            type="text"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search character..."
            className="border text-black border-gray-300 p-2 rounded-md w-full mb-4"
          />
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div className="text-white h-[420px] overflow-y-scroll">
              {searchResults.map((result) => (
                <div onClick={()=>imageurlset(result.image)} key={result.characterName} className="flex cursor-pointer items-center space-x-4 mb-2">
                  <img src={result.image} alt={result.characterName} className=" h-28" />
                  <div>
                    <p>{result.characterName}</p>
                    <ul>
                      {result.animeNames.slice(0,1).map((anime, index) => (
                        <li key={index}>{anime.romaji || anime.english || anime.native}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
