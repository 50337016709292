import axios from "axios";
import { loginFailure, loginStart, loginSuccess,logout } from "./AuthActions";

export const login = async (user, dispatch) => {
  dispatch(loginStart());
  try {
    const res = await axios.post(`https://api.anime4us.me/api/user/login`, user);
    // //console.log(res.data)
    dispatch(loginSuccess(res.data));
  } catch (err) {
    // alert("Wrong Login Credentials")
    dispatch(loginFailure());
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    }
    throw err;
  }
};

export const logOut = async (dispatch) => {
    dispatch(logout());
}