import React from 'react';
import { Link } from 'react-router-dom';

const CardImage = ({deleteEntry,history, cardData }) => {



  return (
    <div className=" w-[178px] relative rounded-t-xl rounded-b bg-[#272729]">

<div
          className='absolute cursor-default h-[250px] w-[178px] '
          onMouseEnter={(e) => { e.currentTarget.querySelector('.hidden').style.display = 'block'; }}
          onMouseLeave={(e) => { e.currentTarget.querySelector('.hidden').style.display = 'none'; }}
        >
          <div className="hidden bg-opacity-60 bg-black h-[250px] w-[178px]" style={{ display: 'none' }}>
              <div className='mx-auto flex  justify-center my-auto h-[250px] items-center'>
                <button onClick={()=>deleteEntry(history.animeId)} className=' p-1 bg-purple-900  text-gray-300 cursor-pointer rounded'>Remove From List</button>
              </div>
          </div>
        </div>
      <Link to={`/watch?anime=${cardData.id}`}>
        

        <img
          src={cardData.image}
          alt={cardData.title.english}
          className="w-full h-[250px]  p-[1px]  object-cover"
        />
        <div className='text-white flex flex-row  justify-between items-center' >
          <div className='flex flex-row items-center m-1'>
            <p className=' border-white rounded-md mr-2 px-1 text-sm  font-jt tracking-widest text-white '><span className=' bg-pink-800 px-2 rounded-md'>{history.episodeNumber}/{cardData.totalEpisodes}</span> Watched</p>
          </div>

          {/* <p className='justify-end text-sm  text-gray-300 mr-2 tracking-widest'> {cardData.type}</p> */}
        </div>
      </Link>
    </div>
  );
};

export default CardImage;
