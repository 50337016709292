import React, { useState, useEffect } from 'react';
import { getUsers, updateUser, deleteUser } from '../../../utils/AdminApi';
import { BeatLoader } from 'react-spinners';

const UserList = () => {
  const [users, setUsers] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [loading,setLoading]=useState(false);
  const [accesslevel,Setaccesslevel]=useState(-2);
  const Level = ["User", "Admin", "Anime Moderator", "Comment Moderator"]
  
  
  
  const handleCheckboxChange = (value) => {
    if(accesslevel==value) Setaccesslevel(-2);
    else Setaccesslevel(value)
  };



  useEffect(() => {
    loadUsers();
  }, [page, limit,accesslevel]);



  const nextPage = () => {
    setPage(page + 1);
  };

  // Function to handle previous page
  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  const loadUsers = async () => {
    try {
      const data = await getUsers(page, limit,accesslevel);
      setUsers(data);
    } catch (error) {
      console.error('Error loading users:', error);
    }
  };

  const toggleUserBlocked = async (user) => {
    setLoading(true);
    try {
      setSelectedUser(user)
      const updatedUser = { ...user, userBlocked: !user.userBlocked };
      await updateUser(updatedUser._id, updatedUser);
      loadUsers();
      setTimeout(()=>{

        setLoading(false);
      },1000)
    } catch (error) {
      setLoading(false);
      console.error('Error toggling user status:', error);
    }
  };
  

  const handleEdit = async () => {
    if (!selectedUser) return;
    try {
      
      await updateUser(selectedUser._id, selectedUser);
      loadUsers();
      setShowEditModal(false);
    } catch (error) {
      console.error('Error editing user:', error);
    }
  };

  const handleDelete = async (userId) => {
    if (!userId) return;
    try {
      await deleteUser(userId);
      loadUsers();
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  return (
    <div className="container p-10 max-w-[90vw] mt-5 font-jt mx-auto rounded-lg bg-[#2b2b2b] text-white">
      {/* Pagination controls */}
      {/* User list */}
      <div className="flex flex-row bg-[#191919] text-2xl rounded-md items-center  py-4">
        {/* Display user details */}
        <div className="w-10 mr-10 h-10 rounded-full" />
        <p className='w-[5%]'>Role</p>
        <p className='w-[20%]'>Display Name</p>
        <p className='w-[20%]'>User Name</p>
        <p className='w-[20%]'>Email Address</p>
        <p className='w-[8%]'>Warning Counter</p>
        <div className='w-[27%] text-center'>
          Actions
        </div>
      </div>
      <div className='flex flex-row justify-end'>
        <div className='flex flex-row items-center mr-10'>

        <div  className='cursor-pointer flex flex-row border-2 p-2 rounded-md mx-4 space-x-2 items-center' onClick={() => handleCheckboxChange(0)}>
        <input
          type="checkbox"
          className="h-4 w-4"
          checked={accesslevel === 0}
          onChange={() => handleCheckboxChange(0)}
        />
        <div htmlFor="accessLevel0">Show Admins only</div>
        </div>
        <br />

        <div  className='cursor-pointer flex flex-row border-2 p-2 rounded-md mx-4 space-x-2 items-center' onClick={() => handleCheckboxChange(1)}>
        <input
          type="checkbox"
          className="h-4 w-4"
          checked={accesslevel === 1}
          onChange={() => handleCheckboxChange(1)}
        />
        <div htmlFor="accessLevel1">Show Anime Moderators only</div>
        </div>
        <br />

        <div  className='cursor-pointer flex flex-row border-2 p-2 rounded-md mx-4 space-x-2 items-center' onClick={() => handleCheckboxChange(2)}>
        <input
          type="checkbox"
          className="h-4 w-4"
          checked={accesslevel === 2}
          onChange={() => handleCheckboxChange(2)}
        />
        <div htmlFor="accessLevel2">Show Comment Moderators only</div>
        </div>
        
        <br />
        
        </div>
      <div className="flex justify-end my-4">
        <button
          className="px-4 py-2 bg-[#956262] text-white rounded-md mr-2"
          onClick={prevPage}
        >
          Previous
        </button>
        <button
          className="px-4 py-2 bg-[#956262] text-white rounded-md"
          onClick={nextPage}
        >
          Next
        </button>
      </div>
      </div>
      
      {users != null && users.map(user => (
        <div>
          <div key={user._id} className="flex flex-row  items-center border-b py-4">
            {/* Display user details */}
            <img src={"https://s4.anilist.co/file/anilistcdn/character/large/b127691-6m4hNwL2vLML.png"} alt="Avatar" className="w-10 mr-10 h-10 rounded-full" />
            <p className='w-[10%]'>{Level[user.accessLevel + 1]}</p>
            <p className='w-[20%]'>{user.displayName}</p>
            <p className='w-[20%]'>{user.username}</p>
            <p className='w-[20%]'>{user.email}</p>
            <p className='w-[8%] items-center text-center'>{user.warningCounter}</p>
            <div className='w-[27%] items-center text-center'>
              <button className=' w-[50px] rounded-md mx-2 p-2 bg-[#61A3BA]' onClick={() => { setSelectedUser(user); setShowEditModal(true) }}>Edit</button>
              <button className={` w-[120px] ${user.userBlocked ?"bg-[#C7DCA7]":"bg-[#748E63]"} text-black rounded-md mx-2 p-2`} onClick={ ()  => toggleUserBlocked(user)}>{loading && selectedUser && selectedUser._id == user._id ?(<BeatLoader color="#36d7b7" />):(user.userBlocked ? 'Unblock User' : 'Block User')} 
              </button>
              <button className=' w-[80px] bg-[#DF2E38] rounded-md mx-2 p-2' onClick={() => handleDelete(user._id)}>Delete</button>
            </div>
          </div>
          {selectedUser && selectedUser._id == user._id && showEditModal && (
            <div >
              <div className="modal-content">
                <span className="close justify-end flex " ><img src="close.svg" onClick={() => setShowEditModal(false)} className='h-10 cursor-pointer hover:scale-110 transition-all' alt="" /></span>
                <form className='flex flex-row  items-center border-b py-4'>
                  <select
                    className='ml-14 mx-2 text-black rounded-md p-2 w-[10%]'
                    value={selectedUser.accessLevel}
                    onChange={(e) => setSelectedUser({ ...selectedUser, accessLevel: e.target.value })}
                  >
                    <option value="-1">User</option>
                    <option value="0">Admin</option>
                    <option value="1">Anime Moderator</option>
                    <option value="2">Comment Moderator</option>
                    {/* Add more options as needed */}
                  </select>
                  <input
                    type="text"
                    className='mx-3 text-black rounded-md p-2 w-[14%]'
                    value={selectedUser.displayName}
                    onChange={(e) => setSelectedUser({ ...selectedUser, displayName: e.target.value })}
                  />
                  <input
                    type="text"
                    className='mx-3 text-black rounded-md p-2 w-[20%]'
                    value={selectedUser.username}
                    onChange={(e) => setSelectedUser({ ...selectedUser, username: e.target.value })}
                  />
                  <input
                    type="text"
                    className='mx-3 text-black rounded-md p-2 w-[20%]'
                    value={selectedUser.email}
                    onChange={(e) => setSelectedUser({ ...selectedUser, email: e.target.value })}
                  />
                  <input
                    type="number"
                    className='mx-3 text-black rounded-md p-2 w-[8%]'
                    value={selectedUser.warningCounter}
                    onChange={(e) => setSelectedUser({ ...selectedUser, warningCounter: e.target.value })}
                  />
                  {/* Other input fields for username, email, warning counter, etc. */}
                  <button onClick={handleEdit} className='bg-white text-black p-2 rounded-md'>Save Changes</button>
                </form>
              </div>
            </div>
          )}
        </div>
      ))}

      {/* Edit User Modal */}

    </div>
  );
};

export default UserList;
