import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "black" }}
        onClick={onClick}
      />
    );
  };
  
  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "black" }}
        onClick={onClick}
      />
    );
  };
const CharacterCarousel = ({ characters }) => {
    
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <>
    <div className='text-white text-center bg-[#171717] text-[30px] font-jt'>Characters</div>
    <Slider {...settings} className=' rounded-t-none rounded-md mb-52 bg-[#171717]'>
      {characters.map((character) => (
        <div key={character.id} className="mx-5 ">
          <a href={`https://anilist.co/character/${character.id}`} target="_blank" rel="noopener noreferrer">
            <div className=" p-4 rounded-lg h-[200px] w-fit">
              <img src={character.image} alt={character.name.full} className="w-full h-full rounded-lg mb-4 object-contain" />
              <h3 className="text-white text-lg font-semibold mb-2 text-center">{character.name.full}</h3>
              <p className="text-gray-400 text-sm mb-1 text-center ">{character.role}</p>
            </div>
          </a>
        </div>
      ))}
    </Slider>
    </>
    
  );
};

export default CharacterCarousel;
