import React from 'react';
import Popup from 'reactjs-popup';
import DetailsBox from './CardDetails';
import CardImage from './CardImage';

const Card = ({data,kitsu}) => {

  return (
    <>
      <div className=' md:hidden cursor-pointer'>
            <CardImage kitsu={kitsu} cardData={data}/>
            <div className='text-white font-jt text-center mt-1 text-base'>
            {data?.title.english? data?.title.english.slice(0,14)+"...":data?.title.romaji.slice(0,14)+"..."}
              </div>
        </div>
    <div className="hidden md:flex items-center h-fit justify-center m-2 ">
      <Popup
        trigger={<div className=' cursor-pointer'>
            <CardImage kitsu={kitsu} cardData={data}/>
            <div className='text-white font-jt text-center mt-1 text-xl'>
            {data?.title.english? data?.title.english.slice(0,10):data?.title.romaji.slice(0,10)}
              </div>
        </div>
        }
        position="right center"
        
        contentStyle={{
          padding: '2px',
          borderRadius: '6px',
          backgroundColor: '#272729',
          color: 'white',
          width:'300px',
          
        }}
        arrow={false}
        on="hover"
        mouseEnterDelay={800}
        mouseLeaveDelay={0}
        >
       <DetailsBox cardData={data}/>
      </Popup>
    </div>
        </>
  );
};

export default Card;
