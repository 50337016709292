import axios from 'axios';

// Assuming your server is running at http://localhost:3000

const baseURL = 'https://api.anime4us.me/api/comment'; // Replace with your server's base URL

// Function to add a comment
async function addComment(commentData) {
  try {
    const response = await axios.post(`${baseURL}/add`, commentData);
    return response.data;
  } catch (error) {
    console.error('Error adding comment:', error.response.data);
    return null;
  }
}

// Function to get single-level comments
async function getSingleLevelComments(animeId, episodeNumber, parentId) {
  // const queryParams = { animeId, episodeNumber, parentId };
  try {
    if(parentId==null){
      const response = await axios.get(`${baseURL}/comments/single-level?animeId=${animeId}&episodeNumber=${episodeNumber}`);
      return response.data;
    }
    else{
      const response = await axios.get(`${baseURL}/comments/single-level?animeId=${animeId}&episodeNumber=${episodeNumber}&parentId=${parentId}`);
      return response.data;
    }
  } catch (error) {
    console.error('Error getting single-level comments:', error.response.data);
    return null;
  }
}

// Function to update a comment
async function updateComment(commentId, updatedText) {
  try {
    const response = await axios.put(`${baseURL}/comments/${commentId}`, { text: updatedText });
    return response.data;
  } catch (error) {
    console.error('Error updating comment:', error.response.data);
    return null;
  }
}

// Function to get all comments
async function getAllComments(animeId, episodeNumber) {
  // const queryParams = { animeId, episodeNumber };
  try {
    const response = await axios.get(`https://api.anime4us.me/api/comment/comments/all?animeId=1&episodeNumber=5`);
    // console.log(response,"data1234")
    return response.data;
  } catch (error) {
    console.error('Error getting all comments:', error.response.data);
    return null;
  }
}

// Function to delete a comment
async function deleteComment(commentId) {
  try {
    const response = await axios.delete(`${baseURL}/comments/${commentId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting comment:', error.response.data);
    return null;
  }
}

// Function to add a reaction to a comment
async function addReactionToComment(commentId, reactionData) {
  try {
    const response = await axios.post(`${baseURL}/reaction/${commentId}/reactions`, reactionData);
    return response.data;
  } catch (error) {
    console.error('Error adding reaction to comment:', error.response.data);
    return null;
  }
}

// Function to delete a reaction from a comment
async function deleteReactionFromComment(commentId, reactionId) {
  try {
    const response = await axios.delete(`${baseURL}/reaction/${commentId}/reactions/${reactionId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting reaction from comment:', error.response.data);
    return null;
  }
}

export{
  addComment,
  getAllComments,
  getSingleLevelComments,
  updateComment,
  deleteComment,
  addReactionToComment,
  deleteReactionFromComment,
};
