import { useEffect, useState } from "react";
import NewReleaseCard from "../../component/Home/New Release added completed/NewReleaseCard";
import RectangleCard from "../../component/Home/Top anime/Top Anime Card";
import AnimeCard from "../../component/Main Page/Detail Card/Detail Card";
import Sidebar from "../../component/Main Page/Episode Selector/EpisodeSelector";
import Recommendations from "../../component/Main Page/Recommendations/Recommendations";
import Related from "../../component/Main Page/Related/Related";
import VideoPlayer from "../../component/Main Page/Video Player/Player";
import { fetchAnimeById } from "../../utils/AnimeApi";
import { useLocation } from 'react-router-dom';
import { Skeleton } from "@mui/material";
import AnimeDetailsSkeleton from "../../component/Main Page/Detail Card/Skeleton";
import { animateScroll } from 'react-scroll';
import CommentApp from "../../component/Main Page/Comments/CommentMain";


const Watch = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryValue = searchParams.get('anime')
  console.log(queryValue,"queryValue")
  const [data, setData] = useState(null);
  const [episode,setEpisode]=useState(null);
  const [commentEpisode,setcommentEpisode]=useState(-1);
  const dummyART = new Array(7).fill(0)
  const [mobileART,setmobileArt]=useState(0)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const trending = await fetchAnimeById(queryValue!==null?queryValue:1);
        console.log(trending,"animedata")
        setData(trending);
      } catch (error) {
        // Handle errors, e.g., set an error state or log the error
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Call the async function immediately inside useEffect
  }, []);

  useEffect(() => {
    animateScroll.scrollToTop({
      duration: 0
    });
    
}, [location.pathname]);

// useEffect(()=>{
//   console.log(episode,"episode1231")
// },[episode])

const handleCommentepisodeNumber =(episode)=>{
  setcommentEpisode(episode)
}

  return (
    <div className="bg-[#020202] h-full min-h-[2000px]">
      <div className="flex  flex-col md:flex-row w-full">
        <div className="hidden md:block w-fit">
         {data? (<Sidebar data={data} episode={episode} setEpisode={setEpisode} />):(
            <div className="bg-[#171717] h-[650px] rounded-md  mt-12 w-[350px] p-3 mx-2">
              <Skeleton variant="rectangular" className="  rounded-md"  sx={{ bgcolor: 'grey.900' }} width={200} height={50}/>
              <Skeleton variant="rectangular" className=" mt-6 rounded-md"  sx={{ bgcolor: 'grey.900' }} width={300} height={50}/>
              <Skeleton variant="rectangular" className=" mt-2 rounded-md"  sx={{ bgcolor: 'grey.900' }} width={300} height={50}/>
              <Skeleton variant="rectangular" className=" mt-2 rounded-md"  sx={{ bgcolor: 'grey.900' }} width={300} height={50}/>
              <Skeleton variant="rectangular" className=" mt-2 rounded-md"  sx={{ bgcolor: 'grey.900' }} width={300} height={50}/>
              <Skeleton variant="rectangular" className=" mt-2 rounded-md"  sx={{ bgcolor: 'grey.900' }} width={300} height={50}/>
              <Skeleton variant="rectangular" className=" mt-2 rounded-md"  sx={{ bgcolor: 'grey.900' }} width={300} height={50}/>
              <Skeleton variant="rectangular" className=" mt-2 rounded-md"  sx={{ bgcolor: 'grey.900' }} width={300} height={50}/>
              <Skeleton variant="rectangular" className=" mt-2 rounded-md"  sx={{ bgcolor: 'grey.900' }} width={300} height={50}/>
              <Skeleton variant="rectangular" className=" mt-2 rounded-md"  sx={{ bgcolor: 'grey.900' }} width={300} height={50}/>
              
            </div>
         )}
        </div>

        {data ? (<VideoPlayer data={data} episode={episode} setEpisode={setEpisode}/>):(<div className="flex w-full flex-col">
          <Skeleton variant="rectangular" className="w-full  mt-12 rounded-md"  sx={{ bgcolor: 'grey.900' }}  height={450}/>
          <Skeleton variant="rectangular" className="w-full  mt-4 rounded-md"  sx={{ bgcolor: 'grey.900' }}  height={130}/>
          <Skeleton variant="rectangular" className="w-full  mt-4 rounded-md"  sx={{ bgcolor: 'grey.900' }}  height={50}/>
        </div>)}

        <div className="block md:hidden  w-full md:w-fit">
         {data? (<Sidebar data={data} episode={episode} setEpisode={setEpisode} />):(
            <div className="bg-[#171717] h-[650px] rounded-md  mt-12 w-[95vw] p-3 mx-2">
              <Skeleton variant="rectangular" className="  rounded-md"  sx={{ bgcolor: 'grey.900' }} width={200} height={50}/>
              <Skeleton variant="rectangular" className=" mt-6 rounded-md"  sx={{ bgcolor: 'grey.900' }} width={350} height={50}/>
              <Skeleton variant="rectangular" className=" mt-2 rounded-md"  sx={{ bgcolor: 'grey.900' }} width={350} height={50}/>
              <Skeleton variant="rectangular" className=" mt-2 rounded-md"  sx={{ bgcolor: 'grey.900' }} width={350} height={50}/>
              <Skeleton variant="rectangular" className=" mt-2 rounded-md"  sx={{ bgcolor: 'grey.900' }} width={350} height={50}/>
              <Skeleton variant="rectangular" className=" mt-2 rounded-md"  sx={{ bgcolor: 'grey.900' }} width={350} height={50}/>
              <Skeleton variant="rectangular" className=" mt-2 rounded-md"  sx={{ bgcolor: 'grey.900' }} width={350} height={50}/>
              <Skeleton variant="rectangular" className=" mt-2 rounded-md"  sx={{ bgcolor: 'grey.900' }} width={350} height={50}/>
              <Skeleton variant="rectangular" className=" mt-2 rounded-md"  sx={{ bgcolor: 'grey.900' }} width={350} height={50}/>
              <Skeleton variant="rectangular" className=" mt-2 rounded-md"  sx={{ bgcolor: 'grey.900' }} width={350} height={50}/>
              
            </div>
         )}
        </div>

        <div className="hidden md:block w-fit">
          <div className="text-center text-3xl mt-2 font-jt text-white">
            Related
          </div>
          <div className=" overflow-y-auto overflow-x-hidden h-[90vh]">
            {data ? data.animeData?.relationsAnilist?.map(elem=>(
            <Related data={elem}/>
            )):(
              <div className="flex flex-col justify-end items-end ">
              {dummyART.map(elem=>(<div className="mt-4 mx-4">
              <Skeleton variant="rectangular" className=" rounded-xl"  sx={{ bgcolor: 'grey.900' }} width={350} height={100}/>
              
              </div>))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between ">
        <div className="mx-auto md:mx-0 w-[95%] md:w-[75%] ">
        <div className="mt-8">
           {data ?  (<AnimeCard data={data}/>):(
            <AnimeDetailsSkeleton/>
           )}
        </div>
        <div className="md:hidden flex flex-row mx-auto mt-4 w-[98%] justify-center text-white items-center">
              <button onClick={()=> setmobileArt(0)} className={`px-3 ${mobileART==0 ? "bg-purple-800":"bg-[#1c1c1c]"} w-full text-xl rounded-l-xl py-2`}>Related</button>
        
              <button onClick={()=> setmobileArt(1)} className={`px-3 ${mobileART==1 ? "bg-purple-800":"bg-[#1c1c1c]"} w-full text-xl rounded-r-xl py-2`}>Recommended</button>
        </div>
        {mobileART==0 && <div className="w-full md:hidden flex justify-center flex-col mx-auto mt-6">
          
          
          <div className=" w-full">
            {data ? data.animeData?.relationsAnilist?.map(elem=>(
            <Related data={elem}/>
            )):(
              <div className="flex flex-col justify-end items-end ">
              {dummyART.map(elem=>(<div className="mt-4 mx-4">
              <Skeleton variant="rectangular" className=" rounded-xl"  sx={{ bgcolor: 'grey.900' }} width={350} height={100}/>
              
              </div>))}
              </div>
            )}
          </div>
        </div>}
       {mobileART==1 &&  <div className="w-full md:hidden flex justify-center flex-col mx-auto mt-6">
          
          <div className="w-full">
          {data ? data.animeData?.recommendations?.map(elem=>(
            <Recommendations data={elem}/>
            )):(
              
              <div className="flex flex-col justify-end items-end ">
              {dummyART.map(elem=>(<div className="mt-4 md:mr-4">
              <Skeleton variant="rectangular" className=" rounded-xl"  sx={{ bgcolor: 'grey.900' }} width={350} height={100}/>
              
              </div>))}
              </div>
              
            )}
            
          </div>
        </div>}
        {data ? ( 
          <div className="rounded-2xl md:ml-4 mt-10 bg-[#171717] text-white">
            <div className="flex flex-row w-full justify-between px-4">
            <h1 className="text-2xl mt-4 text-white font-bold">Comments</h1>
            <div className="mt-4">
              <button onClick={()=>handleCommentepisodeNumber(-1)}  className={`${commentEpisode==-1?"bg-purple-800":"bg-[#4d4c4c]"} p-1 px-2 rounded-l`}>Anime Comments</button>
              {episode && <button onClick={()=>handleCommentepisodeNumber(episode.number)} className={`${commentEpisode!==-1?"bg-purple-800":"bg-[#4d4c4c]"} p-1 px-2 rounded-r`}>Episode {episode.number} Comments</button> }
            </div>
           
            </div>
            <CommentApp animeId={data.anime.id} episodeNumber={commentEpisode}/>
          </div>
        ):(
          <div className="bg-[#010101]">
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
        </div>
        )}
        </div>
       
        <div className="w-fit hidden md:block mt-6">
          <div className="text-center text-3xl my-2 font-jt text-white">
            Recommended
          </div>
          <div className="">
          {data ? data.animeData?.recommendations?.map(elem=>(
            <Recommendations data={elem}/>
            )):(
              
              <div className="flex flex-col justify-end items-end ">
              {dummyART.map(elem=>(<div className="mt-4 mr-4">
              <Skeleton variant="rectangular" className=" rounded-xl"  sx={{ bgcolor: 'grey.900' }} width={350} height={100}/>
              
              </div>))}
              </div>
              
            )}
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Watch;
