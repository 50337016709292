import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const AnimeDetailsSkeleton = () => {
  return (
    <div className=' min-h-[2000px] ml-6'>
      <div className="flex   mt-4  h-fit bg-[#171717] font-jt text-white shadow-lg rounded-lg rounded-b-none p-4">
        <Skeleton height={400} width={250} variant="rectangular" className=" object-cover rounded-md" />

        <div className="ml-4 flex-1">
          <Skeleton variant="text" width={400} height={80} className="" />
          
          <div className=''>
            <Skeleton variant="rectunglar"  height={300} className="text-gray-300" />
          </div>

          <div className="flex flex-wrap my-4 ">
          
            <Skeleton variant="rectangular" width={80} height={30} className="bg-[#353535] rounded-full py-1 px-3 text-base mr-2 mb-2" />
            <Skeleton variant="rectangular" width={80} height={30} className="bg-[#353535] rounded-full py-1 px-3 text-base mr-2 mb-2" />
            <Skeleton variant="rectangular" width={80} height={30} className="bg-[#353535] rounded-full py-1 px-3 text-base mr-2 mb-2" />
            <Skeleton variant="rectangular" width={80} height={30} className="bg-[#353535] rounded-full py-1 px-3 text-base mr-2 mb-2" />
            <Skeleton variant="rectangular" width={80} height={30} className="bg-[#353535] rounded-full py-1 px-3 text-base mr-2 mb-2" />
            <Skeleton variant="rectangular" width={80} height={30} className="bg-[#353535] rounded-full py-1 px-3 text-base mr-2 mb-2" />
            <Skeleton variant="rectangular" width={80} height={30} className="bg-[#353535] rounded-full py-1 px-3 text-base mr-2 mb-2" />
            <Skeleton variant="rectangular" width={80} height={30} className="bg-[#353535] rounded-full py-1 px-3 text-base mr-2 mb-2" />
            <Skeleton variant="rectangular" width={80} height={30} className="bg-[#353535] rounded-full py-1 px-3 text-base mr-2 mb-2" />
            <Skeleton variant="rectangular" width={80} height={30} className="bg-[#353535] rounded-full py-1 px-3 text-base mr-2 mb-2" />
            <Skeleton variant="rectangular" width={80} height={30} className="bg-[#353535] rounded-full py-1 px-3 text-base mr-2 mb-2" />
            <Skeleton variant="rectangular" width={80} height={30} className="bg-[#353535] rounded-full py-1 px-3 text-base mr-2 mb-2" />
            <Skeleton variant="rectangular" width={80} height={30} className="bg-[#353535] rounded-full py-1 px-3 text-base mr-2 mb-2" />
            <Skeleton variant="rectangular" width={80} height={30} className="bg-[#353535] rounded-full py-1 px-3 text-base mr-2 mb-2" />
            <Skeleton variant="rectangular" width={80} height={30} className="bg-[#353535] rounded-full py-1 px-3 text-base mr-2 mb-2" />
            <Skeleton variant="rectangular" width={80} height={30} className="bg-[#353535] rounded-full py-1 px-3 text-base mr-2 mb-2" />
            <Skeleton variant="rectangular" width={80} height={30} className="bg-[#353535] rounded-full py-1 px-3 text-base mr-2 mb-2" />
            {/* Repeat for other genres */}
          </div>

          <div className="flex justify-start mt-2">
            <Skeleton variant="rectangular" width={150} height={40} className="cursor-pointer mr-4  p-2 m-2 rounded-md  hover:scale-110 transition-all" />
            <Skeleton variant="rectangular" width={150} height={40} className="cursor-pointer   p-2 m-2 rounded-md  hover:scale-110 transition-all" />
          </div>

          <div className="mb-4">
      <div className="flex flex-wrap mb-4">
        <div className="w-full md:w-1/2">
          <p className="text-gray-400">
            <Skeleton className="text-purple-600"/> <Skeleton variant="text" width={80} />
            <br />
            <Skeleton className="text-purple-600"/> <Skeleton variant="text" width={120} />
            <br />
            <Skeleton className="text-purple-600"/> <Skeleton variant="text" width={120} />
            <br />
            <Skeleton className="text-purple-600"/> <Skeleton variant="text" width={80} />
          </p>
        </div>
        <div className="w-full md:w-1/2">
          <p className="text-gray-400 flex flex-row items-center">
            <Skeleton className="text-purple-600"/>
            <ul>
              <Skeleton variant="text" width={100} />
              <Skeleton variant="text" width={100} />
            </ul>
          </p>
          <p className="text-gray-400">
            <Skeleton className="text-purple-600"/> <Skeleton variant="text" width={200} />
            <br />
            <Skeleton className="text-purple-600"/> <Skeleton variant="text" width={80} />
          </p>
        </div>
      </div>

      <div className="flex flex-wrap mb-4">
        <div className="w-full md:w-1/2 text-gray-400">
          <Skeleton className="text-purple-600"/> <Skeleton variant="text" width={100} />
          <br />
          <Skeleton className="text-purple-600"/><Skeleton variant="text" width={100} />
          <br />
          <Skeleton className="text-purple-600"/><Skeleton variant="text" width={120} />
        </div>
        <div className="w-full md:w-1/2">
          <p className="text-gray-400">
            <Skeleton className="text-purple-600" /> <Skeleton variant="text" width={120} />
            <br />
            <Skeleton className="text-purple-600" /> <Skeleton variant="text" width={100} />
            <br />
            <Skeleton className="text-purple-600" /> <Skeleton variant="text" width={80} />
          </p>
        </div>
      </div>
    </div>
        </div>
      </div>

      {/* Add Skeletons for Tags and Characters sections */}
    </div>
  );
};

export default AnimeDetailsSkeleton;
