import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const AnimeCalendar = ({ data }) => {
  const [selectedDay, setSelectedDay] = useState(null);
  const [sortedDays, setsortedDays] = useState(null);
  useEffect(() => {
    const sortedDays2 = Object.entries(data)
      .sort(([, dayA], [, dayB]) => {
        const dateA = dayA[0]?.airingAt ? new Date(dayA[0]?.airingAt) : null;
        const dateB = dayB[0]?.airingAt ? new Date(dayB[0]?.airingAt) : null;
  
        // Handle cases where either dateA or dateB is null
        if (!dateA && !dateB) {
          return 0;
        } else if (!dateA) {
          return 1;
        } else if (!dateB) {
          return -1;
        } else {
          return dateA - dateB;
        }
      })
      .map(([day]) => data[day]);
  
    // setSortedDays(sortedDays2);
    setSelectedDay(sortedDays2[0]); // Selecting the first day after sorting
  }, [data]);
  
  const handleDayClick = (day) => {
    setSelectedDay(data[day]);
  };

  const formatDate = (date) => {
    const options = { month: 'short', day: 'numeric' };
    return new Date(date).toLocaleDateString('en-US', options).toUpperCase();
  };
  const footerStyle = {
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("footer2.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
};
  return (
    <div className="flex flex-col  md:w-[80%] md:ml-10 bg-[#171717]  p-2 rounded-md text-white font-jt" style={footerStyle}>
      <div className='flex flex-row w-full justify-between items-center overflow-x-scroll scrollbar-hide mb-2'>
      {Object.keys(data).map((day) => (
        <div key={day} className="text-center mx-5">
          <div
            key={day}
            className={`cursor-pointer font-bold uppercase text-[40px] md:text-[40px]  ${selectedDay === data[day] ? 'border-b-2  text-purple-800 border-b-purple-800' : ' text-gray-300 hover:text-white transition-all'}`}
            onClick={() => handleDayClick(day)}
          >
          <p className={` text-[14px]  ${selectedDay === data[day] ? 'text-purple-800 ' : ' text-gray-300 hover:text-white transition-all'}`}>{data[day][0].airingAt?formatDate(data[day][0].airingAt):"No Data"}</p>
            <h2>{day.charAt(0).toUpperCase() + day.slice(1, 3)}</h2>
          </div>
        </div>
      ))}
      </div>
      {selectedDay && (
        <div className="col-span-7 ">
          
           
            <div>
            {selectedDay.map((anime) => (
                <div key={anime.id} className='flex flex-row justify-between h-12 md:h-auto my-1 px-1 md:px-4  hover:bg-[#4d4d4d] hover:rounded'>
                  <div className='flex flex-row w-full'>
                  <div className=" md:px-4 md:w-[10%] w-auto py-2 text-center">
                    {new Date(anime.airingAt).toTimeString().split(' ')[0]}
                  </div>
                  <div className="px-2 md:px-4 w-full  md:w-auto py-2 text-center">{anime.title.english?anime.title.english:anime.title.romaji}</div>
                  </div>
                  <Link to={`/watch?anime=${anime.id}`} className="md:hidden px-4 cursor-pointer hover:text-gray-300 hover:bg-purple-900 hover:border-l-purple-950 hover:border-1 bg-[#333333]  flex flex-row  items-center justify-between w-[155px]  rounded-full text-sm text-center">
                  <img width="13" height="13" src="https://img.icons8.com/ios-glyphs/30/FFFFFF/play--v1.png" alt="play--v1"/>                    
                  Episode {anime.airingEpisode}</Link>
                
                  <Link to={`/watch?anime=${anime.id}`} className="hidden px-4 cursor-pointer hover:text-gray-300 hover:bg-purple-900 hover:border-l-purple-950 hover:border-1 bg-[#333333]  md:flex flex-row  items-center justify-between w-[155px]  rounded-full text-center">
                  <img width="15" height="15" src="https://img.icons8.com/ios-glyphs/30/FFFFFF/play--v1.png" alt="play--v1"/>                    
                  Episode {anime.airingEpisode}</Link>
                
                </div>
              ))}
            </div>
            
           
        </div>
      )}
    </div>
  );
};

export default AnimeCalendar;
