import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import {
  BeatLoader
} from "react-spinners";
import { Link } from "react-router-dom";
const signupValidationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  display_name: Yup.string().required("Required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
});

const SignUpPopUp = ({ Loginpop, SetLoginPop, Registerpop, SetRegisterpop }) => {
  const [showPassword, setShowPassword] = useState(true);
  const [LastScreen, setLastScreen] = useState(false);
  const checkEmailExists = async (email) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_AWS}/check_email_exists/`,
        {
          params: { email: email },
        }
      );
      return response.data; // Assumes the response contains a field 'exists' which is a boolean
    } catch (error) {
      console.error("Error checking email:", error);
      return false;
    }
  };

  const registerUser = async (userData) => {
    try {
      //console.log(userData, "asd");
      let userData2 = {
        email: userData.email,
        display_name: userData.display_name,
        password: userData.password,
        phone_number: userData.phone_number,
      };
      const response = await axios.post(
        `https://api.anime4us.me/api/user/register`,
        userData2
      );
      return response.data; // handle response data here
    } catch (error) {
      console.error(error); // handle error here
      return null;
    }
  };

  const footerStyle = {
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("signup-img.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
};
  return (
    <div className="fixed top-0 left-0 z-[3000] w-screen text-gray-300 h-screen backdrop-blur-sm  bg-black bg-opacity-50  font-jt" >
      <div className="flex relative flex-col lg:flex-row bg-[#1C1C1C] bg-opacity-80 h-full lg:h-[98vh] lg:mt-2 lg:my-2 w-[100%] lg:w-[84%] lg:rounded-2xl mx-auto py-8 shadow-lg " style={footerStyle}>
        <button
          onClick={() => { SetRegisterpop(!Registerpop) }}
          className="absolute top-3 right-3 transition-all hover:scale-[1.05] text-gray-600 hover:text-gray-800">
          <img src="close.svg" className="h-10" alt="" />
        </button>
        <div
          
          alt=""
          className="lg:block hidden  mx-auto my-[40px] h-[600px] w-[600px] "
        />
        
          <div className="bg-[#1C1C1C] bg-opacity-80  mr-14 p-4 lg:p-8 rounded-lg w-full lg:w-1/2">
            <h2 className="text-2xl font-extrabold mb-3 text-[45px]">
              Sign Up
            </h2>
            <p className="text-gray-300 font-thin mt-6 mb-8 text-[25px]">
              Please fill this form to Register
            </p>

            <Formik
              initialValues={{
                email: "",
                display_name: "",
                password: "",
                confirmPassword: "",
              }}
              validationSchema={signupValidationSchema}
              onSubmit={async (values, { setSubmitting, setFieldError }) => {
                //console.log("ASDDSA");
                // Check if email already exists
                const emailExists = await checkEmailExists(values.email);
                if (emailExists?.email_exists) {
                  setFieldError(
                    "email",
                    "This email already exists. Please use a different email."
                  );
                  setSubmitting(false);
                  return;
                }

                // If email is unique, proceed with registration
                const response = await registerUser(values);
                if (response) {
                  setLastScreen(true)
                  // Handle any post-registration actions here, such as redirecting to a different page
                } else {
                  alert("Registration failed.");
                }

                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form className="space-y-4">
                  <div className="relative ">
                      <label
                        htmlFor="display_name"
                        className="ml-6  bg-[#1C1C1C] bg-opacity-80 font-semibold text-[16px] absolute -top-[11px] left-2 z-10"
                      >
                        Display Name
                      </label>
                      <Field
                        id="display_name"
                        name="display_name"
                        type="text"
                        className="relative mb-4 border-[3px] block text-[18px] py-3 px-5 text-gray-300 bg-[#1C1C1C] border-gray-300 rounded-full w-full"
                      />
                      <ErrorMessage
                        name="display_name"
                        className="text-red-600 text-sm ml-5"
                        component="div"
                      />
                    </div>
                  
                  <div className="relative">
                    <label
                      htmlFor="email"
                      className="ml-6 bg-[#1C1C1C] bg-opacity-80 font-semibold text-[16px] absolute -top-[11px] left-2 z-10"
                    >
                      Email
                    </label>
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      className="relative mb-4 border-[3px] block text-[18px] py-3 px-5 text-gray-300 bg-[#1C1C1C] border-gray-300 rounded-full w-full"
                    />
                    <ErrorMessage
                      name="email"
                      className="text-red-600 text-sm ml-5"
                      component="div"
                    />
                  </div>
                  <div className="relative">
                    <label
                      htmlFor="password"
                      className="ml-6 bg-[#1C1C1C] bg-opacity-80 font-semibold text-[16px] absolute -top-[11px] left-2 z-10"
                    >
                      Create Password
                    </label>
                    <Field
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      className="relative mb-4 border-[3px] block text-[18px] py-3 px-5 text-gray-300 bg-[#1C1C1C] border-gray-300 rounded-full w-full"
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute right-4 top-7 transform -translate-y-1/2"
                    >
                      {!showPassword ? (
                        <i class="fa fa-eye-slash" aria-hidden="true"></i>
                      ) : (
                        <i class="fa fa-eye" aria-hidden="true"></i>
                      )}
                    </button>
                    <ErrorMessage
                      name="password"
                      className="text-red-600 text-sm ml-5"
                      component="div"
                    />
                  </div>
                  <div className="relative">
                    <label
                      htmlFor="confirmPassword"
                      className="ml-6 bg-[#1C1C1C] bg-opacity-80 font-semibold text-[16px] absolute -top-[11px] left-2 z-10"
                    >
                      Confirm Password
                    </label>
                    <Field
                      id="confirmPassword"
                      name="confirmPassword"
                      type={showPassword ? "text" : "password"}
                      className="relative mb-4 border-[3px] block text-[18px] py-3 px-5 text-gray-300 bg-[#1C1C1C] border-gray-300 rounded-full w-full"
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute right-4 top-7 transform -translate-y-1/2"
                    >
                      {!showPassword ? (
                        <i class="fa fa-eye-slash" aria-hidden="true"></i>
                      ) : (
                        <i class="fa fa-eye" aria-hidden="true"></i>
                      )}
                    </button>
                    <ErrorMessage
                      name="confirmPassword"
                      className="text-red-600 text-sm ml-5"
                      component="div"
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="bg-white text-black w-full  py-2 rounded-full mb-6 shadow-md mt-5"
                  >
                    {isSubmitting ? (<BeatLoader color={"white"} size={14} />) : "Register"}
                  </button>

                  
                  <div className="text-center mt-4">
                    <span className="text-gray-300">Already a Member?</span>{" "}
                    <button onClick={() => { SetLoginPop(!Loginpop); SetRegisterpop(!Registerpop) }} className="text-purple-800 underline">
                      Sign In
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        
          

        
      </div>
    </div>
  );
};

export default SignUpPopUp;
