import axios from 'axios';

const BASE_URL = 'https://api.anime4us.me/api/'; // Replace with your actual API base URL

// Function to get users with pagination and limit
const fetchUniqueValues = async ()=>{
    try {
      const response = await axios.get('https://api.anime4us.me/api/filter/unique-values'); // Replace '/api/unique-array-values' with your actual endpoint
      return response.data;
    } catch (error) {
      console.error('Error fetching unique values:', error);
      return null;
    }
  };

  
  const fetchFilterData = async (page = 1, pageSize = 10,name="",data)=> {
    try {
      
  
      // Make a POST request to the backend API
      const response = await axios.post(
        `https://api.anime4us.me/api/filter/advance-filter?page=${page}&pageSize=${pageSize}&name=${name}`,
        data
      );
  
      // Return the response data
      return response.data;
    } catch (error) {
      // Handle errors, e.g., log the error
      console.error('Error fetching data:', error);
      throw error; // Propagate the error to the caller if needed
    }
  }
  

export  {
    fetchUniqueValues,
    fetchFilterData
  };
  