import axios from 'axios';

const fetchAnimeSearchByCharacter = async (name) => {
    try {
        const response = await axios.get(`https://api.anime4us.me/api/anime/search-character?name=${name}`);
        return response.data;
    } catch (error) {
        throw new Error('Error fetching anime search by character');
    }
};

const fetchRandomAnime = async () => {
    try {
        const response = await axios.get('https://api.anime4us.me/api/anime/random');
        return response.data;
    } catch (error) {
        throw new Error('Error fetching random anime');
    }
};

const fetchAnimeSearch = async (name, limit = 5) => {
    try {
        const response = await axios.get(`https://api.anime4us.me/api/anime/search?name=${name}&limit=${limit}`);
        return response.data;
    } catch (error) {
        throw new Error('Error fetching anime search');
    }
};

// Add more functions for different endpoints
const fetchTrendingAnime = async () => {
    try {
        const response = await axios.get('https://api.anime4us.me/api/anime/trending');
        return response.data;
    } catch (error) {
        throw new Error('Error fetching trending anime');
    }
};

const fetchPopularAnime = async () => {
    try {
        const response = await axios.get('https://api.anime4us.me/api/anime/popular');
        return response.data;
    } catch (error) {
        throw new Error('Error fetching popular anime');
    }
};

const fetchRecentEpisodes = async () => {
    try {
        const response = await axios.get('https://api.anime4us.me/api/anime/recent');
        return response.data;
    } catch (error) {
        throw new Error('Error fetching recent episodes');
    }
};

const fetchSchedule = async () => {
    try {
        const response = await axios.get('https://api.anime4us.me/api/anime/schedule');
        return response.data;
    } catch (error) {
        throw new Error('Error fetching schedule');
    }
};
const fetchTopAnimeByGenre = async () => {
    try {
        const response = await axios.get(`https://api.anime4us.me/api/anime/top`);
        return response.data;
    } catch (error) {
        throw new Error(`Error fetching top anime `);
    }
};
const fetchAnimeById = async (id) => {
    try {
      const response = await axios.get(`https://api.anime4us.me/api/anime/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error fetching anime by ID: ${error.message}`);
    }
  };


  const fetchAnifyVideoSources = async ({ providerId, watchId, episodeNumber, id, subType }) => {
    try {
        // console.log("hit")
        const encodedEpisodeName = encodeURIComponent(watchId);
      const response = await axios.get(`https://api.anify.tv/sources?providerId=${providerId}&watchId=${encodedEpisodeName}&episodeNumber=${episodeNumber}&id=${id}&subType=${subType}`,);
    //   console.log("==============")
    //   console.log(response.data)
    //   console.log("xxxxxxxxxxxxxx")
      return {"provider":providerId,"data":response.data?.sources,"language":subType,"subtitles":response.data?.subtitles};
    } catch (error) {
      return null;
    }
  }
  const fetchEpisodeSourcesANDservers = async (episodeName,id,episode,subOrDub) => {
    try {
      const encodedEpisodeName = encodeURIComponent(episodeName);
      const response = await axios.get(`https://api.anime4us.me/api/anime/fetch-episode-sources/${encodedEpisodeName}?id=${id}&episode=${episode}&sub=${subOrDub}`);
      return response.data;
    } catch (error) {
        return null;
    }
  };
  const fetchZoro = async (id,episode) => {
    try {
      
      const response = await axios.get(`https://api.anime4us.me/api/zoro?alID=${id}&episodeNumber=${episode}`);
      return response.data;
    } catch (error) {
        return null;
    }
  };
// Export all functions
export {
    fetchAnifyVideoSources,
    fetchEpisodeSourcesANDservers,
    fetchAnimeById,
    fetchAnimeSearchByCharacter,
    fetchRandomAnime,
    fetchAnimeSearch,
    fetchTrendingAnime,
    fetchPopularAnime,
    fetchRecentEpisodes,
    fetchSchedule,
    fetchTopAnimeByGenre,
    fetchZoro
    // ... add other functions here as needed
};
