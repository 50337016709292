import React, { useState } from 'react';
import Comment from './CommentChild';
import RichInput from './RichInput';


const CommentsRoot = ({ReFetchParentComment,getdataComment, comments,userId,animeId,episodeNumber }) => {
  const [comment, setComment] = useState('');
  return (
    <div className="rounded-2xl font-jt bg-[#171717] p-4">
      {/* <h1 className="text-2xl text-white font-bold mb-4">Comments</h1> */}
      {userId ? (<div>
      <div className='ml-2 text-white mb-2'>Add Comment</div>
      <RichInput getdataComment={getdataComment} setShowReplyInput={-2} showReplyInput={-2} comment={comment} setComment={setComment}  parentId={null} userId={userId} animeId={animeId} episodeNumber={episodeNumber}/>
      </div>):(<div className='text-[30px] flex flex-row items-center text-purple-300 font-bold bg-[#242323] p-1 px-3 rounded-md my-2 '>
      Sign in to add Comment
      </div>)}
      {comments.map((comment) => (
        <Comment ReFetchParentComment={ReFetchParentComment} key={comment._id} comment={comment} parentId={comment._id} userId={userId} animeId={animeId} episodeNumber={episodeNumber}/>
      ))}
    </div>
  );
};

export default CommentsRoot;
