

const EpisodeSelectorServer = ({ videoData, ServerVideoSelect, ads, prompText }) => {
  const { language, data } = videoData
  return (
    <div className="bg-[#2a2929]  flex my-2  rounded-md w-full flex-row">
      <div className=" text-gray-300 w-[40%] md:w-[30%] items-center flex flex-row justify-center  text-center">
        {prompText}
        {ads && <img src="ad.svg" className="h-6 pl-2" alt="" />}
      </div>

      
        {language == "sub" ? (
          <div className={`bg-[#1b1a1a] rounded-r-md pl-4 py-1 text-white font-jt flex flex-col w-full `}>
          <div className="flex items-center my-auto">
            <span>

              <img src="cc.svg" className="h-4 mr-1" alt="" />
            </span>
            <p className="text-lg mr-4">Sub</p>
            <p className="text-sm flex flex-wrap space-x-1">
              {data.map(elem => (
                <span onClick={()=> ServerVideoSelect(elem.url)} className=" bg-[#333333] p-2 rounded my-1 cursor-pointer hover:scale-105 flex flex-row items-center">
                  <img src="play.svg" className="h-4 mr-1"></img>
                  {elem.quality ? elem.quality : elem.name ? elem.name : "Play"}</span>
              ))}
            </p>
          </div>
          </div>
        ) : (
          <div className={`bg-[#190c21] rounded-r-md pl-4 py-1 text-white font-jt flex flex-col w-full `}>
          <div className="flex items-center my-auto">
            <span>

              <img src="dub.svg" className="h-4 mr-1" alt="" />
            </span>
            <p className="text-lg mr-4">Dub</p>
            <p className="text-sm flex flex-wrap space-x-1">
              {data.map(elem => (
                <span onClick={()=> ServerVideoSelect(elem.url)} className="  bg-[#333333] p-2 cursor-pointer hover:scale-105 my-1 rounded flex flex-row items-center">
                  <img src="play.svg" className="h-4 mr-1"></img>
                  {elem.quality ? elem.quality : elem.name ? elem.name : "Play"}</span>
              ))}
            </p>
          </div>
          </div>
          )}


      </div>

    
  )
}

export default EpisodeSelectorServer

{/* <div className=" w-[26%] p-5 py-8 text-gray-300 text-sm text-center">
          You're watching <span className="text-purple-800 font-semibold">Episode 1.</span> If current servers doesn't work, please report below.
        </div> */}