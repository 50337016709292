import { useEffect, useState } from "react";
import Filter from "./Filter";
import Card from "../Home/Card/Card";
import { Button, IconButton } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import "./filtermain.css"
import { Skeleton } from "@mui/material";
import { fetchFilterData } from "../../utils/Filter Api";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";


const initialSort = [
    { "name": "Release date", "taken": false, "sortby": true },
    { "name": "Name A-Z", "taken": false, "sortby": true },
    { "name": "Average Rating", "taken": false, "sortby": true },
    { "name": "Anilist Rating", "taken": false, "sortby": true },
    { "name": "Kitsu Rating", "taken": false, "sortby": true },
    { "name": "Average Popularity", "taken": false, "sortby": true },
    { "name": "Anilist Popularity", "taken": false, "sortby": true },
    { "name": "Number of Episodes", "taken": false, "sortby": true }
]
let queryintial = {
    "sortData": initialSort,
    "TagsIncluded": [],
    "TagsnotIncluded": [],
    "GenresIncluded": [],
    "GenresnotIncluded": [],
    "CountriesIncluded": [],
    "CountriesnotIncluded": [],
    "StudiosIncluded": [],
    "StudiosnotIncluded": [],
    "SeasonsIncluded": [],
    "SeasonsnotIncluded": [],
    "ReleaseDateIncluded": [],
    "ReleaseDatenotIncluded": [],
    "statusIncluded": [],
    "statusnotIncluded": [],
    "typesIncluded": [],
    "typesnotIncluded": []
}
const FilterMain = () => {
    const [searchParams, setSearchParams] = useSearchParams(); 
    const navigate =useNavigate();
    const [query, setQuery] = useState(queryintial);
    const [search, setSearch] = useState("");
    const [page, setpage] = useState(1);
    const [data, setData] = useState(null);
    const [TotalResult,setTotalResult]=useState(0);
    const [loading, setloading] = useState(false);
    const [lastPage,setlastPage]=useState(5);
    const [intialLimit,setintialLimit] = useState(5)
    const [maxLimit,setmaxLimit] = useState(10)
    const dummyRecently = new Array(40).fill(0)


    
    const nextPage = () => {
        setpage(page + 1);
    };

    // Function to handle previous page
    const prevPage = () => {
        if (page > 1) {
            setpage(page - 1);
        }
    };

    const [active, setActive] = useState(1);

    const getItemProps = (index) => ({
        variant:"text",
        color: "grey",
        onClick: () => setActive(index),
        className: active=== index ?" text-purple-500 text-[30px] font-bold  text-center mx-auto":"text-gray-300 text-center mx-auto",
    });

    const next = () => {
        if (active === lastPage) {
            if(active<maxLimit){
                setlastPage(lastPage+1)
                setActive(active + 1);
            }    
            return;
        
        }

        setActive(active + 1);
    };

    const prev = () => {
        if (active === 1) return;

        if(active>intialLimit){
            setlastPage(lastPage-1)
            setActive(active - 1);
        }
        else{

            setActive(active - 1);
        }
    };

    const searchFunc= async()=>{
        if (searchParams.get('genre')) {
            
            let newQuery=query;
            newQuery.GenresIncluded=[searchParams.get('genre')]

            // setQuery(prevQuery => ({
            //     ...prevQuery,
            //     GenresIncluded: [searchParams.get('genre')], // Update GenresIncluded with the genre from URL
            // }));
            navigate('/filter')
            
            setloading(true)
            const dataget = await fetchFilterData(active,40,search,newQuery);

            setloading(false)
            if(dataget.animeCount>0) setmaxLimit((dataget.animeCount/40 )+1)
            setTotalResult(dataget.animeCount)
            setData(dataget.data)
        }
        else if (searchParams.get('ongoing')) {
            
            let newQuery=query;
            newQuery.statusIncluded=[searchParams.get('ongoing')]

            
            navigate('/filter')
            
            setloading(true)
            const dataget = await fetchFilterData(active,40,search,newQuery);

            setloading(false)
            if(dataget.animeCount>0) setmaxLimit((dataget.animeCount/40 )+1)
            setTotalResult(dataget.animeCount)
            setData(dataget.data)
        }
        else{
            setloading(true)
            const dataget = await fetchFilterData(active,40,search,query);
            setloading(false)
            if(dataget.animeCount>0) setmaxLimit((dataget.animeCount/40 )+1)
            setTotalResult(dataget.animeCount)
            setData(dataget.data)
        }

           
    }

    useEffect(()=>{
        searchFunc()
    },[active])

    return (<div className="w-fit mb-10">
        <Filter toggleGenreHome={searchParams.get('genres')} toggleTagHome={searchParams.get('tags')} toggleTypeHome={searchParams.get('types')} toggleSeasonHome={searchParams.get('seasons')}  query={query } search={search} setSearch={setSearch} searchFunc={searchFunc} setQuery={setQuery} />
        <div className="md:w-[82vw] mb-4 flex flex-col md:flex-row items-center justify-between">
            <div className="text-white ml-4 text-[20px] px-2">Total Results :{TotalResult}</div>
            <div className="flex w-fit bg-[#1C1C1C] rounded-full justify-around  text-black items-center">
                
                <Button
                    variant="text"
                    className="flex py-3 p-2 md:p-auto items-center md:gap-2 text-gray-300 rounded-full"
                    onClick={prev}
                    disabled={active === 1}
                >
                    <ArrowLeftIcon strokeWidth={2} className="h-4 text-gray-300 w-4" /> Previous
                </Button>
                <div className="flex md:space-x-2 items-center">
                    <IconButton {...getItemProps(1)}>1</IconButton>
                    <IconButton {...getItemProps(2)}>2</IconButton>
                    <IconButton {...getItemProps(3)}>3</IconButton>
                    <IconButton {...getItemProps(4)}>4</IconButton>
                    <IconButton {...getItemProps(5)}>{5}</IconButton>
                    {intialLimit<active &&<IconButton {...getItemProps(lastPage)}>{lastPage}</IconButton>}
                </div>
                <Button
                    variant="text"
                    className="flex py-3 p-2 md:p-auto items-center md:gap-2 md:ml-2 text-gray-300 rounded-full"
                    onClick={next}
                    disabled={active === maxLimit}
                >
                    Next
                    <ArrowRightIcon strokeWidth={2} className="h-4 text-gray-300 w-4" />
                </Button>
            </div>
            {/* <div className="flex justify-end my-4">

                <button
          className="px-4 py-2 rounded-full bg-[#956262] text-white  mr-2"
          onClick={prevPage}
        >
          Previous
        </button>
        <button
          className="px-4 py-2 rounded-full bg-[#956262] text-white "
          onClick={nextPage}
        >
          Next
        </button>
            </div> */}
        </div>

        <div className="flex md:w-[90vw] justify-around md:justify-start flex-wrap">
            {data ? (<>
                {data.map(elem => (
                    <Card data={elem} />
                ))}
            </>) : (<>
                {loading && dummyRecently.map(elem => (<div className=" mb-4 mx-10">
                    <Skeleton variant="rectangular" className="" sx={{ bgcolor: 'grey.900' }} width={180} height={230} />
                    <Skeleton variant="rectangular" className=" mt-2" sx={{ bgcolor: 'grey.900' }} width={180} height={30} />
                </div>))}
            </>
            )}
        </div>


    </div>)
}


export default FilterMain;