import { Skeleton } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { fetchRecentEpisodes } from "../../../utils/AnimeApi";
import Card from "./Card";
import { getHistory } from "../../../utils/History Api";
import { AuthContext } from "../../../authContext/AuthContext";


const ContineWatching = () => {
  const { user, dispatch } = useContext(AuthContext);
    const dummyRecently = new Array(40).fill(0)
    const [historyData, sethistoryData] = useState(null);

    
    useEffect(() => {
       if(user){
        const fetchData = async () => {
          try {
            const recent = await getHistory(user.user._id);
            // console.log(recent)
            recent.reverse()
            sethistoryData(recent);
          } catch (error) {
            
            console.error('Error fetching data:', error);
          }
        };
        fetchData();
       }  
      }, [user]);

return(
    <div className="">
          <div className=" ml-4 font-medium text-3xl mt-2 text-white font-jt">
            Your Watch History
          </div>
          <div className="ml-2 flex justify-around flex-wrap">
            {historyData?(<>
                {historyData.map(elem=>(
                  <Card user={user} dispatch={dispatch} history={elem.history} data={elem.animeDetails}/>
                ))}
            </>):(<>
              {dummyRecently.map(elem=>(<div className="mt-4">
              <Skeleton variant="rectangular" className=""  sx={{ bgcolor: 'grey.900' }} width={180} height={230}/>
              <Skeleton variant="rectangular" className=" mt-2"  sx={{ bgcolor: 'grey.900' }} width={180} height={30}/>
              </div>))}
            </>
            )}
          </div>
        </div>
)

}

export default ContineWatching;