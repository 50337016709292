import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import './styles.css';
import { addComment } from '../../../utils/Comment Api';

const RichInput = ({getdataComment,setShowReplyInput,showReplyInput,getDataChildren,comment,setComment,flag,parentId, userId, animeId, episodeNumber}) => {
 

  const modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{ 'size': [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link'],
      ['clean']
    ],
  };

  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link'
  ];

  const handleEditorChange = (content) => {
    setComment(content);
  };

  const handleSubmit = async () => {
    if (comment.trim() !== '<p> </p>') {
      let text=comment
      const commentdata={text,parentId, userId, animeId, episodeNumber}
       const data = await addComment(commentdata);

       if(showReplyInput!==-2){
        setComment(null)
        setShowReplyInput(!showReplyInput)
      
        await getDataChildren()
      }
       else{
        setComment(null)
        getdataComment()
        
       }
    } else {
      alert('Please enter a comment before submitting.');
    }
  };

  return (
    <div className='text-white '>
      <div className={`bg-[#242323] ${flag==true?"rounded-none":"rounded-2xl"}`}>
      <ReactQuill
        theme="snow"
        value={comment}
        onChange={handleEditorChange}
        modules={modules}
        formats={formats}
        className='rounded-full'
      />
      </div>
      
       {flag!=true  && <button className='ml-2 mt-2 bg-[#323232] rounded-md p-2 flex flex-row  items-center' onClick={handleSubmit}>Submit Comment<img src="submit.svg" className='h-4' alt="" /></button>} 
        </div>
  );
};

export default RichInput;
