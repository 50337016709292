// Your main component
import React, { useEffect, useState } from 'react';
import AnimeCalendar from './Calender'; // Replace with your actual file path
import RealTimeClock from './RealTimeClock';
import { fetchSchedule } from '../../../utils/AnimeApi';

const Schedule = () => {
  const animeData = {
    "sunday": [
      {
        "id": "159560",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/429054/posters/63d0f8519417b.jpg",
        "bannerImage": "https://image.tmdb.org/t/p/w500/wqGiU3FE8Ca7w6GpUsRcJw714mr.jpg",
        "title": {
          "native": "逃走中 グレートミッション",
          "romaji": "Tousouchuu: Great Mission",
          "english": "Run for Money: The Great Mission"
        },
        "currentEpisode": 34,
        "airingAt": 1702771200000,
        "airingEpisode": 36
      },
      {
        "id": "168555",
        "coverImage": "https://s4.anilist.co/file/anilistcdn/media/anime/cover/large/bx168555-N4NuTOAiyYsF.jpg",
        "bannerImage": "",
        "title": {
          "native": "冒険大陸 アニアキングダム",
          "romaji": "Bouken Tairiku Ania Kingdom",
          "english": "Ania Kingdom"
        },
        "currentEpisode": 1,
        "airingAt": 1702771200000,
        "airingEpisode": 38
      },
      {
        "id": "21",
        "coverImage": "https://media.kitsu.io/anime/poster_images/12/original.png",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/81797/backgrounds/616009a8bd688.jpg",
        "title": {
          "native": "ONE PIECE",
          "romaji": "ONE PIECE",
          "english": "ONE PIECE"
        },
        "currentEpisode": 1087,
        "airingAt": 1702773000000,
        "airingEpisode": 1088
      },
      {
        "id": "120755",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/season/2024157/posters/651ac8ae4052c.jpg",
        "bannerImage": "https://s4.anilist.co/file/anilistcdn/media/anime/banner/120755-DJS9ciiZfKiq.jpg",
        "title": {
          "native": "アイドルマスター ミリオンライブ！",
          "romaji": "The IDOLM@STER Million Live!",
          "english": "THE IDOLM@STER Million Live!"
        },
        "currentEpisode": 10,
        "airingAt": 1702774800000,
        "airingEpisode": 11
      },
      {
        "id": "155321",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/437767/posters/64c6397f90978.jpg",
        "bannerImage": "",
        "title": {
          "native": "盖世帝尊",
          "romaji": "Gaishi Dizun",
          "english": "The Galaxy Emperor"
        },
        "currentEpisode": 34,
        "airingAt": 1702778400000,
        "airingEpisode": 42
      },
      {
        "id": "122521",
        "coverImage": "https://artworks.thetvdb.com/banners/series/392234/posters/5fb7d46d06219.jpg",
        "bannerImage": "",
        "title": {
          "native": "绝世武魂",
          "romaji": "Jueshi Wu Hun",
          "english": "Peerless Martial Spirit"
        },
        "currentEpisode": 329,
        "airingAt": 1702778400000,
        "airingEpisode": 332
      },
      {
        "id": "168163",
        "coverImage": "https://media.kitsu.io/anime/48062/poster_image/c9dd72fb807a4ee4470c81cd32ef8b71.jpg",
        "bannerImage": "",
        "title": {
          "native": "斗破苍穹 年番2",
          "romaji": "Dou Po Cangqiong: Nian Fan 2",
          "english": "Battle Through the Heavens 6"
        },
        "currentEpisode": null,
        "airingAt": 1702778400000,
        "airingEpisode": 23
      },
      {
        "id": "156075",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/440343/posters/65193d598acf8.jpg",
        "bannerImage": "https://image.tmdb.org/t/p/w500/6YrRIBR24lO53EqoxW0LHXZaM1p.jpg",
        "title": {
          "native": "傲世九重天",
          "romaji": "Aoshi Jiu Chong Tian",
          "english": "Transcending the Nine Heavens"
        },
        "currentEpisode": 4,
        "airingAt": 1702778400000,
        "airingEpisode": 12
      },
      {
        "id": "155723",
        "coverImage": "https://media.kitsu.io/anime/48033/poster_image/6e29a6b5862d9cbae8205d44a386ec8d.png",
        "bannerImage": "",
        "title": {
          "native": "武神主宰 大威篇",
          "romaji": "Wushen Zhuzai: Da Wei Pian",
          "english": null
        },
        "currentEpisode": 112,
        "airingAt": 1702778400000,
        "airingEpisode": 122
      },
      {
        "id": "148862",
        "coverImage": "https://media.kitsu.io/anime/46107/poster_image/dbfc805268ad8dddb45b7c8dd7bfebca.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/420018/backgrounds/64aa0f3deb0f8.jpg",
        "title": {
          "native": "七つの大罪 黙示録の四騎士",
          "romaji": "Nanatsu no Taizai: Mokushiroku no Yonkishi",
          "english": "The Seven Deadly Sins: Four Knights of the Apocalypse"
        },
        "currentEpisode": 8,
        "airingAt": 1702798200000,
        "airingEpisode": 10
      },
      {
        "id": "151970",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/421855/posters/642345d6e5b23.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/421855/backgrounds/6408cd5c895bb.jpg",
        "title": {
          "native": "シャングリラ・フロンティア",
          "romaji": "Shangri-La Frontier",
          "english": "Shangri-La Frontier"
        },
        "currentEpisode": 11,
        "airingAt": 1702800000000,
        "airingEpisode": 12
      },
      {
        "id": "160514",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/429985/posters/64eefad570357.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/429985/backgrounds/64bc20490885b.jpg",
        "title": {
          "native": "DOG SIGNAL",
          "romaji": "DOG SIGNAL",
          "english": "ドッグシグナル"
        },
        "currentEpisode": 1,
        "airingAt": 1702800000000,
        "airingEpisode": 9
      },
      {
        "id": "163024",
        "coverImage": "https://media.kitsu.io/anime/47229/poster_image/cab3e0f874416536412537ccea780f68.jpg",
        "bannerImage": "https://s4.anilist.co/file/anilistcdn/media/anime/banner/163024-rFtOWsDQy12B.jpg",
        "title": {
          "native": "キャプテン翼シーズン2 ジュニアユース編",
          "romaji": "Captain Tsubasa: Season 2 - Junior Youth-hen",
          "english": "Captain Tsubasa: Junior Youth Arc"
        },
        "currentEpisode": 11,
        "airingAt": 1702801800000,
        "airingEpisode": 12
      },
      {
        "id": "118123",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/400547/posters/60ddea06070b8.jpg",
        "bannerImage": "https://s4.anilist.co/file/anilistcdn/media/anime/banner/118123-BvHyzWd850nZ.jpg",
        "title": {
          "native": "ホロのぐらふぃてぃ",
          "romaji": "Holo no Graffiti",
          "english": "Holo Graffiti"
        },
        "currentEpisode": 195,
        "airingAt": 1702803600000,
        "airingEpisode": 239
      },
      {
        "id": "6149",
        "coverImage": "https://s4.anilist.co/file/anilistcdn/media/anime/cover/medium/b6149-w9IRcMlFkn4i.jpg",
        "bannerImage": "",
        "title": {
          "native": "ちびまる子ちゃん",
          "romaji": "Chibi Maruko-chan (1995)",
          "english": "Chibi Maruko-chan"
        },
        "currentEpisode": null,
        "airingAt": 1702803600000,
        "airingEpisode": 1421
      },
      {
        "id": "2406",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/359424/posters/62eac46d28773.jpg",
        "bannerImage": "https://s4.anilist.co/file/anilistcdn/media/anime/banner/2406-ROqXxejQ5bH9.jpg",
        "title": {
          "native": "サザエさん",
          "romaji": "Sazae-san",
          "english": "Mrs. Sazae"
        },
        "currentEpisode": 2640,
        "airingAt": 1702805400000,
        "airingEpisode": 2713
      },
      {
        "id": "160515",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/429994/posters/641e6590db8c8.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/429994/backgrounds/6522ff275f3c7.jpg",
        "title": {
          "native": "オーバーテイク！",
          "romaji": "Overtake!",
          "english": "OVERTAKE!"
        },
        "currentEpisode": 11,
        "airingAt": 1702818000000,
        "airingEpisode": 12
      },
      {
        "id": "162694",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/432050/posters/640f54e3cfd63.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/432050/backgrounds/6509c1bdf2b62.jpg",
        "title": {
          "native": "君のことが大大大大大好きな100人の彼女",
          "romaji": "Kimi no Koto ga Dai Dai Dai Dai Daisuki na 100-nin no Kanojo",
          "english": "The 100 Girlfriends Who Really, Really, Really, Really, REALLY Love You"
        },
        "currentEpisode": 10,
        "airingAt": 1702819800000,
        "airingEpisode": 11
      },
      {
        "id": "143327",
        "coverImage": "https://media.kitsu.io/anime/45713/poster_image/3a49bff3d452d460606237cd09935c8e.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/414207/backgrounds/651d072135efe.jpg",
        "title": {
          "native": "MFゴースト",
          "romaji": "MF Ghost",
          "english": "MF GHOST"
        },
        "currentEpisode": 11,
        "airingAt": 1702825200000,
        "airingEpisode": 12
      },
      {
        "id": "156039",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/426530/posters/64131feec05a1.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/426530/backgrounds/651a4557e6382.jpg",
        "title": {
          "native": "暴食のベルセルク",
          "romaji": "Boushoku no Berserk",
          "english": "Berserk of Gluttony"
        },
        "currentEpisode": 11,
        "airingAt": 1702825200000,
        "airingEpisode": 12
      },
      {
        "id": "129229",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/440383/posters/651a3caa3db3e.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/440383/backgrounds/654f085c7ef9d.jpg",
        "title": {
          "native": "三十六骑",
          "romaji": "36 Ji",
          "english": "Thirty-six Cavalry"
        },
        "currentEpisode": 8,
        "airingAt": 1702825200000,
        "airingEpisode": 12
      },
      {
        "id": "152802",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/422090/posters/648ff7b77be8e.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/422090/backgrounds/64c7fac7afe95.jpg",
        "title": {
          "native": "ダークギャザリング",
          "romaji": "Dark Gathering",
          "english": "Dark Gathering"
        },
        "currentEpisode": 23,
        "airingAt": 1702829100000,
        "airingEpisode": 24
      },
      {
        "id": "126316",
        "coverImage": "https://media.kitsu.io/anime/44311/poster_image/5843f81e1f2205bf930a3b11adbfc773.jpg",
        "bannerImage": "https://s4.anilist.co/file/anilistcdn/media/anime/banner/126316-3xTe0FoN6Fkw.jpg",
        "title": {
          "native": "B-PROJECT 熱烈＊ラブコール",
          "romaji": "B-PROJECT: Netsuretsu*Love Call",
          "english": "B-PROJECT Passion*Love Call"
        },
        "currentEpisode": 10,
        "airingAt": 1702832400000,
        "airingEpisode": 12
      },
      {
        "id": "170660",
        "coverImage": "https://s4.anilist.co/file/anilistcdn/media/anime/cover/medium/b170660-FSRmrtkvYKfN.jpg",
        "bannerImage": "",
        "title": {
          "native": "チキップダンサーズ　第3期",
          "romaji": "Chickip Dancers 3rd Season",
          "english": "Kimi ni Todoke: From Me to You Season 3"
        },
        "currentEpisode": null,
        "airingAt": 1702856700000,
        "airingEpisode": 13
      }
    ],
    "monday": [
      {
        "id": "162803",
        "coverImage": "https://media.kitsu.io/anime/47195/poster_image/e03b4e72f4a2d12843dfb1786f7fb996.jpg",
        "bannerImage": "https://media.kitsu.io/anime/47195/cover_image/29ff0efedf11395a783b79e0da4309df.png",
        "title": {
          "native": "デッドマウント・デスプレイ 第2クール",
          "romaji": "Dead Mount Death Play Part 2",
          "english": "Dead Mount Death Play Part 2"
        },
        "currentEpisode": 22,
        "airingAt": 1702306800000,
        "airingEpisode": 10
      },
      {
        "id": "155389",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/425474/posters/649d6e34560e8.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/425474/backgrounds/633f0df19c501.jpg",
        "title": {
          "native": "SHY",
          "romaji": "SHY",
          "english": "SHY"
        },
        "currentEpisode": 11,
        "airingAt": 1702306800000,
        "airingEpisode": 11
      },
      {
        "id": "158704",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/428350/posters/646b8bb3f1c57.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/428350/backgrounds/650207d00230b.jpg",
        "title": {
          "native": "私の推しは悪役令嬢。",
          "romaji": "Watashi no Oshi wa Akuyaku Reijou.",
          "english": "I'm in Love with the Villainess"
        },
        "currentEpisode": 11,
        "airingAt": 1702308600000,
        "airingEpisode": 11
      },
      {
        "id": "161364",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/430925/posters/64ef493056048.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/430925/backgrounds/64ff00f21cc59.jpg",
        "title": {
          "native": "とあるおっさんのVRMMO活動記",
          "romaji": "Toaru Ossan no VRMMO Katsudouki",
          "english": "A Playthrough of a Certain Dude's VRMMO Life"
        },
        "currentEpisode": 11,
        "airingAt": 1702310400000,
        "airingEpisode": 11
      },
      {
        "id": "140501",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/412076/posters/62db4eab56ecd.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/412076/backgrounds/6514d001dda95.jpg",
        "title": {
          "native": "聖剣学院の魔剣使い",
          "romaji": "Seiken Gakuin no Maken Tsukai",
          "english": "The Demon Sword Master of Excalibur Academy"
        },
        "currentEpisode": 4,
        "airingAt": 1702312200000,
        "airingEpisode": 12
      },
      {
        "id": "140842",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/415509/posters/622fb8f59ff1e.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/415509/backgrounds/62d4091b5202c.jpg",
        "title": {
          "native": "ちいかわ",
          "romaji": "Chiikawa",
          "english": "Chiikawa"
        },
        "currentEpisode": 109,
        "airingAt": 1702334400000,
        "airingEpisode": 125
      },
      {
        "id": "136862",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/406729/posters/60f18fb011e17.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/406729/backgrounds/60f19bb8a2720.jpg",
        "title": {
          "native": "逆天至尊",
          "romaji": "Ni Tian Zhizun",
          "english": "Against the Sky Supreme"
        },
        "currentEpisode": 254,
        "airingAt": 1702864800000,
        "airingEpisode": 259
      },
      {
        "id": "119927",
        "coverImage": "https://artworks.thetvdb.com/banners/series/392238/posters/5fb7d660c562b.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/392238/backgrounds/64a77eedc5707.jpg",
        "title": {
          "native": "独步逍遥",
          "romaji": "Dubu Xiaoyao",
          "english": "One Step Toward Freedom"
        },
        "currentEpisode": 380,
        "airingAt": 1702864800000,
        "airingEpisode": 374
      },
      {
        "id": "136146",
        "coverImage": "https://media.kitsu.io/anime/poster_images/45036/original.jpg",
        "bannerImage": "https://image.tmdb.org/t/p/w500/a3fMswweVPTYxyOcbBycnHjtoSF.jpg",
        "title": {
          "native": "万界仙踪 第五季",
          "romaji": "Wan Jie Xian Zong 5",
          "english": "万界仙踪 第五季"
        },
        "currentEpisode": 228,
        "airingAt": 1702864800000,
        "airingEpisode": 261
      },
      {
        "id": "137653",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/433335/posters/642d329e4d45a.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/433335/backgrounds/656016f00755d.jpg",
        "title": {
          "native": "仙逆",
          "romaji": "Xian Ni",
          "english": "仙逆"
        },
        "currentEpisode": 13,
        "airingAt": 1702864800000,
        "airingEpisode": 15
      },
      {
        "id": "137665",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/438558/posters/64e3338b14e5c.jpg",
        "bannerImage": "",
        "title": {
          "native": "魔装传说",
          "romaji": "Mo Zhuang Chuanshuo",
          "english": "魔装传说"
        },
        "currentEpisode": 25,
        "airingAt": 1702864800000,
        "airingEpisode": 38
      },
      {
        "id": "170508",
        "coverImage": "https://s4.anilist.co/file/anilistcdn/media/anime/cover/large/bx170508-72GLTka7NHeF.jpg",
        "bannerImage": "",
        "title": {
          "native": "猫猫のひとりごと",
          "romaji": "Maomao no Hitorigoto",
          "english": null
        },
        "currentEpisode": null,
        "airingAt": 1702868400000,
        "airingEpisode": 11
      },
      {
        "id": "155419",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/425760/posters/6459429d7fda2.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/425760/backgrounds/64ef039fb9d1d.jpg",
        "title": {
          "native": "星屑テレパス",
          "romaji": "Hoshikuzu Telepath",
          "english": "Stardust Telepath"
        },
        "currentEpisode": 10,
        "airingAt": 1702900800000,
        "airingEpisode": 11
      },
      {
        "id": "142666",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/413466/posters/64228c231ca7f.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/413466/backgrounds/651acb1413907.jpg",
        "title": {
          "native": "ミギとダリ",
          "romaji": "Migi to Dali",
          "english": "Migi&Dali"
        },
        "currentEpisode": 11,
        "airingAt": 1702904400000,
        "airingEpisode": 12
      },
      {
        "id": "162209",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/431804/posters/63ff15b953d7b.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/431804/backgrounds/6523955009a0a.jpg",
        "title": {
          "native": "川越ボーイズ・シング",
          "romaji": "Kawagoe Boys Sing",
          "english": "KAWAGOE BOYS SING -Now or Never-"
        },
        "currentEpisode": 10,
        "airingAt": 1702908000000,
        "airingEpisode": 11
      },
      {
        "id": "158926",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/427919/posters/639f64835807a.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/427919/backgrounds/6516a9f8aa229.jpg",
        "title": {
          "native": "鴨乃橋ロンの禁断推理",
          "romaji": "Kamonohashi Ron no Kindan Suiri",
          "english": "Ron Kamonohashi's Forbidden Deductions"
        },
        "currentEpisode": 11,
        "airingAt": 1702908000000,
        "airingEpisode": 12
      }
    ],
    "tuesday": [
      {
        "id": "153777",
        "coverImage": "https://media.kitsu.io/anime/poster_images/44332/original.jpg",
        "bannerImage": "",
        "title": {
          "native": "万界独尊 第二季",
          "romaji": "Wan Jie Duzun 2",
          "english": "Ten Thousand Worlds"
        },
        "currentEpisode": 137,
        "airingAt": 1702346400000,
        "airingEpisode": 140
      },
      {
        "id": "166219",
        "coverImage": "https://media.kitsu.io/anime/48061/poster_image/fc08f7ec292123344f0e4d22cdd747f7.png",
        "bannerImage": "",
        "title": {
          "native": "吞噬星空 第四季",
          "romaji": "Tunshi Xingkong 4",
          "english": null
        },
        "currentEpisode": 11,
        "airingAt": 1702346400000,
        "airingEpisode": 12
      },
      {
        "id": "155723",
        "coverImage": "https://media.kitsu.io/anime/48033/poster_image/6e29a6b5862d9cbae8205d44a386ec8d.png",
        "bannerImage": "",
        "title": {
          "native": "武神主宰 大威篇",
          "romaji": "Wushen Zhuzai: Da Wei Pian",
          "english": null
        },
        "currentEpisode": 112,
        "airingAt": 1702346400000,
        "airingEpisode": 121
      },
      {
        "id": "165426",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/435306/posters/646efedc6690f.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/435306/backgrounds/65522bdb83b9c.jpg",
        "title": {
          "native": "おでかけ子ザメ",
          "romaji": "Odekake Kozame",
          "english": "Little Shark's Day Out"
        },
        "currentEpisode": 19,
        "airingAt": 1702378800000,
        "airingEpisode": 20
      },
      {
        "id": "146206",
        "coverImage": "https://media.kitsu.io/anime/45957/poster_image/8d458ede6c78abfedc49a2908d711d36.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/397831/backgrounds/605a4b298a15e.jpg",
        "title": {
          "native": "聖女の魔力は万能です Season2",
          "romaji": "Seijo no Maryoku wa Bannou desu Season 2",
          "english": "The Saint's Magic Power is Omnipotent II"
        },
        "currentEpisode": 10,
        "airingAt": 1702389600000,
        "airingEpisode": 11
      },
      {
        "id": "150077",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/420663/posters/646bf05272e22.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/420663/backgrounds/651d07b1c3d03.jpg",
        "title": {
          "native": "Paradox Live THE ANIMATION",
          "romaji": "Paradox Live THE ANIMATION",
          "english": "Paradox Live THE ANIMATION"
        },
        "currentEpisode": 9,
        "airingAt": 1702389600000,
        "airingEpisode": 10
      },
      {
        "id": "137665",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/438558/posters/64e3338b14e5c.jpg",
        "bannerImage": "",
        "title": {
          "native": "魔装传说",
          "romaji": "Mo Zhuang Chuanshuo",
          "english": "魔装传说"
        },
        "currentEpisode": 25,
        "airingAt": 1702393200000,
        "airingEpisode": 37
      },
      {
        "id": "163329",
        "coverImage": "https://media.kitsu.io/anime/45607/poster_image/e58a433656cab76783d2495be15a0836.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/393478/backgrounds/60f3733aa53e2.jpg",
        "title": {
          "native": "東京リベンジャーズ 天竺編",
          "romaji": "Tokyo Revengers: Tenjiku-hen",
          "english": "Tokyo Revengers Season 2 Part 2"
        },
        "currentEpisode": 47,
        "airingAt": 1702393200000,
        "airingEpisode": 11
      },
      {
        "id": "170041",
        "coverImage": "https://s4.anilist.co/file/anilistcdn/media/anime/cover/medium/b170041-ZM18eTGQ8dYz.png",
        "bannerImage": "https://image.tmdb.org/t/p/w500/oZrkl9ZVKYHrQOoE98yQu1JpuWy.jpg",
        "title": {
          "native": "忍ばない！クリプトニンジャ咲耶",
          "romaji": "Shinobanai! Crypto Ninja Sakuya",
          "english": "忍ばない! クリプトニンジャ咲耶"
        },
        "currentEpisode": 1,
        "airingAt": 1702396800000,
        "airingEpisode": 11
      },
      {
        "id": "145140",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/416359/posters/63e62ffed0c2b.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/416359/backgrounds/6474a605de0b7.jpg",
        "title": {
          "native": "ヘルク",
          "romaji": "Helck",
          "english": "Helck"
        },
        "currentEpisode": 22,
        "airingAt": 1702398540000,
        "airingEpisode": 23
      }
    ],
    "wednesday": [
      {
        "id": "133772",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/420527/posters/628d84cb490db.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/420527/backgrounds/64ca436aed221.jpg",
        "title": {
          "native": "少年歌行之暗河传",
          "romaji": "Shaonian Ge Xing: Anhe Zhuan",
          "english": "Tales Of Dark River"
        },
        "currentEpisode": 13,
        "airingAt": 1702432800000,
        "airingEpisode": 21
      },
      {
        "id": "153493",
        "coverImage": "https://media.kitsu.io/anime/47860/poster_image/2f6f6cda8d0f405ea1a6dfd61e50486d.jpg",
        "bannerImage": "",
        "title": {
          "native": "西行纪 年番",
          "romaji": "Xi Xing Ji: Nian Fan",
          "english": null
        },
        "currentEpisode": 22,
        "airingAt": 1702432800000,
        "airingEpisode": 22
      },
      {
        "id": "122521",
        "coverImage": "https://artworks.thetvdb.com/banners/series/392234/posters/5fb7d46d06219.jpg",
        "bannerImage": "",
        "title": {
          "native": "绝世武魂",
          "romaji": "Jueshi Wu Hun",
          "english": "Peerless Martial Spirit"
        },
        "currentEpisode": 329,
        "airingAt": 1702432800000,
        "airingEpisode": 331
      },
      {
        "id": "137671",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/434304/posters/644b08e666924.jpg",
        "bannerImage": "https://image.tmdb.org/t/p/w500/ultVhSglNH3mxzsZMoURQ323d9I.jpg",
        "title": {
          "native": "遮天",
          "romaji": "Zhe Tian",
          "english": "Shrouding the Heavens"
        },
        "currentEpisode": 32,
        "airingAt": 1702432800000,
        "airingEpisode": 35
      },
      {
        "id": "127976",
        "coverImage": "https://media.kitsu.io/anime/45488/poster_image/be47f14fa3e7ac016867a6fa1fc4aa01.png",
        "bannerImage": "https://s4.anilist.co/file/anilistcdn/media/anime/banner/127976-dhwWiCREK7zU.jpg",
        "title": {
          "native": "天官赐福 第二季",
          "romaji": "Tian Guan Ci Fu 2",
          "english": "Heaven Official's Blessing 2"
        },
        "currentEpisode": null,
        "airingAt": 1702461600000,
        "airingEpisode": 7
      },
      {
        "id": "157399",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/427359/posters/64fee1c29d7d5.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/427359/backgrounds/63779641a934d.jpg",
        "title": {
          "native": "ブルバスター",
          "romaji": "Bullbuster",
          "english": "BULLBUSTER"
        },
        "currentEpisode": 10,
        "airingAt": 1702468800000,
        "airingEpisode": 11
      },
      {
        "id": "154214",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/424116/posters/64ebeb1501bf9.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/424116/backgrounds/651c70c048420.jpg",
        "title": {
          "native": "婚約破棄された令嬢を拾った俺が、イケナイことを教え込む",
          "romaji": "Konyaku Haki sareta Reijou wo Hirotta Ore ga, Ikenai Koto wo Oshiekomu",
          "english": "I'm Giving the Disgraced Noble Lady I Rescued a Crash Course in Naughtiness"
        },
        "currentEpisode": 10,
        "airingAt": 1702472400000,
        "airingEpisode": 11
      },
      {
        "id": "168092",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/438096/posters/64d2687c485de.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/438096/backgrounds/64d265374b184.jpg",
        "title": {
          "native": "放課後のブレス ",
          "romaji": "Houkago no Breath",
          "english": "Pokémon: Paldean Winds"
        },
        "currentEpisode": 3,
        "airingAt": 1702472400000,
        "airingEpisode": 4
      },
      {
        "id": "161964",
        "coverImage": "https://media.kitsu.io/anime/47099/poster_image/a3d5e652f167d91e9b308a2fcbe9e878.jpg",
        "bannerImage": "https://s4.anilist.co/file/anilistcdn/media/anime/banner/161964-a3MIPlFGpwbo.jpg",
        "title": {
          "native": "陰の実力者になりたくて！ 2nd season",
          "romaji": "Kage no Jitsuryokusha ni Naritakute! 2nd season",
          "english": "The Eminence in Shadow Season 2"
        },
        "currentEpisode": 10,
        "airingAt": 1702474200000,
        "airingEpisode": 11
      },
      {
        "id": "166523",
        "coverImage": "https://media.kitsu.io/anime/47672/poster_image/af6892b656ce9d0eb5b7f8f6a7fab78a.png",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/427074/backgrounds/655ee8ed33eac.jpg",
        "title": {
          "native": "絆のアリル セカンドシーズン",
          "romaji": "Kizuna no Allele Season 2",
          "english": "Kizuna no Allele 2nd Season"
        },
        "currentEpisode": 12,
        "airingAt": 1702479600000,
        "airingEpisode": 11
      },
      {
        "id": "156632",
        "coverImage": "https://media.kitsu.io/anime/46711/poster_image/bc0f1f329e30b7f6770a0df07dbe4356.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/series/344220/backgrounds/5fdf6e7a91c54.jpg",
        "title": {
          "native": "ウマ娘 プリティーダービー Season 3",
          "romaji": "Uma Musume: Pretty Derby Season 3",
          "english": "Umamusume: Pretty Derby Season 3"
        },
        "currentEpisode": null,
        "airingAt": 1702479600000,
        "airingEpisode": 11
      },
      {
        "id": "159559",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/429055/posters/641e800b45ad1.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/429055/backgrounds/651e5a3d98ca1.jpg",
        "title": {
          "native": "16bitセンセーション ANOTHER LAYER",
          "romaji": "16bit Sensation: ANOTHER LAYER",
          "english": "16bit Sensation: Another Layer"
        },
        "currentEpisode": 10,
        "airingAt": 1702481400000,
        "airingEpisode": 11
      },
      {
        "id": "162984",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/432749/posters/6509e0149b19d.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/432749/backgrounds/641b5a73bdd25.jpg",
        "title": {
          "native": "カミエラビ",
          "romaji": "Kamierabi",
          "english": "KamiErabi GOD.app"
        },
        "currentEpisode": 10,
        "airingAt": 1702482900000,
        "airingEpisode": 11
      }
    ],
    "thursday": [
      {
        "id": "119927",
        "coverImage": "https://artworks.thetvdb.com/banners/series/392238/posters/5fb7d660c562b.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/392238/backgrounds/64a77eedc5707.jpg",
        "title": {
          "native": "独步逍遥",
          "romaji": "Dubu Xiaoyao",
          "english": "One Step Toward Freedom"
        },
        "currentEpisode": 380,
        "airingAt": 1702519200000,
        "airingEpisode": 373
      },
      {
        "id": "136146",
        "coverImage": "https://media.kitsu.io/anime/poster_images/45036/original.jpg",
        "bannerImage": "https://image.tmdb.org/t/p/w500/a3fMswweVPTYxyOcbBycnHjtoSF.jpg",
        "title": {
          "native": "万界仙踪 第五季",
          "romaji": "Wan Jie Xian Zong 5",
          "english": "万界仙踪 第五季"
        },
        "currentEpisode": 228,
        "airingAt": 1702519200000,
        "airingEpisode": 260
      },
      {
        "id": "155321",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/437767/posters/64c6397f90978.jpg",
        "bannerImage": "",
        "title": {
          "native": "盖世帝尊",
          "romaji": "Gaishi Dizun",
          "english": "The Galaxy Emperor"
        },
        "currentEpisode": 34,
        "airingAt": 1702519200000,
        "airingEpisode": 41
      },
      {
        "id": "153499",
        "coverImage": "https://media.kitsu.io/anime/46552/poster_image/3612b2525bcf09e21e71c7569c28374b.png",
        "bannerImage": "",
        "title": {
          "native": "神印王座 第2季",
          "romaji": "Shen Yin Wangzuo 2",
          "english": null
        },
        "currentEpisode": 57,
        "airingAt": 1702519200000,
        "airingEpisode": 58
      },
      {
        "id": "166323",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/438457/posters/64dec2609e8aa.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/438457/backgrounds/64dec340b6a8b.jpg",
        "title": {
          "native": "龙时代",
          "romaji": "Long Shidai",
          "english": "X Epoch of Dragon"
        },
        "currentEpisode": 13,
        "airingAt": 1702526400000,
        "airingEpisode": 23
      },
      {
        "id": "169966",
        "coverImage": "https://s4.anilist.co/file/anilistcdn/media/anime/cover/large/bx169966-elyLBCoBAJxx.jpg",
        "bannerImage": "https://s4.anilist.co/file/anilistcdn/media/anime/banner/169966-dysUtZi16eNg.jpg",
        "title": {
          "native": "I.CINNAMOROLL Animation",
          "romaji": "I.CINNAMOROLL Animation",
          "english": "I.CINNAMOROLL Animation"
        },
        "currentEpisode": 2,
        "airingAt": 1702540800000,
        "airingEpisode": 11
      },
      {
        "id": "166452",
        "coverImage": "https://media.kitsu.io/anime/47649/poster_image/a2aefcfe364ec509f474cacf72fba264.jpg",
        "bannerImage": "https://s4.anilist.co/file/anilistcdn/media/anime/banner/166452-53WAhvvEof3s.jpg",
        "title": {
          "native": "魔法使いの嫁 SEASON2 第2クール",
          "romaji": "Mahoutsukai no Yome SEASON 2 Part 2",
          "english": "The Ancient Magus' Bride Season 2 Part 2"
        },
        "currentEpisode": null,
        "airingAt": 1702558800000,
        "airingEpisode": 11
      },
      {
        "id": "162670",
        "coverImage": "https://media.kitsu.io/anime/47246/poster_image/b1fa85143151a24d0ae818d2c5907741.jpg",
        "bannerImage": "https://media.kitsu.io/anime/47246/cover_image/b59ea923a14198c0686905ccfbff5831.png",
        "title": {
          "native": "Dr.STONE NEW WORLD 第2クール",
          "romaji": "Dr. STONE: NEW WORLD Part 2",
          "english": "Dr. STONE New World Part 2"
        },
        "currentEpisode": 20,
        "airingAt": 1702560600000,
        "airingEpisode": 10
      },
      {
        "id": "156184",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/426703/posters/648dd7de914f3.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/426703/backgrounds/651ad04005617.jpg",
        "title": {
          "native": "冒険者になりたいと都に出て行った娘がＳランクになってた",
          "romaji": "Boukensha ni Naritai to Miyako ni Deteitta Musume ga S Rank ni Natteta",
          "english": "My Daughter Left the Nest and Returned an S-Rank Adventurer"
        },
        "currentEpisode": 11,
        "airingAt": 1702562400000,
        "airingEpisode": 12
      },
      {
        "id": "164312",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/434312/posters/64fb1edfec4af.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/434312/backgrounds/64fe095b0b51c.jpg",
        "title": {
          "native": "柚木さんちの四兄弟。",
          "romaji": "Yuzuki-san Chi no Yon Kyoudai.",
          "english": "The Yuzuki Family’s Four Sons"
        },
        "currentEpisode": 10,
        "airingAt": 1702564200000,
        "airingEpisode": 11
      },
      {
        "id": "163364",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/433251/posters/643061c994565.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/433251/backgrounds/6479821597a2d.jpg",
        "title": {
          "native": "ビックリメン",
          "romaji": "Bikkurimen",
          "english": "ビックリメン"
        },
        "currentEpisode": 2,
        "airingAt": 1702564200000,
        "airingEpisode": 11
      },
      {
        "id": "145064",
        "coverImage": "https://media.kitsu.io/anime/45857/poster_image/6a6b5472cd1b1414796c70733fe332b3.jpg",
        "bannerImage": "https://s4.anilist.co/file/anilistcdn/media/anime/banner/145064-S7qAgxf6kMrW.jpg",
        "title": {
          "native": "呪術廻戦 第2期",
          "romaji": "Jujutsu Kaisen 2nd Season",
          "english": "JUJUTSU KAISEN Season 2"
        },
        "currentEpisode": 44,
        "airingAt": 1702565760000,
        "airingEpisode": 21
      },
      {
        "id": "171130",
        "coverImage": "https://s4.anilist.co/file/anilistcdn/media/anime/cover/large/bx171130-17djgipHS4oG.jpg",
        "bannerImage": "",
        "title": {
          "native": "【腐男子召喚〜異世界で神獣にハメられました〜】ミニアニメ 6th",
          "romaji": "Fudanshi Shoukan: Isekai de Shinjuu ni Hameraremashita Mini Anime 6th Season",
          "english": null
        },
        "currentEpisode": null,
        "airingAt": 1702566000000,
        "airingEpisode": 2
      },
      {
        "id": "155527",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/424459/posters/64ef5f9ca8d7f.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/424459/backgrounds/6515f6e9cc76b.jpg",
        "title": {
          "native": "お嬢と番犬くん",
          "romaji": "Ojou to Banken-kun",
          "english": "A Girl & Her Guard Dog"
        },
        "currentEpisode": 11,
        "airingAt": 1702567800000,
        "airingEpisode": 12
      },
      {
        "id": "142877",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/413578/posters/648ff2b54312a.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/413578/backgrounds/64a6cf196ada7.jpg",
        "title": {
          "native": "るろうに剣心 －明治剣客浪漫譚－(2023)",
          "romaji": "Rurouni Kenshin: Meiji Kenkaku Romantan (2023)",
          "english": "Rurouni Kenshin (2023)"
        },
        "currentEpisode": 23,
        "airingAt": 1702569300000,
        "airingEpisode": 24
      },
      {
        "id": "127163",
        "coverImage": "https://s4.anilist.co/file/anilistcdn/media/anime/cover/large/bx127163-wch07Trqr3xp.jpg",
        "bannerImage": "",
        "title": {
          "native": "レヱル・ロマネスク2",
          "romaji": "Rail Romanesque 2",
          "english": "レヱル・ロマネスク2"
        },
        "currentEpisode": null,
        "airingAt": 1702569600000,
        "airingEpisode": 11
      },
      {
        "id": "138788",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/409650/posters/64e9f973614cb.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/409650/backgrounds/651f320c1dbc5.jpg",
        "title": {
          "native": "アンダーニンジャ",
          "romaji": "Under Ninja",
          "english": "UNDER NINJA"
        },
        "currentEpisode": 10,
        "airingAt": 1702571280000,
        "airingEpisode": 11
      },
      {
        "id": "8336",
        "coverImage": "https://artworks.thetvdb.com/banners/posters/318771-1.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/fanart/original/318771-1.jpg",
        "title": {
          "native": "はなかっぱ",
          "romaji": "Hanakappa",
          "english": "はなかっぱ"
        },
        "currentEpisode": 4,
        "airingAt": 1702591800000,
        "airingEpisode": 530
      },
      {
        "id": "140842",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/415509/posters/622fb8f59ff1e.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/415509/backgrounds/62d4091b5202c.jpg",
        "title": {
          "native": "ちいかわ",
          "romaji": "Chiikawa",
          "english": "Chiikawa"
        },
        "currentEpisode": 109,
        "airingAt": 1702593600000,
        "airingEpisode": 126
      }
    ],
    "friday": [
      {
        "id": "142730",
        "coverImage": "https://s4.anilist.co/file/anilistcdn/media/anime/cover/large/bx142730-X3iXoiM0wE0c.png",
        "bannerImage": "",
        "title": {
          "native": "神武天尊 第二季",
          "romaji": "Shen Wu Tianzun 2",
          "english": "The Legend of Sky Lord 2"
        },
        "currentEpisode": 160,
        "airingAt": 1702602000000,
        "airingEpisode": 195
      },
      {
        "id": "130974",
        "coverImage": "https://s4.anilist.co/file/anilistcdn/media/anime/cover/medium/b130974-EgtPkMqfA9HX.jpg",
        "bannerImage": "",
        "title": {
          "native": "修罗剑尊",
          "romaji": "Xiuluo Jian Zun",
          "english": null
        },
        "currentEpisode": null,
        "airingAt": 1702602000000,
        "airingEpisode": 211
      },
      {
        "id": "1960",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/167921/posters/649abc69952f1.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/fanart/original/167921-3.jpg",
        "title": {
          "native": "それいけ!アンパンマン",
          "romaji": "Sore Ike! Anpanman",
          "english": "それいけ! アンパンマン"
        },
        "currentEpisode": null,
        "airingAt": 1702605300000,
        "airingEpisode": 1644
      },
      {
        "id": "136862",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/406729/posters/60f18fb011e17.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/406729/backgrounds/60f19bb8a2720.jpg",
        "title": {
          "native": "逆天至尊",
          "romaji": "Ni Tian Zhizun",
          "english": "Against the Sky Supreme"
        },
        "currentEpisode": 254,
        "airingAt": 1702605600000,
        "airingEpisode": 258
      },
      {
        "id": "134088",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/season/2062765/posters/64bc9b3baf285.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/434678/backgrounds/649e5a48d2c60.jpg",
        "title": {
          "native": "大主宰",
          "romaji": "Da Zhu Zai",
          "english": "大主宰"
        },
        "currentEpisode": 25,
        "airingAt": 1702605600000,
        "airingEpisode": 27
      },
      {
        "id": "168131",
        "coverImage": "https://s4.anilist.co/file/anilistcdn/media/anime/cover/large/bx168131-qa5FMiIpXGQS.png",
        "bannerImage": "",
        "title": {
          "native": "完美世界 第4季",
          "romaji": "Wanmei Shijie 4",
          "english": "完美世界"
        },
        "currentEpisode": 9,
        "airingAt": 1702605600000,
        "airingEpisode": 11
      },
      {
        "id": "137666",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/440271/posters/6516b2cc31abb.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/440271/backgrounds/6516b3fc483c1.jpg",
        "title": {
          "native": "恶魔法则",
          "romaji": "Emo Faze",
          "english": "Law of Devil"
        },
        "currentEpisode": 9,
        "airingAt": 1702605600000,
        "airingEpisode": 14
      },
      {
        "id": "171346",
        "coverImage": "https://s4.anilist.co/file/anilistcdn/media/anime/cover/large/bx171346-dA3AJ4iGfAo9.jpg",
        "bannerImage": "",
        "title": {
          "native": "カビゴンとカラカラ",
          "romaji": "Kabigon to Karakara",
          "english": null
        },
        "currentEpisode": null,
        "airingAt": 1702630800000,
        "airingEpisode": 2
      },
      {
        "id": "165159",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/435020/posters/64bbba6a21ffd.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/435020/backgrounds/64bbbc3dd4d15.jpg",
        "title": {
          "native": "BEYBLADE X",
          "romaji": "BEYBLADE X",
          "english": "BEYBLADE X"
        },
        "currentEpisode": 9,
        "airingAt": 1702632300000,
        "airingEpisode": 11
      },
      {
        "id": "120325",
        "coverImage": "https://s4.anilist.co/file/anilistcdn/media/anime/cover/large/bx120325-ubMgHRUT5dgJ.png",
        "bannerImage": "https://image.tmdb.org/t/p/w500/4nDGHWJZsraqLyxarkrLbGFS9zv.jpg",
        "title": {
          "native": "ふしぎ駄菓子屋 銭天堂",
          "romaji": "Fushigi Dagashiya: Zenitendou",
          "english": "ふしぎ駄菓子屋 銭天堂"
        },
        "currentEpisode": 103,
        "airingAt": 1702633200000,
        "airingEpisode": 114
      },
      {
        "id": "158871",
        "coverImage": "https://s4.anilist.co/file/anilistcdn/media/anime/cover/large/bx158871-FmWU8dacXDiA.jpg",
        "bannerImage": "https://s4.anilist.co/file/anilistcdn/media/anime/banner/158871-JGaVb9DxvgTa.jpg",
        "title": {
          "native": "ポケットモンスター (2023)",
          "romaji": "Pocket Monsters (2023)",
          "english": "Pokémon Horizons: The Series"
        },
        "currentEpisode": 32,
        "airingAt": 1702634100000,
        "airingEpisode": 33
      },
      {
        "id": "8687",
        "coverImage": "https://media.kitsu.io/anime/poster_images/5443/original.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/fanart/original/281405-4.jpg",
        "title": {
          "native": "ドラえもん (2005)",
          "romaji": "Doraemon (2005)",
          "english": null
        },
        "currentEpisode": 745,
        "airingAt": 1702634400000,
        "airingEpisode": 812
      },
      {
        "id": "169932",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/442374/posters/6558684da0a80.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/442374/backgrounds/6558f47b5c8ca.jpg",
        "title": {
          "native": "小刻的画图写话",
          "romaji": "Xiaoke de Huatu Xie Hua",
          "english": "Kay's Daily Doodles"
        },
        "currentEpisode": 4,
        "airingAt": 1702638000000,
        "airingEpisode": 5
      },
      {
        "id": "169932",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/442374/posters/6558684da0a80.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/442374/backgrounds/6558f47b5c8ca.jpg",
        "title": {
          "native": "小刻的画图写话",
          "romaji": "Xiaoke de Huatu Xie Hua",
          "english": "Kay's Daily Doodles"
        },
        "currentEpisode": 4,
        "airingAt": 1702638240000,
        "airingEpisode": 6
      },
      {
        "id": "111322",
        "coverImage": "https://s4.anilist.co/file/anilistcdn/media/anime/cover/large/bx111322-2jQMDQva4YD7.png",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/353712/backgrounds/646a60e0a8e9e.jpg",
        "title": {
          "native": "盾の勇者の成り上がり Season 3",
          "romaji": "Tate no Yuusha no Nariagari Season 3",
          "english": "The Rising of the Shield Hero Season 3"
        },
        "currentEpisode": null,
        "airingAt": 1702641600000,
        "airingEpisode": 11
      },
      {
        "id": "129188",
        "coverImage": "https://media.kitsu.io/anime/44010/poster_image/6c8f1685dbea383e1f424de684891179.png",
        "bannerImage": "https://s4.anilist.co/file/anilistcdn/media/anime/banner/129188-JMptckRAFHEr.jpg",
        "title": {
          "native": "ゴブリンスレイヤーⅡ",
          "romaji": "Goblin Slayer II",
          "english": "GOBLIN SLAYER II"
        },
        "currentEpisode": 10,
        "airingAt": 1702645200000,
        "airingEpisode": 11
      },
      {
        "id": "154459",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/424535/posters/65046e22c2bf6.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/424535/backgrounds/6532f0116c346.jpg",
        "title": {
          "native": "経験済みなキミと、経験ゼロなオレが、お付き合いする話。",
          "romaji": "Keikenzumi na Kimi to, Keiken Zero na Ore ga, Otsukiai suru Hanashi.",
          "english": "Our Dating Story: The Experienced You and The Inexperienced Me"
        },
        "currentEpisode": 10,
        "airingAt": 1702647000000,
        "airingEpisode": 11
      },
      {
        "id": "154587",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/424536/posters/64e6a8b95dfad.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/424536/backgrounds/649dbc94f2997.jpg",
        "title": {
          "native": "葬送のフリーレン",
          "romaji": "Sousou no Frieren",
          "english": "Frieren: Beyond Journey’s End"
        },
        "currentEpisode": 14,
        "airingAt": 1702648800000,
        "airingEpisode": 15
      },
      {
        "id": "163140",
        "coverImage": "https://s4.anilist.co/file/anilistcdn/media/anime/cover/large/bx163140-99mUhgXp8t5T.jpg",
        "bannerImage": "",
        "title": {
          "native": "ヒプノシスマイク-Division Rap Battle- Rhyme Anima +",
          "romaji": "Hypnosis Mic -Division Rap Battle- Rhyme Anima+",
          "english": "HYPNOSISMIC -Division Rap Battle- Rhyme Anima PLUS"
        },
        "currentEpisode": 10,
        "airingAt": 1702652400000,
        "airingEpisode": 11
      },
      {
        "id": "154116",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/423030/posters/641f13ad4d93a.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/423030/backgrounds/64fe06fb91fee.jpg",
        "title": {
          "native": "アンデッドアンラック",
          "romaji": "Undead Unluck",
          "english": "Undead Unluck"
        },
        "currentEpisode": 10,
        "airingAt": 1702657380000,
        "airingEpisode": 11
      },
      {
        "id": "160900",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/430544/posters/63da3c49344a3.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/430544/backgrounds/64fedfa13513f.jpg",
        "title": {
          "native": "はめつのおうこく",
          "romaji": "Hametsu no Oukoku",
          "english": "The Kingdoms of Ruin"
        },
        "currentEpisode": 10,
        "airingAt": 1702659180000,
        "airingEpisode": 11
      },
      {
        "id": "154692",
        "coverImage": "https://media.kitsu.io/anime/46486/poster_image/c4886de18a0c4e4b4915c8ca8d5270c4.jpg",
        "bannerImage": "https://s4.anilist.co/file/anilistcdn/media/anime/banner/154692-uWl9ysnEw17E.jpg",
        "title": {
          "native": "カノジョも彼女 Season 2",
          "romaji": "Kanojo mo Kanojo Season 2",
          "english": "Girlfriend, Girlfriend Season 2"
        },
        "currentEpisode": 10,
        "airingAt": 1702660980000,
        "airingEpisode": 11
      },
      {
        "id": "169227",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/438986/posters/64f0843b1a94c.jpg",
        "bannerImage": "https://image.tmdb.org/t/p/w500/oz0kPD4pwSdEZq8cPgl3WIMr5nM.jpg",
        "title": {
          "native": "まついぬ",
          "romaji": "Matsuinu",
          "english": "まついぬ"
        },
        "currentEpisode": 4,
        "airingAt": 1702677600000,
        "airingEpisode": 11
      },
      {
        "id": "142274",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/season/1970113/posters/61aa793218881.jpg",
        "bannerImage": "https://image.tmdb.org/t/p/w500/hF1LFAol3GaubZ6YkDR6AQeJz7q.jpg",
        "title": {
          "native": "ニンジャラ",
          "romaji": "Ninjala",
          "english": "Ninjala the Animation"
        },
        "currentEpisode": 96,
        "airingAt": 1702679400000,
        "airingEpisode": 99
      }
    ],
    "saturday": [
      {
        "id": "163136",
        "coverImage": "https://media.kitsu.io/anime/47251/poster_image/7ce0dad7a603d6551671cd798f1cf566.jpg",
        "bannerImage": "",
        "title": {
          "native": "シャドウバースF セブンシャドウズ編",
          "romaji": "Shadowverse Flame: Seven Shadows-hen",
          "english": "Shadowverse Flame: Seven Shadows Arc"
        },
        "currentEpisode": 73,
        "airingAt": 1702686600000,
        "airingEpisode": 24
      },
      {
        "id": "146348",
        "coverImage": "https://media.kitsu.io/anime/45418/poster_image/df5d466c08ef313415538aa6b6039c0e.jpg",
        "bannerImage": "",
        "title": {
          "native": "万古剑神",
          "romaji": "Wangu Jian Shen",
          "english": "Everlasting God of Sword"
        },
        "currentEpisode": 38,
        "airingAt": 1702690200000,
        "airingEpisode": 114
      },
      {
        "id": "153777",
        "coverImage": "https://media.kitsu.io/anime/poster_images/44332/original.jpg",
        "bannerImage": "",
        "title": {
          "native": "万界独尊 第二季",
          "romaji": "Wan Jie Duzun 2",
          "english": "Ten Thousand Worlds"
        },
        "currentEpisode": 137,
        "airingAt": 1702692000000,
        "airingEpisode": 141
      },
      {
        "id": "153479",
        "coverImage": "https://s4.anilist.co/file/anilistcdn/media/anime/cover/large/bx153479-SqMhsHlj4otI.png",
        "bannerImage": "https://image.tmdb.org/t/p/w500/dCB8Fv1CvLbW5y46MqNxSZlt3MY.jpg",
        "title": {
          "native": "王者大师兄",
          "romaji": "Wang Zhe Da Shixiong",
          "english": "王者大师兄"
        },
        "currentEpisode": 5,
        "airingAt": 1702692000000,
        "airingEpisode": 14
      },
      {
        "id": "137683",
        "coverImage": "https://media.kitsu.io/anime/47604/poster_image/f8dcee7a5281d8657d26b44627a40ced.png",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/435940/backgrounds/64851b81517f0.jpg",
        "title": {
          "native": "斗罗大陆2绝世唐门",
          "romaji": "Douluo Dalu 2: Jueshi Tangmen",
          "english": "Soul Land 2: The Peerless Tang Clan"
        },
        "currentEpisode": 26,
        "airingAt": 1702695600000,
        "airingEpisode": 27
      },
      {
        "id": "156081",
        "coverImage": "https://s4.anilist.co/file/anilistcdn/media/anime/cover/large/bx156081-uT9HJRIn1A7L.jpg",
        "bannerImage": "https://image.tmdb.org/t/p/w500/7xYcpZTg70bPI8IOdyzfFStw8aO.jpg",
        "title": {
          "native": "七侯笔录",
          "romaji": "Qi Hou Biliu",
          "english": "七侯笔录"
        },
        "currentEpisode": 2,
        "airingAt": 1702695600000,
        "airingEpisode": 8
      },
      {
        "id": "966",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/79654/posters/6367b4bf7584c.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/79654/backgrounds/60c349ea480b2.jpg",
        "title": {
          "native": "クレヨンしんちゃん",
          "romaji": "Crayon Shin-chan",
          "english": "Shin Chan"
        },
        "currentEpisode": 2299,
        "airingAt": 1702711800000,
        "airingEpisode": 1212
      },
      {
        "id": "158791",
        "coverImage": "https://media.kitsu.io/anime/46922/poster_image/f2c40aa392045828badbee6e6a513626.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/428394/backgrounds/6501a73d7dbca.jpg",
        "title": {
          "native": "め組の大吾 救国のオレンジ",
          "romaji": "Megumi no Daigo: Kyuukoku no Orange",
          "english": "Firefighter Daigo: Rescuer in Orange"
        },
        "currentEpisode": 11,
        "airingAt": 1702715400000,
        "airingEpisode": 12
      },
      {
        "id": "235",
        "coverImage": "https://artworks.thetvdb.com/banners/posters/5da4995cd30fd.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/series/72454/backgrounds/5f5c402bc8066.jpg",
        "title": {
          "native": "名探偵コナン",
          "romaji": "Meitantei Conan",
          "english": "Case Closed"
        },
        "currentEpisode": 1106,
        "airingAt": 1702717200000,
        "airingEpisode": 1107
      },
      {
        "id": "162720",
        "coverImage": "https://media.kitsu.io/anime/47184/poster_image/872cdb8c8cab3412a49035a29cab8683.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/432720/backgrounds/656b316a089ce.jpg",
        "title": {
          "native": "キボウノチカラ～オトナプリキュア'23～",
          "romaji": "Kibou no Chikara: Otona Precure '23",
          "english": "Power of Hope ~Precure Full Bloom~"
        },
        "currentEpisode": 9,
        "airingAt": 1702718700000,
        "airingEpisode": 11
      },
      {
        "id": "143085",
        "coverImage": "https://media.kitsu.io/anime/45657/poster_image/86eab146e88695ffac854b888ecd3aec.png",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/401915/backgrounds/6504e323b9555.jpg",
        "title": {
          "native": "最果てのパラディン 鉄錆の山の王",
          "romaji": "Saihate no Paladin: Tetsusabi no Yama no Ou",
          "english": "The Faraway Paladin: The Lord of Rust Mountains"
        },
        "currentEpisode": 10,
        "airingAt": 1702731600000,
        "airingEpisode": 11
      },
      {
        "id": "159808",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/429263/posters/63b87577aba3f.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/429263/backgrounds/656fc377a1709.jpg",
        "title": {
          "native": "ひきこまり吸血姫の悶々",
          "romaji": "Hikikomari Kyuuketsuki no Monmon",
          "english": "The Vexations of a Shut-In Vampire Princess"
        },
        "currentEpisode": 9,
        "airingAt": 1702733400000,
        "airingEpisode": 10
      },
      {
        "id": "158927",
        "coverImage": "https://s4.anilist.co/file/anilistcdn/media/anime/cover/large/bx142838-ECZSqfknAqAT.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/405920/backgrounds/6181af0258553.jpg",
        "title": {
          "native": "SPY×FAMILY 第2クール",
          "romaji": "SPY×FAMILY Part 2",
          "english": "SPY x FAMILY Cour 2"
        },
        "currentEpisode": 13,
        "airingAt": 1702735200000,
        "airingEpisode": 11
      },
      {
        "id": "165070",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/434982/posters/646350ca00a20.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/434982/backgrounds/65055a7092b7a.jpg",
        "title": {
          "native": "新しい上司はど天然",
          "romaji": "Atarashii Joushi wa Dotennen",
          "english": "My New Boss is Goofy"
        },
        "currentEpisode": 10,
        "airingAt": 1702737000000,
        "airingEpisode": 11
      },
      {
        "id": "163142",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/432815/posters/650100e1f2f77.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/432815/backgrounds/6509b0aa5874f.jpg",
        "title": {
          "native": "帰還者の魔法は特別です",
          "romaji": "Kikansha no Mahou wa Tokubetsu desu",
          "english": "A Returner's Magic Should Be Special"
        },
        "currentEpisode": 10,
        "airingAt": 1702738800000,
        "airingEpisode": 11
      },
      {
        "id": "168992",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/439302/posters/655576c12ce0e.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/439302/backgrounds/655229f20b62d.jpg",
        "title": {
          "native": "オチビサン",
          "romaji": "Ochibi-san",
          "english": "The Diary of Ochibi"
        },
        "currentEpisode": 2,
        "airingAt": 1702738800000,
        "airingEpisode": 9
      },
      {
        "id": "142599",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/413335/posters/64e95354381d2.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/413335/backgrounds/61b51a1e179d1.jpg",
        "title": {
          "native": "豚のレバーは加熱しろ",
          "romaji": "Buta no Liver wa Kanetsu Shiro",
          "english": "Butareba -The Story of a Man Turned into a Pig-"
        },
        "currentEpisode": 9,
        "airingAt": 1702740600000,
        "airingEpisode": 10
      },
      {
        "id": "146493",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/417983/posters/64d4b08399339.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/417983/backgrounds/6474a8510e7aa.jpg",
        "title": {
          "native": "ラグナクリムゾン",
          "romaji": "Ragna Crimson",
          "english": "Ragna Crimson"
        },
        "currentEpisode": 11,
        "airingAt": 1702742400000,
        "airingEpisode": 12
      },
      {
        "id": "154384",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/424368/posters/631727e9d1e00.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/424368/backgrounds/6317300950bc4.jpg",
        "title": {
          "native": "ティアムーン帝国物語～断頭台から始まる、姫の転生逆転ストーリー～",
          "romaji": "Tearmoon Teikoku Monogatari: Dantoudai kara Hajimaru, Hime no Tensei Gyakuten Story",
          "english": "Tearmoon Empire"
        },
        "currentEpisode": 10,
        "airingAt": 1702742400000,
        "airingEpisode": 11
      },
      {
        "id": "161645",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/431162/posters/63eda57ded888.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/431162/backgrounds/64e7424360eef.jpg",
        "title": {
          "native": "薬屋のひとりごと",
          "romaji": "Kusuriya no Hitorigoto",
          "english": "The Apothecary Diaries"
        },
        "currentEpisode": 10,
        "airingAt": 1702742700000,
        "airingEpisode": 11
      },
      {
        "id": "166896",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/437008/posters/64a931b474405.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/437008/backgrounds/65031cd662bfa.jpg",
        "title": {
          "native": "僕らの雨いろプロトコル",
          "romaji": "Bokura no Ameiro Protocol",
          "english": "Protocol: Rain"
        },
        "currentEpisode": 10,
        "airingAt": 1702744200000,
        "airingEpisode": 11
      },
      {
        "id": "161992",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/431495/posters/649c2d7a86139.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/431495/backgrounds/649c6809d720b.jpg",
        "title": {
          "native": "ポーション頼みで生き延びます!",
          "romaji": "Potion-danomi de Ikinobimasu!",
          "english": "I Shall Survive Using Potions!"
        },
        "currentEpisode": 10,
        "airingAt": 1702746000000,
        "airingEpisode": 11
      },
      {
        "id": "142842",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/413549/posters/6248e0b2e2506.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/413549/backgrounds/6248e1781cb14.jpg",
        "title": {
          "native": "遊☆戯☆王ゴーラッシュ!!",
          "romaji": "Yu☆Gi☆Oh! Go Rush!!",
          "english": "Yu-Gi-Oh! Go Rush!!"
        },
        "currentEpisode": 85,
        "airingAt": 1702765800000,
        "airingEpisode": 89
      },
      {
        "id": "163256",
        "coverImage": "https://media.kitsu.io/anime/47270/poster_image/a658444f52ed512d2b3250b56fe81429.jpg",
        "bannerImage": "",
        "title": {
          "native": "デュエル・マスターズWIN 決闘学園編",
          "romaji": "Duel Masters WIN: Duel Wars",
          "english": "デュエル・マスターズWIN 決闘学園編"
        },
        "currentEpisode": null,
        "airingAt": 1702769400000,
        "airingEpisode": 38
      },
      {
        "id": "157883",
        "coverImage": "https://artworks.thetvdb.com/banners/v4/series/427819/posters/63ba1ea747f5f.jpg",
        "bannerImage": "https://artworks.thetvdb.com/banners/v4/series/427819/backgrounds/63bc606bb75bc.jpg",
        "title": {
          "native": "ひろがるスカイ！プリキュア",
          "romaji": "Hirogaru Sky! Precure",
          "english": "Soaring Sky! Pretty Cure"
        },
        "currentEpisode": 44,
        "airingAt": 1702769400000,
        "airingEpisode": 45
      }
    ]
  };

  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const trending = await fetchSchedule();
        console.log(trending,"data")
        setData(trending[0]);
      } catch (error) {
        // Handle errors, e.g., set an error state or log the error
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Call the async function immediately inside useEffect
  }, []);


  return (
    <div className="font-jt  py-8">
      <div className='flex flex-row'>
      <h1 className="text-xl items-center flex md:text-2xl font-bold ml-10 mb-4 text-white">Estimated Schedule</h1>
      <RealTimeClock/>
      </div>
      <AnimeCalendar data={data?animeData:animeData} />
    </div>
  );
};

export default Schedule;
