import React from 'react';
import { Link } from 'react-router-dom';

const CardImage = ({id, cardData,kitsu}) => {
  const cleanedColor = cardData.color? cardData.color.replace(/"/g, ''):"#000000";
  const textStyle = {
    color: cleanedColor,
    // You can add other inline styles if needed
  };
  const borderStyle = {
    borderRight: `2px solid ${cleanedColor}`, // Applying border with cleanedColor
    // You can add other inline styles if needed
  }
  return (

    <Link to={`/watch?anime=${cardData.id}`} className=" w-[95%] md:w-[380px] items-center my-2  border-r-2 border-purple-400 flex flex-row h-40 md:h-24 text-white bg-[#272729]" style={borderStyle}>
          <div className={'min-w-[50px] md:min-w-[60px] ml-2 mr-6 text-center  text-[80px] font-comic'} style={textStyle}>
            {id+1}
          </div>
          <div className='h-full min-w-fit'>
            <img
            src={cardData.image}
            alt={cardData.title.english}
            className='h-full w-[100px] md:w-full p-[1px]'
            /> 
          </div>
          <div className='flex flex-col w-full font-jt'>
            <p className='text-[15px] text-center'>{cardData?.title.english? cardData?.title.english.slice(0,30):cardData?.title.romaji.slice(0,30)}</p>
            
              <div className='flex flex-row justify-center items-center'>

              <p className=' border-white rounded-md text-sm mr-2 px-1 font-bold font-jt tracking-widest text-white '>{cardData.currentEpisode}/{cardData.totalEpisodes}</p> 
              <div className="relative">
              <img className="h-6" src="tv.svg" />{" "}
              <p className="text-black top-[2px] text-[13px] mx-auto flex justify-center w-full font-bold font-jt absolute">
                {kitsu?.attributes.ageRating}
              </p>
            </div>
              <p className='justify-end font-bold mx-2 tracking-widest'> {cardData.type}</p>
              </div>

          </div>

    </Link>
  );
};

export default CardImage;
