// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SliderComp from './component/Home/Slider/Slider';
import Card from './component/Home/Card/Card';
import RectangleCard from './component/Home/Top anime/Top Anime Card';
import NewReleaseCard from './component/Home/New Release added completed/NewReleaseCard';
import Schedule from './component/Home/schedule/Schedule';
import EpisodeSelector from './component/Main Page/Episode Selector/EpisodeSelector';
import VideoPlayer from './component/Main Page/Video Player/Player';
import AnimeCard from './component/Main Page/Detail Card/Detail Card';
import HomePage from './pages/Home/Home';
import Watch from './pages/Watch/Watch';
import Navbar from './component/Navbar/Navbar';
import Filter from './component/Filter/Filter';
import Profile from './pages/Miscellaneous/Miscellaneous';
import Miscellaneous from './pages/Miscellaneous/Miscellaneous';
import Footer from './component/Footer/Footer';
import CommentApp from './component/Main Page/Comments/CommentMain';
import UserList from './component/Admin and Moderators/Admin/Admin';
import CustomCheckBox from './component/Filter/CustomCheckBox';
import FilterMain from './component/Filter/FilterMain';
import RichInput from './component/Main Page/Comments/RichInput';

function App() {
  return (
    <div className='bg-[#020202]'>
        <Router>
      <Navbar/>
    <Routes>
    <Route path="/" element={<HomePage/>}/> 
    <Route path="/comment" element={< CommentApp/>}/> 
    <Route path="/profile" element={< Miscellaneous defaulttab={0}/>} />
    <Route path="/continue-watching" element={< Miscellaneous defaulttab={1}/>} />
    <Route path="/watch-list" element={< Miscellaneous defaulttab={2}/>} />
    <Route path="/user-list" element={< Miscellaneous defaulttab={3}/>} />
    <Route path="/anime-updates" element={< Miscellaneous defaulttab={4}/>} />
    <Route path="/comment-flag" element={< Miscellaneous defaulttab={5}/>} />
    <Route path="/filter" element={< FilterMain/>} />
    <Route path="/watch" element={< Watch/>} />
    <Route path="/home" element={< HomePage/>} />
    <Route path="/9" element={< AnimeCard/>} />
    <Route path="/8" element={< VideoPlayer/>} />
    <Route path="/7" element={< EpisodeSelector/>} /> 
    <Route path="/6" element={< Schedule/>} /> 
    <Route path="/5" element={< NewReleaseCard/>} /> 
    <Route path="/1" element={< RectangleCard/>} />
    <Route path="/2" element={< Card/>} />  
    {/* <Route path="/3" element={<LoginPage/>} /> */}
    <Route path="/4" element={<SliderComp/>} />
    </Routes>
    <Footer/>
</Router>
    </div>
    
  );
}

export default App;
