// Inside your App.js or any other file where you want to render comments
import React, { useContext, useEffect, useState } from "react";
import CommentsRoot from "./CommentRoot";
import { Skeleton } from "@mui/material";
import { getAllComments, getSingleLevelComments } from "../../../utils/Comment Api";
import { AuthContext } from "../../../authContext/AuthContext";

const dummyComments = [
  {
    id: 1,
    text: "It was hidden under the log beside the stream. It had been there for as long as Jerry had been alive. He wasn't sure if anyone besides him and his friends knew of its existence. He knew that anyone could potentially find it, but it was well enough hidden that it seemed unlikely to happen. The fact that it had been there for more than 30 years attested to this. So it was quite a surprise when he found the item was missing.",
    children: [
      {
        id: 2,
        text: "Hopes and dreams were dashed that day. It should have been expected, but it still came as a shock. The warning signs had been ignored in favor of the possibility, however remote, that it could actually happen. That possibility had grown from hope to an undeniable belief it must be destiny. That was until it wasn't and the hopes and dreams came crashing down.",
        children: [
          {
            id: 4,
            text: "Betty decided to write a short story and she was sure it was going to be amazing. She had already written it in her head and each time she thought about it she grinned from ear to ear knowing how wonderful it would be. She could imagine the accolades coming in and the praise she would receive for creating such a wonderful piece. She was therefore extremely frustrated when she actually sat down to write the short story and the story that was so beautiful inside her head refused to come out that way on paper.",
            children: [],
          },
          {
            id: 5,
            text: "The river slowly meandered through the open space. It had hidden secrets that it didn't want to reveal. It had a well-planned strategy to appear calm, inviting, and appealing. That's how the river lured her unknowing victims to her water's edge.",
            children: [
              {
                id: 7,
                text: "There was nothing to indicate Nancy was going to change the world. She looked like an average girl going to an average high school. It was the fact that everything about her seemed average that would end up becoming her superpower.",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 3,
        text: "She had been told time and time again that the most important steps were the first and the last. It was something that she carried within her in everything she did, but then he showed up and disrupted everything. He told her that she had it wrong. The first step wasn't the most important. The last step wasn't the most important. It was the next step that was the most important.",
        children: [
          {
            id: 6,
            text: "It's always good to bring a slower friend with you on a hike. If you happen to come across bears, the whole group doesn't have to worry. Only the slowest in the group do. That was the lesson they were about to learn that day.",
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: 8,
    text: "It was a rat's nest. Not a literal one, but that is what her hair seemed to resemble every morning when she got up. It was going to take at least an hour to get it under control and she was sick and tired of it. She peered into the mirror and wondered if it was worth it. It wasn't. She opened the drawer and picked up the hair clippers.",
    children: [
      {
        id: 9,
        text: "The towels had been hanging from the rod for years. They were stained and worn, and quite frankly, just plain ugly. Debra didn't want to touch them but she really didn't have a choice. It was important for her to see what was living within them.",
        children: [],
      },
      {
        id: 10,
        text: "It's always good to bring a slower friend with you on a hike. If you happen to come across bears, the whole group doesn't have to worry. Only the slowest in the group do. That was the lesson they were about to learn that day.",
        children: [
          {
            id: 11,
            text: "There was a time and a place for Stephanie to use her magic. The problem was that she had a difficult time determining this. She wished she could simply use it when the desire hit and there wouldn't be any unforeseen consequences. Unfortunately, that's not how it worked and the consequences could be devastating if she accidentally used her magic at the wrong time.",
            children: [
              {
                id: 12,
                text: "Was it enough? That was the question he kept asking himself. Was being satisfied enough? He looked around him at everyone yearning to just be satisfied in their daily life and he had reached that goal. He knew that he was satisfied and he also knew it wasn't going to be enough.",
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
];

const CommentApp = ({animeId,episodeNumber}) => {
  // const animeId=1
  // const episodeNumber=5
  const [allComments, setAllComments] = useState([]);
  const { user, dispatch } = useContext(AuthContext);
  const getdataComment = async () => {
    const data = await getSingleLevelComments(animeId,episodeNumber,null);
    if(data)data.reverse()
    // console.log(data,"data1234")
    setAllComments(data);
  };
  useEffect(() => {
    
    getdataComment();
  }, [episodeNumber]);

  return (
    <div className=" ">
      {allComments ? (
        <CommentsRoot ReFetchParentComment={getdataComment} getdataComment={getdataComment}  comments={allComments} userId={user?.user._id} animeId={animeId} episodeNumber={episodeNumber}/>
      ) : (
        <div className="bg-[#010101]">
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            className=" mt-6 rounded-md"
            sx={{ bgcolor: "grey.900" }}
            height={100}
          />
        </div>
      )}
    </div>
  );
};

export default CommentApp;
