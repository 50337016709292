import React, { useEffect, useState } from "react";
import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";
import { MediaPlayer, MediaProvider, Poster, Track } from "@vidstack/react";
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from "@vidstack/react/player/layouts/default";

import "./style.scss";
import {
  fetchAnifyVideoSources,
  fetchEpisodeSourcesANDservers,
  fetchZoro,
} from "../../../utils/AnimeApi";
import EpisodeSelector from "./EpisodeSelector";
import EpisodeSelectorServer from "./EpisodeSelectorServer";

import { addToWatchlist, removeFromWatchlist } from "../../../utils/Watchlist";
import { AuthContext } from "../../../authContext/AuthContext";
import { useContext } from "react";
import { loginSuccess } from "../../../authContext/AuthActions";
import { Box, Rating } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import EpisodeSelectorZoro from "./EpisodeSelectorZoro";

const VideoPlayer = ({ data, episode, setEpisode }) => {
  const { user, dispatch } = useContext(AuthContext);
  const deleteEntry = async (animeId) => {
    try {
      const newUserData = await removeFromWatchlist(user.user._id, animeId);

      const updatedUser = { ...user, user: newUserData };
      dispatch(loginSuccess(updatedUser));
    } catch (error) {
      console.error("Error deleting entry:", error);
    }
  };

  const addEntry = async (animeId) => {
    try {
      const newUserData = await addToWatchlist(user.user._id, animeId);

      const updatedUser = { ...user, user: newUserData };
      dispatch(loginSuccess(updatedUser));
    } catch (error) {
      console.error("Error deleting entry:", error);
    }
  };

  const [videoSources, setVideoSources] = useState([]);
  const [videoSourcesBackend, setVideoSourcesBackend] = useState([]);
  const [videoServersBackend, setVideoServersBackend] = useState([]);
  const [videoSourcesZoro, setVideoSourcesZoro] = useState([]);
  // const [videoSourcesZoroDub, setVideoSourcesZoroDub] = useState([]);
  const [sourceVideo, setSourceVideo] = useState("/video.mp4");
  const [StreamOrSource, setStreamOrSource] = useState(true);
  const [subtitles, setSubtitles] = useState([]);
  const [thumbnail, setThumbnail] = useState(null);
  const [serverVideo, setServerVideo] = useState(
    "https://goone.pro/streaming.php?id=MjUwNTQ=&title=Naruto+Episode+1&typesub=SUB"
  );
  useEffect(() => {
    // console.log(episode,"episodedatacheck")
    const fetchFromBackend = async () => {
      if (episode) {
        let sourcesArray = [];
        let serversArray = [];

        if (episode.hasDub) {
          const res = await fetchEpisodeSourcesANDservers(
            episode.id,
            data.id,
            episode.number,
            "sub"
          );
          const resDub = await fetchEpisodeSourcesANDservers(
            episode.idDub,
            data.id,
            episode.number,
            "dub"
          );

          if (res) sourcesArray.push({ language: "sub", data: res.sources });
          if (resDub)
            sourcesArray.push({ language: "dub", data: resDub.sources });
          if (res) serversArray.push({ language: "sub", data: res.servers });
          if (resDub)
            serversArray.push({ language: "dub", data: resDub.servers });
        } else {
          const res = await fetchEpisodeSourcesANDservers(
            episode.id,
            data.id,
            episode.number,
            "sub"
          );

          if (res) sourcesArray.push({ language: "sub", data: res.sources });
          if (res) serversArray.push({ language: "sub", data: res.servers });
        }
        console.log(episode, "checkdata episode");
        console.log(sourcesArray, "checkdata server");
        console.log(serversArray, "checkdata sources");
        setVideoServersBackend(serversArray);
        setVideoSourcesBackend(sourcesArray);
      }
    };

    fetchFromBackend();
  }, [episode]);

  const labels = {
    0.5: "Meh",
    1: "Barely Watchable",
    1.5: "Not Great",
    2: "Has Potential",
    2.5: "Fair",
    3: "Entertaining",
    3.5: "Good Watch",
    4: "Really Good",
    4.5: "Amazing",
    5: "Masterpiece",
  };
  function getLabelText(value) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
  }

  const [value, setValue] = useState(0.5);
  const [hover, setHover] = useState(-1);

  useEffect(() => {
    const fetchSourcesZoro = async () => {
      if (episode) {
        const dataa = await fetchZoro(data.anime.id, episode.number);
        setVideoSourcesZoro(dataa);


       if(dataa && dataa.length>0){
        const zoroSubtitles = dataa.map((item) =>
        item.subtitles
          .filter((sub) => sub.lang !== 'Thumbnails')
          .map((sub) => ({
            url: sub.url,
            lang: sub.lang,
          }))
      )
      .flat(); // Flattening the array of arrays into a single array

    // Update the state with the filtered subtitles data
     setSubtitles([...subtitles,...zoroSubtitles]);
     console.log(zoroSubtitles,"checkdata zoro subtitles")
    // Filter and extract thumbnails data for zoro provider and language=sub
    const zoroThumbnails = dataa.map((item) =>
        item.subtitles
          .filter((sub) => sub.lang === 'Thumbnails')
          .map((sub) => ({
            url: sub.url,
            lang: sub.lang,
          }))).flat(); // Flattening the array of arrays into a single array

    // Update the state with the filtered thumbnails data
     setThumbnail(zoroThumbnails[0]?.url);
    // console.log(zoroThumbnails[0]?.url,"checkdata thumbnail")
       }
      }
    };
    fetchSourcesZoro();
  }, [episode]);

  useEffect(() => {
    const fetchSources = async () => {
      if (episode) {
        const sourcesArray = [];

        for (const source of episode.idSecond) {
          const { providerId, id, hasDub } = source;

          if (hasDub) {
            const subTypes = ["sub", "dub"];

            for (const subType of subTypes) {
              const sources = await fetchAnifyVideoSources({
                providerId,
                watchId: id,
                episodeNumber: episode.number,
                id: data.anime.id,
                subType,
              });
              if (sources) sourcesArray.push(sources);
            }
          } else {
            const sources = await fetchAnifyVideoSources({
              providerId,
              watchId: id,
              episodeNumber: episode.number,
              id: data.anime.id,
              subType: "sub", // Assuming no dub, always fetch sub
            });
            if (sources) sourcesArray.push(sources);
          }
        }

        console.log(sourcesArray, "checkdata sources anify");

        setVideoSources(sourcesArray);

        //////////////////////////////////////////////////////////////////////////////////////////////////////////

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Filter and extract subtitles data for zoro provider and language=sub, excluding Thumbnails
        const zoroSubtitles = sourcesArray
          .filter((item) => item.provider === "zoro" && item.language === "sub")
          .map((item) =>
            item.subtitles
              .filter((sub) => sub.lang !== "Thumbnails")
              .map((sub) => ({
                url: sub.url,
                lang: sub.lang,
              }))
          )
          .flat(); // Flattening the array of arrays into a single array

        // Update the state with the filtered subtitles data
        setSubtitles([...subtitles,...zoroSubtitles]);
        console.log(zoroSubtitles, "checkdata subtitles");
        // Filter and extract thumbnails data for zoro provider and language=sub
        const zoroThumbnails = sourcesArray
          .filter((item) => item.provider === "zoro" && item.language === "sub")
          .map((item) =>
            item.subtitles
              .filter((sub) => sub.lang === "Thumbnails")
              .map((sub) => ({
                url: sub.url,
                lang: sub.lang,
              }))
          )
          .flat(); // Flattening the array of arrays into a single array

        // Update the state with the filtered thumbnails data
         setThumbnail(zoroThumbnails[0]?.url);
        console.log(zoroThumbnails[0]?.url, "checkdata thumbnail");
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      }
    };

    fetchSources();
  }, [episode]);

  // useEffect(() => {
  //   console.log("-------------")
  //   console.log(videoSources, "anify", Date.now())
  //   console.log(videoServersBackend, "consumet")
  //   console.log(videoSourcesBackend, "consumet")
  // }, [videoSources, videoServersBackend, videoSourcesBackend])

  const SourceVideoSelect = (source) => {
    setSourceVideo(source);
    setStreamOrSource(true);
  };

  const ServerVideoSelect = (server) => {
    setServerVideo(server);
    setStreamOrSource(false);
  };

  return (
    <div className="w-full h-full font-jt">
      <div className="flex justify-center m-2 mt-[52px] h-full w-[95vw] md:w-auto">
        <div className="w-full h-full">
          {StreamOrSource == true ? (
            <div className="aspect-w-16 aspect-h-9">
              <MediaPlayer
                playsinline
                title={
                  data?.anime?.title.english
                    ? data?.anime?.title.english
                    : data?.anime?.title.romaji
                }
                src={sourceVideo}
                className="w-full h-full"
              >
                <MediaProvider>
                  {subtitles.length>0 &&
                    subtitles.map((data) => (
                      <Track
                        src={data.url}
                        kind="subtitles"
                        label={data.lang}
                        lang={data.lang}
                      />
                    ))}
                </MediaProvider>
                <Poster
                  className="vds-poster w-full h-full object-cover"
                  src={
                    data?.anime?.cover
                      ? data?.anime?.cover
                      : data?.anime?.coverAnilist
                  }
                  alt={
                    data?.anime?.title.english
                      ? data?.anime?.title.english
                      : data?.anime?.title.romaji
                  }
                />
                <DefaultVideoLayout
                  thumbnails={thumbnail ? thumbnail : ""}
                  icons={defaultLayoutIcons}
                />
              </MediaPlayer>
            </div>
          ) : (
            <iframe
              src={serverVideo}
              scrolling="no"
              className="w-full h-[300px] md:h-[470px]"
              frameborder="0"
            ></iframe>
          )}
        </div>
        <div></div>
      </div>
      <div className="max-h-[350px] md:max-h-[200px] w-[96vw] mx-auto md:w-full overflow-y-scroll">
        {videoSources.length > 0 &&
          videoSources
            .filter((ele) => ele.data !== null && ele.data.length > 0)
            .map((ele) => (
              <EpisodeSelector
                SourceVideoSelect={SourceVideoSelect}
                prompText={"3rd Party Servers"}
                ads={false}
                videoData={ele}
              />
            ))}
        {videoSourcesZoro &&
          videoSourcesZoro.length > 0 &&
          videoSourcesZoro.map((ele) => (
            <EpisodeSelectorZoro
              SourceVideoSelect={SourceVideoSelect}
              prompText={"Anime4Us Server 1"}
              ads={false}
              videoData={ele}
            />
          ))}
        {videoSourcesBackend.map((ele) => (
          <EpisodeSelector
            SourceVideoSelect={SourceVideoSelect}
            prompText={"Anime4Us Server 2"}
            ads={false}
            videoData={ele}
          />
        ))}
        {videoServersBackend.map((ele) => (
          <EpisodeSelectorServer
            ServerVideoSelect={ServerVideoSelect}
            prompText={"Anime4Us Server 2"}
            ads={true}
            videoData={ele}
          />
        ))}
      </div>

      <div className=" w-[95vw] mx-auto md:w-full items-center md:bg-[#2a2929] px-2 rounded-md justify-between mt-4 md:m-2 my-auto flex flex-row">
        <div className="flex flex-row space-x-6">
          <button
            type="button"
            className="flex items-center flex-row text-sm md:text-base text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-full  px-10 py-2.5 text-center me-2 md:mb-2"
          >
            <img src="report.svg" className="h-6" alt="" />
            Report
          </button>

          {user.user.watchList.includes(data.anime.id) ? (
            <button
              type="button"
              onClick={() => deleteEntry(data.anime.id)}
              className="flex text-white items-center text-sm md:text-base bg-gradient-to-br from-pink-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium  rounded-full  px-6 py-2.5 text-center me-2 md:mb-2"
            >
              <img src="tick.svg" className="h-6 md:mr-2" alt="" />
              Remove from Watchlist
            </button>
          ) : (
            <button
              type="button"
              onClick={() => addEntry(data.anime.id)}
              className="flex text-white bg-gradient-to-r items-center from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-purple-800/80 font-medium  rounded-full  px-6 py-2.5 text-center me-2 md:mb-2"
            >
              <img src="plus.svg" className="h-6 mr-2" alt="" />
              Add to Watchlist
            </button>
          )}
        </div>
        <div className="hidden md:block">
          <div className=" text-white text-center text-[20px]  mt-2">
            Rate this Anime
          </div>
          <Rating
            name="hover-feedback"
            value={value}
            precision={0.5}
            getLabelText={getLabelText}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            onChangeActive={(event, newHover) => {
              setHover(newHover);
            }}
            emptyIcon={
              <StarIcon style={{ color: "white", opacity: 1, fontSize: 40 }} />
            }
            style={{ fontSize: 40 }}
          />
        </div>
        {value !== null && (
          <div className="hidden md:block w-20 mr-4 text-[20px] font-comic text-white">
            {labels[hover !== -1 ? hover : value]}
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoPlayer;
